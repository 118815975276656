import { renderToStaticMarkup } from 'react-dom/server';

//Helpers
import { formatProjectNameForEmail, formatAddress, formatSaksnummer, formatContactInfoText } from 'helpers/formatHelpers'
import { getAnsvarsomraaderFromForm, groupAnsvarsomraaderByFunksjon } from './ansvarsomraadeHelpers';

import React from 'react';

const formatFormType = innsendingstype => {
    switch (innsendingstype) {
        case 'ansvarsrett':
            return 'ansvarserklæringen';
        case 'samsvarserklaering':
            return 'samsvarserklæringen';
        case 'kontrollerklaering':
            return 'kontrollerklæringen';
        default:
            return '';
    }
}

const renderAnsvarsomraaderList = ansvarsomraader => {
    const ansvarsomraadeByFunksjon = groupAnsvarsomraaderByFunksjon(ansvarsomraader);
    return ansvarsomraadeByFunksjon && Object.keys(ansvarsomraadeByFunksjon)?.length
        ? Object.keys(ansvarsomraadeByFunksjon).map(ansvarsomraadeFunksjonKey => {
            const ansvarsomraadeFunksjon = ansvarsomraadeByFunksjon[ansvarsomraadeFunksjonKey];
            const ansvarsomraadeListElements = ansvarsomraadeFunksjon.ansvarsomraader.filter(ansvarsomraade => {
                return ansvarsomraadeFunksjonKey !== 'SØK'
            }).map((ansvarsomraade, ansvarsomraadeIndex) => {
                return (
                    <li key={ansvarsomraadeIndex}>{ansvarsomraade.beskrivelseAvAnsvarsomraade}</li>
                )
            })
            return (
                <React.Fragment key={ansvarsomraadeFunksjonKey}>
                    {ansvarsomraadeFunksjon?.funksjonBeskrivelse}{ansvarsomraadeFunksjonKey !== 'SØK' ? ':' : ''}
                    <ul style={{ margin: '4px 0px 16px 0px' }}>{ansvarsomraadeListElements}</ul>
                </React.Fragment>
            )
        })
        : '';
}

export const generateRejectionEmail = (form, toAddress) => {
    const adresse = formatAddress(form.formData?.eiendomByggesteder?.length ? form.formData?.eiendomByggesteder[0] : null);
    const saksnummer = formatSaksnummer(form);
    if (!toAddress) {
        toAddress = form.formData?.ansvarligSoeker?.epost?.length ? form.formData?.ansvarligSoeker?.epost : null;
    }
    return {
        toAddress,
        subject: `${formatProjectNameForEmail(form)} - ${formatFormType(form.innsendingstype)} har blitt avvist`,
        htmlBody: renderToStaticMarkup(
            <html>

                <b>{form.formData?.ansvarligForetak?.navn} har avvist {formatFormType(form.innsendingstype)}{form.formData?.prosjektnavn?.length ? ` for ${form.formData?.prosjektnavn}` : ''}</b><br />
                {
                    adresse ?
                        (
                            <React.Fragment>
                                Adresse: {adresse}<br />
                            </React.Fragment>
                        )
                        : ''
                }
                {
                    saksnummer ?
                        (
                            <React.Fragment>
                                Kommunens saksnummer: {saksnummer}<br />
                            </React.Fragment>
                        )
                        : ''
                }
                {
                    form.formData?.ansvarligForetak?.kontaktpersonNavn?.length ?
                        (
                            <React.Fragment>
                                Kontaktperson hos {form.formData?.ansvarligForetak?.navn}: {form.formData?.ansvarligForetak?.kontaktpersonNavn}<br />
                            </React.Fragment>
                        )
                        : ''
                }

                <br />

                <b>Dere har meldt om følgende feil:</b><br />
                <i>{form.statusReason}</i>

                <br /><br />

                Meldingen er sendt til {form.formData?.ansvarligSoeker?.navn}, som er ansvarlig søker.

                <br /><br /><br />

                <b>Har du spørsmål?</b><br />
                {formatContactInfoText(form)}

                <br /><br />

                Vennlig hilsen<br />
                Direktoratet for byggkvalitet

            </html>
        )
    };
}


export const generateSignedEmail = (form, toAddress) => {
    const adresse = formatAddress(form.formData?.eiendomByggesteder?.length ? form.formData?.eiendomByggesteder[0] : null);
    const saksnummer = formatSaksnummer(form);
    const ansvarsomraader = getAnsvarsomraaderFromForm(form);
    if (!toAddress) {
        toAddress = form.formData?.ansvarligSoeker?.epost?.length ? form.formData?.ansvarligSoeker?.epost : null;
    }
    return {
        toAddress,
        subject: `${formatProjectNameForEmail(form)} - ${formatFormType(form.innsendingstype)} har blitt signert`,
        htmlBody: renderToStaticMarkup(
            <html>

                <b>{form.formData?.ansvarligForetak?.navn} har signert {formatFormType(form.innsendingstype)}{form.formData?.prosjektnavn?.length ? ` for ${form.formData?.prosjektnavn}` : ''}</b><br />
                {
                    adresse ?
                        (
                            <React.Fragment>
                                Adresse: {adresse}<br />
                            </React.Fragment>
                        )
                        : ''
                }
                {
                    saksnummer ?
                        (
                            <React.Fragment>
                                Kommunens saksnummer: {saksnummer}<br />
                            </React.Fragment>
                        )
                        : ''
                }
                {
                    form.formData?.ansvarligForetak?.kontaktpersonNavn?.length ?
                        (
                            <React.Fragment>
                                Kontaktperson hos {form.formData?.ansvarligForetak?.navn}: {form.formData?.ansvarligForetak?.kontaktpersonNavn}<br />
                            </React.Fragment>
                        )
                        : ''
                }

                <br />

                <b>Husk å arkivere erklæringen</b><br />
                Ansvarlig foretak må selv holde arkiv over signerte erklæringer. Trykk på lenken for å laste ned erklæringen: <a href="#download-ref#">#download-ref#</a><br /><br />

                Erklæringen har også blitt sendt til {form.formData?.ansvarligSoeker?.navn}, som er ansvarlig søker.<br />


                <br /><br />

                <b>{ansvarsomraader?.length > 1 ? 'Ansvarsområder' : 'Ansvarsområde'}</b><br />
                {renderAnsvarsomraaderList(ansvarsomraader)}

                <br />

                <b>Har du spørsmål?</b><br />
                {formatContactInfoText(form)}

                <br /><br />

                Vennlig hilsen<br />
                Direktoratet for byggkvalitet

            </html>
        )
    };
}