// Dependencies
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { Accordion, Button, ErrorMessage, Label } from "dibk-design";

// Actions
import { validateAnsvarsomraadetAvsluttet } from "actions/ValidationActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

// Helpers
import { formatDate } from "helpers/formatHelpers";

// Stylesheets
import formsStyle from "components/partials/Forms/Forms.module.scss";
import commonStyle from "components/routes/common.module.scss";

const Ansvarsomraade = ({ updateHandler, validationHandler }) => {
    const dispatch = useDispatch();

    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);
    const validationMessages = useSelector((state) => state.validationMessages);

    const handleUpdate = (value, property) => {
        let updatedFormData = selectedForm.formData;
        updatedFormData[property] = value;
        return updateHandler(updatedFormData);
    };

    const handleValidate = (validations) => {
        validationHandler(validations);
    };

    const formData = selectedForm?.formData;
    return formData ? (
        <React.Fragment>
            <div className={commonStyle.marginBottomSmall}>Opplysningene er hentet fra erklæring om ansvarsrett.</div>
            <div className={commonStyle.marginBottomSmall}>
                <Accordion
                    title="Ansvarlig kontrollerende"
                    expanded
                    onToggleExpand={() => {
                        dispatch(renewLastInteractionAt());
                    }}
                >
                    <dl className={formsStyle.fieldList}>
                        <dt>
                            <Label normalCursor htmlTag="span">
                                Beskrivelse av ansvarsområde
                            </Label>
                        </dt>
                        <dd>{formData.beskrivelseAvAnsvarsomraadet}</dd>
                        {!!formData.ansvarsrettErklaert && (
                            <React.Fragment>
                                <dt>
                                    <Label normalCursor htmlTag="span">
                                        Ansvarsrett ble erklært
                                    </Label>
                                </dt>
                                <dd>{formatDate(formData.ansvarsrettErklaert)}</dd>
                            </React.Fragment>
                        )}
                    </dl>
                    <div className={formsStyle.fieldSection}>
                        <fieldset>
                            <legend className={commonStyle.required}>Er arbeidet avsluttet?</legend>
                            <div className={formsStyle.buttonRow}>
                                <div>
                                    <Button
                                        content="Ja"
                                        size="small"
                                        rounded
                                        onClick={() => {
                                            handleUpdate(true, "ansvarsomraadetAvsluttet");
                                            handleValidate(() => {
                                                dispatch(validateAnsvarsomraadetAvsluttet());
                                            });
                                        }}
                                        noHover
                                        inputType="radio"
                                        name="ansvarsomraadetAvsluttet"
                                        defaultChecked={formData.ansvarsomraadetAvsluttet === true}
                                        hasErrors={!!validationMessages?.ansvarsomraadetAvsluttet?.message?.length}
                                        aria-describedby={
                                            !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                ? "ansvarsomraadetAvsluttet-errorMessage"
                                                : null
                                        }
                                    />
                                </div>
                                <div>
                                    <Button
                                        content="Nei"
                                        size="small"
                                        rounded
                                        onClick={() => {
                                            handleUpdate(false, "ansvarsomraadetAvsluttet");
                                            handleValidate(() => {
                                                dispatch(validateAnsvarsomraadetAvsluttet());
                                            });
                                        }}
                                        noHover
                                        inputType="radio"
                                        name="ansvarsomraadetAvsluttet"
                                        defaultChecked={formData.ansvarsomraadetAvsluttet === false}
                                        hasErrors={!!validationMessages?.ansvarsomraadetAvsluttet?.message?.length}
                                        aria-describedby={
                                            !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                ? "ansvarsomraadetAvsluttet-errorMessage"
                                                : null
                                        }
                                    />
                                </div>
                            </div>
                        </fieldset>
                        {validationMessages?.ansvarsomraadetAvsluttet?.message?.length ? (
                            <ErrorMessage
                                id="ansvarsomraadetAvsluttet-errorMessage"
                                content={validationMessages.ansvarsomraadetAvsluttet.message}
                            />
                        ) : null}
                    </div>
                </Accordion>
            </div>
        </React.Fragment>
    ) : null;
};

Ansvarsomraade.propTypes = {
    updateHandler: PropTypes.func.isRequired
};

export default Ansvarsomraade;
