// Dependencies
import React from "react";
import { useSelector } from "react-redux";

// DIBK Design
import { Footer } from "dibk-design";

const FooterContainer = () => {
    // Redux store
    const selectedSubmission = useSelector((state) => state.selectedSubmission);
    const isAnsvarsrett = selectedSubmission?.innsendingstype === "ansvarsrett";

    return (
        <Footer>
            {isAnsvarsrett && (
                <p>
                    Lurer du på om du kan erklære ansvar?
                    <br />
                    <a
                        href="https://dibk.no/verktoy-og-veivisere/veiviser_ansvar/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Prøv veiviseren vår her
                    </a>
                </p>
            )}
            <p>
                Har du spørsmål om signering for ansvarlige foretak?
                <br />
                <a href="http://dibk.no/signering" target="_blank" rel="noopener noreferrer">
                    Se vanlig stilte spørsmål
                </a>
            </p>
            <p>
                <a href={`${window?.location?.origin}/personvernerklaering`} target="_blank" rel="noopener noreferrer">
                    Personvernerklæring
                </a>
                <a href={`${window?.location?.origin}/cookieerklaering`} target="_blank" rel="noopener noreferrer">
                    Erklæring om informasjonskapsler
                </a>
                <a
                    href="https://uustatus.no/nb/erklaringer/publisert/fa74c32f-fe3d-4ed9-8187-556100d474d5"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Tilgjengelighetserklæring
                </a>
            </p>
        </Footer>
    );
};

export default FooterContainer;
