import { UPDATE_LAST_INTERACTION_AT } from "constants/types";

const initialState = Date.now();

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LAST_INTERACTION_AT:
            return action.payload;
        default:
            return state;
    }
};

export default reducer;

// Selectors
export const getSecondsSinceLastInteraction = (state) => {
    const timeSinceLastInteraction = Date.now() - state.lastInteractionAt;
    return Math.floor(timeSinceLastInteraction / 1000);
};
