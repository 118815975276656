import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SignoutCallbackComponent } from "redux-oidc";

const OidcSignoutCallback = ({ userManager }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!!sessionStorage?.signinRedirectPath?.length) {
            navigate(`${sessionStorage.signinRedirectPath}/utlogget`);
            sessionStorage.removeItem("signinRedirectPath");
        } else {
            navigate("/");
        }
    }, [navigate]);

    const successCallback = () => {};

    return (
        <SignoutCallbackComponent
            userManager={userManager}
            successCallback={successCallback}
            errorCallback={() => {
                navigate("/"); // TODO add errorpage for signout
            }}
        >
            <div>Logger ut...</div>
        </SignoutCallbackComponent>
    );
};

export default OidcSignoutCallback;
