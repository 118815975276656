// Dependencies
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { Header } from "dibk-design";

// Components
import MainHeading from "components/partials/MainHeading";
import AnsvarIByggeProsjektList from "components/partials/Forms/FormParts/AnsvarIByggeProsjektList";
import EiendomByggestedList from "components/partials/Forms/FormParts/EiendomByggestedList";
import Erklaering from "components/partials/Forms/FormParts/Erklaering";
import AnsvarligForetak from "components/partials/Forms/FormParts/AnsvarligForetak";

// Partials
import FormIntroText from "components/partials/FormIntroText";

// Actions
import { updateSelectedForm } from "actions/FormActions";
import { updateIsEdited } from "actions/IsEditedActions";

// Helpers
import { classNameArrayToClassNameString } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const Ansvarsrett = ({ validationHandler }) => {
    const dispatch = useDispatch();

    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const updateFormData = (formData) => {
        return dispatch(
            updateSelectedForm({
                ...selectedForm,
                formData
            })
        ).then(() => {
            dispatch(updateIsEdited(true));
        });
    };

    const updateFormDataField = (value, property) => {
        return updateFormData({
            ...selectedForm.formData,
            [property]: value
        });
    };

    const formData = selectedForm?.formData;
    const disableSamsvarKontrollCheckboxes = formData?.erSamsvarKontrollFeltRedigerbare === false;
    return formData ? (
        <React.Fragment>
            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <MainHeading form={selectedForm} pageName="Skjemautfylling" />
                <FormIntroText form={selectedForm} />
            </section>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Eiendom/Byggested" size={2}></Header>
                <div className={commonStyle.marginBottomSmall}>
                    Trykk på eiendommen for å se detaljer om byggestedet.
                </div>
                <EiendomByggestedList eiendomByggesteder={formData.eiendomByggesteder} />
            </section>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Ansvarlig foretak" size={2}></Header>
                <AnsvarligForetak
                    foretak={formData.ansvarligForetak}
                    innsendingstype={selectedForm.innsendingstype}
                    updateHandler={(foretak) => updateFormDataField(foretak, "ansvarligForetak")}
                    validationHandler={(validations) => validationHandler(validations)}
                />
            </section>

            <div className="page-break"></div>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Ansvar i byggeprosjektet" size={2}></Header>
                <div className={commonStyle.marginBottomSmall}>
                    Ansvarlig søker har foreslått noen opplysninger, men du kan endre beskrivelsen og valgene. Alle
                    feltene må være fylt ut før du kan signere ansvarsretten.
                </div>
                {disableSamsvarKontrollCheckboxes ? (
                    <div className={commonStyle.infoBox}>
                        Søknadssystemet ansvarlig søker har brukt, støtter foreløpig ikke at ansvarlig foretak gjør
                        endringer i når samsvars- eller kontrollerklæringer vil foreligge. Dersom du ønsker å endre på
                        avkrysningen, må du melde ifra til søker via lenken nederst i skjemaet.
                    </div>
                ) : (
                    ""
                )}
                <AnsvarIByggeProsjektList
                    ansvarsomraader={formData.ansvarsomraader}
                    updateHandler={(ansvarsomraader) => updateFormDataField(ansvarsomraader, "ansvarsomraader")}
                    validationHandler={(validations) => validationHandler(validations)}
                />
            </section>

            <div className="page-break"></div>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Erklæring" size={2}></Header>
                <Erklaering
                    formData={formData}
                    updateHandler={(formData) => updateFormData(formData)}
                    validationHandler={(validations) => validationHandler(validations)}
                />
            </section>
        </React.Fragment>
    ) : (
        <p>Henter skjema</p>
    );
};

Ansvarsrett.propTypes = {
    validationHandler: PropTypes.func.isRequired
};

export default Ansvarsrett;
