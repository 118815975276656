// Types
import { FETCH_SUBMISSION } from "constants/types";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

export const fetchSubmission = (guid) => (dispatch) => {
    const internalApiUrl = getEnvironmentVariable("internalApiUrl");
    const apiUrl = `${internalApiUrl}/api/v1/Innsending/${guid}`;
    const fetchOptions = {
        headers: {
            Accept: "application/hal+json"
        }
    };

    return fetch(apiUrl, fetchOptions).then((res) => {
        if (res.ok) {
            return res
                .json()
                .then((form) => {
                    dispatch({ type: FETCH_SUBMISSION, payload: form });
                    return form;
                })
                .catch((error) => {
                    console.log("fetchSubmission action:", error);
                });
        } else {
            return {
                status: res.status,
                xCorrelationId: res.headers?.get?.("x-correlation-id")
            };
        }
    });
};
