// Dependencies
import React from "react";
import { useSelector } from "react-redux";

// Helpers
import { formatOrganiationNumberForPdf, formatSaksnummer } from "helpers/formatHelpers";

// Assets
import { imageData } from "assets/svg/dibk-logo-base64";

const Heading = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const renderTitle = (innsendingstype) => {
        switch (innsendingstype) {
            case "ansvarsrett":
                return (
                    <h1>
                        Erklæring om ansvarsrett
                        <small>etter plan- og bygningsloven (pbl) § 23-3</small>
                    </h1>
                );
            case "samsvarserklaering":
                return (
                    <h1>
                        Samsvarserklæring
                        <small>etter plan -og bygningsloven (pbl) § 23-1, SAK10 kapittel 12</small>
                    </h1>
                );
            case "kontrollerklaering":
                return (
                    <h1>
                        Kontrollerklæring med sluttrapport
                        <small>etter plan- og bygningsloven (pbl) § 24-2, jf. SAK 10 § 12-5 og § 14-8</small>
                    </h1>
                );
            default:
                return "";
        }
    };

    const renderProjectName = (formData) => {
        let projectName;
        if (formData?.prosjektnavn?.length && formData?.prosjektnummer?.length) {
            projectName = `${formData.prosjektnavn} (${formData.prosjektnummer})`;
        } else if (formData?.prosjektnavn?.length) {
            projectName = formData.prosjektnavn;
        } else if (formData?.prosjektnummer?.length) {
            projectName = formData.prosjektnummer;
        }
        return projectName?.length ? (
            <div>
                <b>Prosjekt:</b> {projectName}
            </div>
        ) : (
            ""
        );
    };

    const renderCaseNumber = (form) => {
        let caseNumber = formatSaksnummer(form);
        return caseNumber?.length ? (
            <div>
                <b>Kommunens saksnummer: </b> {caseNumber}
            </div>
        ) : (
            ""
        );
    };

    const formData = selectedForm?.formData;
    return formData ? (
        <React.Fragment>
            <img src={imageData} alt="Direktoratet for byggkvalitet" className="heading-logo" />
            {renderTitle(selectedForm.innsendingstype)}
            <p>
                Ved signering blir erklæringen sendt til ansvarlig søker, {formData.ansvarligSoeker?.navn}
                {formData.ansvarligSoeker?.organisasjonsnummer?.length
                    ? ` (organisasjonsnummer ${formatOrganiationNumberForPdf(
                        formData.ansvarligSoeker.organisasjonsnummer
                    )})`
                    : null}
                {formData.ansvarligSoeker?.kontaktpersonNavn?.length
                    ? `, ved kontaktperson ${formData.ansvarligSoeker.kontaktpersonNavn}`
                    : null}
                .
            </p>
            {renderCaseNumber(selectedForm)}
            {renderProjectName(formData)}
            <div>
                <b>Innsendingens referanse:</b> {selectedForm.ftpbReferanseId}
            </div>
        </React.Fragment>
    ) : (
        ""
    );
};

export default Heading;
