// Dependencies
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

// Helpers
import { formatDate } from "helpers/formatHelpers";

const ArbeiderSomVilUtfoeres = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const formData = selectedForm?.formData;
    const harTilstrekkeligSikkerhet =
        formData?.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet === true
            ? "Ja"
            : formData.ansvarsrettUtfoerende.harTilstrekkeligSikkerhet === false
            ? "Nei"
            : "-";
    return (
        <Fragment>
            <table>
                <thead>
                    <tr>
                        <th>Har tiltaket tilstrekkelig sikkerhetsnivå til å tas i bruk?</th>
                        {formData?.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet === false ? (
                            <th className="text-right">Vi vil utføre arbeidet innen</th>
                        ) : (
                            ""
                        )}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{harTilstrekkeligSikkerhet}</td>
                        {formData?.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet === false ? (
                            <td className="text-right">
                                {formData?.ansvarsrettUtfoerende?.utfoertInnen
                                    ? formatDate(formData.ansvarsrettUtfoerende.utfoertInnen)
                                    : "-"}
                            </td>
                        ) : (
                            ""
                        )}
                    </tr>
                </tbody>
            </table>
            {formData?.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet === false ? (
                <table>
                    <thead>
                        <tr>
                            <th colSpan="2">Vi vil utføre følgende arbeid for å oppnå tilstrekkelig sikkerhetsnivå</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2" className="whitespace-pre-wrap">
                                {formData?.ansvarsrettUtfoerende?.typeArbeider?.length
                                    ? formData.ansvarsrettUtfoerende.typeArbeider
                                    : "-"}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                ""
            )}
        </Fragment>
    );
};

export default ArbeiderSomVilUtfoeres;
