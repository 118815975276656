// Types
import { UPDATE_LAST_INTERACTION_AT } from "constants/types";

export const updateLastInteractionAt = (lastInteractionAt) => (dispatch) => {
    dispatch({ type: UPDATE_LAST_INTERACTION_AT, payload: lastInteractionAt });
};

export const getSecondsSinceLastInteraction = (lastInteractionSince) => (dispatch, getState) => {
    const timeSinceLastInteraction = Date.now() - getState().lastInteractionAt;
    return Math.floor(timeSinceLastInteraction / 1000);
};

export const renewLastInteractionAt = () => (dispatch) => {
    dispatch(updateLastInteractionAt(Date.now()));
};
