// Dependencies
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

// Helpers
import { formatAddress, formatOrganiationNumberForPdf, formatPhoneNumberForPdf } from "helpers/formatHelpers";

const AnsvarligForetak = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const ansvarligForetak = selectedForm?.formData?.ansvarligForetak;
    return ansvarligForetak ? (
        <Fragment>
            <table>
                <thead>
                    <tr>
                        <th className="fixed-width-350">Navn</th>
                        <th className={selectedForm?.innsendingstype === "ansvarsrett" && "fixed-width-130"}>Organisasjons-<br/>nummer</th>
                        {selectedForm?.innsendingstype === "ansvarsrett" ? (
                            <th>Har foretaket sentral godkjenning?</th>
                        ) : null}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="fixed-width-350">{ansvarligForetak?.navn?.length ? ansvarligForetak?.navn : ""}</td>
                        <td className={selectedForm?.innsendingstype === "ansvarsrett" && "fixed-width-130"}>{ansvarligForetak?.organisasjonsnummer ? formatOrganiationNumberForPdf(ansvarligForetak?.organisasjonsnummer) : "-"}</td>
                        {selectedForm?.innsendingstype === "ansvarsrett" ? (
                            <td>{ansvarligForetak.harSentralGodkjenning === true ? "Ja" : "Nei"}</td>
                        ) : null}
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th className="fixed-width-350">Adresse</th>
                        <th className="fixed-width-130">Telefon</th>
                        <th>E-post</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="fixed-width-350">{formatAddress(ansvarligForetak) || "-"}</td>
                        <td className="fixed-width-130">
                            <div>{ansvarligForetak.telefonnummer?.length ? formatPhoneNumberForPdf(ansvarligForetak.telefonnummer) : ""}</div>
                            <div>{ansvarligForetak.mobilnummer?.length ? formatPhoneNumberForPdf(ansvarligForetak.mobilnummer) : ""}</div>
                            {!ansvarligForetak.telefonnummer?.length && !ansvarligForetak.mobilnummer?.length
                                ? "-"
                                : ""}
                        </td>
                        <td>{ansvarligForetak.epost?.length ? ansvarligForetak.epost : "-"}</td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th className="fixed-width-350">Kontaktperson</th>
                        <th className="fixed-width-130">Telefon</th>
                        <th>E-post</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="fixed-width-350">
                            {ansvarligForetak.kontaktpersonNavn?.length ? ansvarligForetak.kontaktpersonNavn : "-"}
                        </td>
                        <td className="fixed-width-130">
                            <div>
                                {ansvarligForetak.kontaktpersonTelefonnummer?.length
                                    ? formatPhoneNumberForPdf(ansvarligForetak.kontaktpersonTelefonnummer)
                                    : ""}
                            </div>
                            <div>
                                {ansvarligForetak.kontaktpersonMobilnummer?.length
                                    ? formatPhoneNumberForPdf(ansvarligForetak.kontaktpersonMobilnummer)
                                    : ""}
                            </div>
                            {!ansvarligForetak.kontaktpersonTelefonnummer?.length &&
                            !ansvarligForetak.kontaktpersonMobilnummer?.length
                                ? "-"
                                : ""}
                        </td>
                        <td>
                            {ansvarligForetak.kontaktpersonEpost?.length ? ansvarligForetak.kontaktpersonEpost : "-"}
                        </td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    ) : null;
};

export default AnsvarligForetak;
