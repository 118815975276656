// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheets
import style from './Snackbar.module.scss';

const Snackbar = ({ message, onCloseClick }) => {
    return <div className={style.snackbarContainer}>
        <div className={style.snackbarContent}>
            {message}
            <button aria-hidden onClick={() => onCloseClick()} className={style.closeButton}></button>
        </div>
    </div>;
};

Snackbar.propTypes = {
    /** Message content inside snackbar */
    message: PropTypes.string,
    /** Function for click on close button element */
    onCloseClick: PropTypes.func.isRequired,
};

export default Snackbar;
