// Dependencies
import React from "react";
import { useSelector } from "react-redux";

// Components
import AnsvarligForetak from "components/partials/Pdf/PdfParts/AnsvarligForetak";
import EiendomByggesteder from "components/partials/Pdf/PdfParts/EiendomByggesteder";
import SamsvarOgKontrollAnsvarsomraade from "components/partials/Pdf/PdfParts/SamsvarOgKontrollAnsvarsomraade";
import SluttrapportForKontroll from "components/partials/Pdf/PdfParts/SluttrapportForKontroll";
import ErklaeringKontroll from "components/partials/Pdf/PdfParts/ErklaeringKontroll";
import Heading from "components/partials/Pdf/PdfParts/Heading";

const KontrollErklaering = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const formData = selectedForm?.formData;
    return formData ? (
        <div class="content-container signed-document">
            <Heading />
            <section>
                <h2>Eiendom/byggested</h2>
                <EiendomByggesteder />
            </section>
            <section>
                <h2>Ansvarlig foretak</h2>
                <AnsvarligForetak />
            </section>
            <section>
                <h2>Ansvarsområde</h2>
                <SamsvarOgKontrollAnsvarsomraade />
            </section>
            <section>
                <h2>Sluttrapport for kontroll</h2>
                <SluttrapportForKontroll />
            </section>
            <section>
                <h2>Erklæring</h2>
                <ErklaeringKontroll />
            </section>
        </div>
    ) : (
        <p>Henter skjema</p>
    );
};

export default KontrollErklaering;
