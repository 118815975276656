import { useNavigate } from 'react-router-dom';
import { CallbackComponent } from "redux-oidc";

const OidcCallback = ({ userManager }) => {
    const navigate = useNavigate();

    // Redux store
    const successCallback = () => {
        const signinRedirectPath = !!sessionStorage?.signinRedirectPath.length ? `${sessionStorage.signinRedirectPath}/rediger` : '';
        sessionStorage.removeItem('signinRedirectPath');
        navigate(signinRedirectPath);
    };

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={successCallback}
            errorCallback={() => {
                navigate("/");
            }}
        >
            <div>Logger inn...</div>
        </CallbackComponent>
    );
};

export default OidcCallback;
