// Dependencies
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { Accordion, InputField, Label } from "dibk-design";

// Actions
import {
    validateAnsvarligForetakKontaktpersonEpost,
    validateAnsvarligForetakKontaktpersonNavn,
    validateAnsvarligForetakKontaktpersonTelefonnummer
} from "actions/ValidationActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

// Helpers
import { formatAddress } from "helpers/formatHelpers";

// Stylesheets
import formsStyle from "components/partials/Forms/Forms.module.scss";
import commonStyle from "components/routes/common.module.scss";

const AnsvarligForetak = ({ foretak, innsendingstype, updateHandler, validationHandler }) => {
    const dispatch = useDispatch();

    // Redux store
    const validationMessages = useSelector((state) => state.validationMessages);

    const handleUpdate = (value, property) => {
        let updatedForetak = foretak;
        updatedForetak[property] = value;
        updateHandler(updatedForetak);
    };

    const handleValidate = (validations) => {
        return validationHandler(validations);
    };

    const handleUpdateAndValidateIfChanged = (newValue, property, validations) => {
        const oldValue = foretak[property];
        if (newValue !== oldValue) {
            handleUpdate(newValue, property);
            return handleValidate(validations);
        }
    };

    const adresse = formatAddress(foretak);
    const telefonNummerList = [foretak.telefonnummer, foretak.mobilnummer].filter((nummer) => {
        return nummer?.length;
    });
    return foretak && Object.keys(foretak).length ? (
        <React.Fragment>
            <div className={commonStyle.marginBottomSmall}>
                Hvis du oppdager feil om foretaket, kan du melde inn dette nederst i skjemaet.
            </div>
            <div className={commonStyle.marginBottomSmall}>
                <Accordion
                    title={`${foretak.navn} (org.nr. ${foretak.organisasjonsnummer})`}
                    expanded
                    onToggleExpand={() => {
                        dispatch(renewLastInteractionAt());
                    }}
                >
                    <dl className={formsStyle.fieldList}>
                        {adresse?.length ? (
                            <React.Fragment>
                                <dd>{adresse}</dd>
                            </React.Fragment>
                        ) : null}
                        {telefonNummerList.length ? (
                            <React.Fragment>
                                <dt>
                                    <Label normalCursor htmlTag="span">
                                        Telefon
                                    </Label>
                                </dt>
                                <dd>
                                    <ul className={formsStyle.cleanList}>
                                        {telefonNummerList.map((nummer, index) => {
                                            return <li key={index}>{nummer}</li>;
                                        })}
                                    </ul>
                                </dd>
                            </React.Fragment>
                        ) : null}
                        {foretak.epost?.length ? (
                            <React.Fragment>
                                <dt>
                                    <Label normalCursor htmlTag="span">
                                        E-post
                                    </Label>
                                </dt>
                                <dd>{foretak.epost}</dd>
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                    </dl>
                    {innsendingstype === "ansvarsrett" ? (
                        foretak.harSentralGodkjenning ? (
                            "Foretaket har sentral godkjenning."
                        ) : (
                            <React.Fragment>
                                Foretaket har <i>ikke</i> sentral godkjenning.
                            </React.Fragment>
                        )
                    ) : null}
                </Accordion>
            </div>
            <div className={commonStyle.marginBottomSmall}>
                <Accordion
                    title="Kontaktperson"
                    expanded
                    onToggleExpand={() => {
                        dispatch(renewLastInteractionAt());
                    }}
                >
                    <div className={commonStyle.marginBottomSmall}>
                        Du må fylle ut navn, e-post og enten mobil- eller telefonnummer. Kontaktpersonen trenger ikke å
                        være den samme som signerer.
                    </div>
                    <div className={formsStyle.fieldList}>
                        <div className="print-flex-10">
                            <InputField
                                id="foretak-kontaktperson-navn"
                                elementKey="foretak-kontaktperson-navn"
                                onBlur={(event) => {
                                    handleUpdateAndValidateIfChanged(event.target.value, "kontaktpersonNavn", () => {
                                        dispatch(validateAnsvarligForetakKontaktpersonNavn());
                                    });
                                }}
                                label="Navn"
                                width="400px"
                                defaultValue={foretak.kontaktpersonNavn || ""}
                                defaultContent="Ikke angitt"
                                required
                                hasErrors={
                                    validationMessages?.ansvarligForetakKontaktpersonNavn?.message?.length
                                        ? true
                                        : false
                                }
                                errorMessage={validationMessages?.ansvarligForetakKontaktpersonNavn?.message}
                                aria-autocomplete="inline"
                            />
                        </div>
                        <div className="print-flex-10">
                            <InputField
                                id="foretak-kontaktperson-epost"
                                elementKey="foretak-kontaktperson-epost"
                                onBlur={(event) => {
                                    handleUpdateAndValidateIfChanged(event.target.value, "kontaktpersonEpost", () => {
                                        dispatch(validateAnsvarligForetakKontaktpersonEpost());
                                    });
                                }}
                                label="E-post (navn@domene.no)"
                                defaultValue={foretak.kontaktpersonEpost || ""}
                                defaultContent="Ikke angitt"
                                width="400px"
                                type="email"
                                required
                                hasErrors={
                                    validationMessages?.ansvarligForetakKontaktpersonEpost?.message?.length
                                        ? true
                                        : false
                                }
                                errorMessage={validationMessages?.ansvarligForetakKontaktpersonEpost?.message}
                                aria-autocomplete="inline"
                            />
                        </div>
                        <div className="print-flex-10">
                            <InputField
                                id="foretak-kontaktperson-mobilnummer"
                                elementKey="foretak-kontaktperson-mobilnummer"
                                onBlur={(event) => {
                                    event.target.value = event.target?.value?.replace(/\s+/g, ""); // Removes whitespace
                                    handleUpdateAndValidateIfChanged(
                                        event.target.value,
                                        "kontaktpersonMobilnummer",
                                        () => {
                                            dispatch(validateAnsvarligForetakKontaktpersonTelefonnummer());
                                        }
                                    );
                                }}
                                label="Mobilnummer (gyldige tegn er tall og +)"
                                defaultValue={foretak.kontaktpersonMobilnummer || ""}
                                defaultContent="Ikke angitt"
                                width="200px"
                                type="tel"
                                required={!foretak.kontaktpersonTelefonnummer?.length}
                                hasErrors={
                                    validationMessages?.ansvarligForetakKontaktpersonMobilnummer?.message?.length
                                        ? true
                                        : false
                                }
                                errorMessage={validationMessages?.ansvarligForetakKontaktpersonMobilnummer?.message}
                                aria-autocomplete="inline"
                            />
                        </div>
                        <div className="print-flex-10">
                            <InputField
                                id="foretak-kontaktperson-telefonnummer"
                                elementKey="foretak-kontaktperson-telefonnummer"
                                onBlur={(event) => {
                                    event.target.value = event.target?.value?.replace(/\s+/g, ""); // Removes whitespace

                                    handleUpdateAndValidateIfChanged(
                                        event.target.value,
                                        "kontaktpersonTelefonnummer",
                                        () => {
                                            dispatch(validateAnsvarligForetakKontaktpersonTelefonnummer());
                                        }
                                    );
                                }}
                                label="Telefonnummer (gyldige tegn er tall og +)"
                                defaultValue={foretak.kontaktpersonTelefonnummer || ""}
                                defaultContent="Ikke angitt"
                                width="200px"
                                type="tel"
                                required={!foretak.kontaktpersonMobilnummer?.length}
                                hasErrors={
                                    validationMessages?.ansvarligForetakKontaktpersonTelefonnummer?.message?.length
                                        ? true
                                        : false
                                }
                                errorMessage={validationMessages?.ansvarligForetakKontaktpersonTelefonnummer?.message}
                                aria-autocomplete="inline"
                            />
                        </div>
                    </div>
                </Accordion>
            </div>
        </React.Fragment>
    ) : null;
};

AnsvarligForetak.propTypes = {
    foretak: PropTypes.object.isRequired,
    innsendingstype: PropTypes.string,
    updateHandler: PropTypes.func.isRequired,
    validationHandler: PropTypes.func.isRequired
};

export default AnsvarligForetak;
