import { useEffect, useState } from 'react';
import { load } from "components/config";

const ConfigLoader = ({ready, loading}) => {

  // State
  const [isLoaded, setIsLoaded] = useState(false);
  const [config, setConfig] = useState(false);

  useEffect(() => {
    // Once the configuration is loaded set `isLoaded` to true so we know to render our component
    load().then(config => {
      setIsLoaded(true);
      setConfig(config);
    });
  }, [])

  // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
  if (!isLoaded) {
    return loading ? loading() : null;
  }

  // The config is loaded so show the component set on the `ready()` props
  return ready(config);
}

export default ConfigLoader;