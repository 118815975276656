// Dependencies
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// DIBK Design
import { Header } from "dibk-design";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const getFormTypeName = (innsendingstype) => {
    switch (innsendingstype) {
        case "ansvarsrett":
            return "Erklæring om ansvarsrett";
        case "samsvarserklaering":
            return "Samsvarserklæring";
        case "kontrollerklaering":
            return "Kontrollerklæring med sluttrapport";
        default:
            return "";
    }
};

const getPageTitle = (form, pageName) => {
    const innsendingstype = form?.innsendingstype;
    const formTypeName = getFormTypeName(innsendingstype);
    if (pageName?.length && formTypeName?.length) {
        return pageName?.length ? `${pageName} - ${formTypeName}` : formTypeName;
    } else if (pageName?.length) {
        return pageName;
    } else if (formTypeName?.length) {
        return formTypeName;
    } else {
        return "";
    }
};

const getSubTitle = (form) => {
    const innsendingstype = form?.innsendingstype;
    switch (innsendingstype) {
        case "ansvarsrett":
            return "etter plan- og bygningsloven (pbl) § 23-3";
        case "samsvarserklaering":
            return "etter plan- og bygningsloven (pbl) § 23-1, SAK10 kapittel 12";
        case "kontrollerklaering":
            return "etter plan- og bygningsloven (pbl) § 24-2, jf. SAK 10 § 12-5 og § 14-8";
        default:
            return "";
    }
};

const renderPageHeading = (form, pageName) => {
    const pageTitle = getPageTitle(form, pageName);
    return (
        <div className={commonStyle.headerSection}>
            <Header content={pageTitle}></Header>
            <span className={commonStyle.subtitle}>{getSubTitle(form)}</span>
        </div>
    );
};

const MainHeading = ({ form, pageName }) => {
    return (
        <Fragment>
            <Helmet>
                <title>{getPageTitle(form, pageName)} - DiBK</title>
                <meta property="og:title" content={`${getPageTitle(form, pageName)} - DiBK`} />
                <meta property="twitter:title" content={`${getPageTitle(form, pageName)} - DiBK`} />
            </Helmet>
            {renderPageHeading(form, pageName)}
        </Fragment>
    );
};

MainHeading.propTypes = {
    form: PropTypes.object,
    pageName: PropTypes.string
};

export default MainHeading;
