// Dependencies
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { Accordion, Button, CheckBoxList, CheckBoxListItem, ErrorMessage, Textarea } from "dibk-design";

// Actions
import {
    validateBeskrivelseForSingleAnsvarsomraade,
    validateSamsvarKontrollCheckboxesForSingleAnsvarsomraade,
    validateDekkesOmradetAvSentralGodkjenningForSingleAnsvarsomraade,
    validateTiltaksklasseForSingleAnsvarsomraade
} from "actions/ValidationActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

// Stylesheets
import formsStyle from "components/partials/Forms/Forms.module.scss";
import commonStyle from "components/routes/common.module.scss";

const AnsvarIByggeProsjekt = ({ ansvarsomraade, index, updateHandler, validationHandler }) => {
    const dispatch = useDispatch();

    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);
    const validationMessages = useSelector((state) => state.validationMessages);

    const handleUpdate = (value, property, index) => {
        return updateHandler(value, property, index);
    };

    const handleValidate = (validations) => {
        validationHandler(validations);
    };

    const handleUpdateAndSaveIfChanged = (newValue, property, index, validations) => {
        const oldValue = ansvarsomraade[property];
        if (newValue !== oldValue) {
            handleUpdate(newValue, property, index);
            handleValidate(validations);
        }
    };

    const samsvarKontrollHasErrors = (ansvarsomraade, index) => {
        if (ansvarsomraade.funksjonKode === "UTF" || ansvarsomraade.funksjonKode === "PRO") {
            return validationMessages?.ansvarsomraader?.[index]?.samsvarCheckboxes?.message?.length > 0 ? true : false;
        } else if (ansvarsomraade.funksjonKode === "KONTROLL") {
            return validationMessages?.ansvarsomraader?.[index]?.kontrollCheckboxes?.message?.length > 0 ? true : false;
        } else {
            return false;
        }
    };

    const disableSamsvarKontrollCheckboxes = selectedForm?.formData?.erSamsvarKontrollFeltRedigerbare === false;

    let accordionTitle = `${ansvarsomraade.funksjonBeskrivelse}`;
    if (ansvarsomraade?.funksjonKode !== "SØK" && ansvarsomraade?.tiltaksklasseKode?.length) {
        accordionTitle += ` (tiltaksklasse ${ansvarsomraade.tiltaksklasseKode})`;
    }

    return (
        <div key={index} className={commonStyle.marginBottomSmall}>
            <Accordion
                title={accordionTitle}
                expanded
                onToggleExpand={() => {
                    dispatch(renewLastInteractionAt());
                }}
            >
                <div className={formsStyle.fieldSection}>
                    {ansvarsomraade.funksjonKode === "SØK" ? (
                        "Erklæringen brukes ved endring av ansvarlig søker."
                    ) : (
                        <Textarea
                            id={`ansvarsomraade-${index}-beskrivelseAvAnsvarsomraade`}
                            elementKey={`ansvarsomraade-${index}-beskrivelseAvAnsvarsomraade`}
                            label="Beskrivelse av ansvarsområde (maks 2000 tegn)"
                            onBlur={(event) => {
                                handleUpdateAndSaveIfChanged(
                                    event.target.value,
                                    "beskrivelseAvAnsvarsomraade",
                                    index,
                                    () => {
                                        dispatch(validateBeskrivelseForSingleAnsvarsomraade(index));
                                    }
                                );
                            }}
                            required
                            resize="vertical"
                            hasErrors={
                                validationMessages?.ansvarsomraader?.[index]?.beskrivelseAvAnsvarsomraade?.message
                                    ?.length > 0
                            }
                            errorMessage={
                                validationMessages?.ansvarsomraader?.[index]?.beskrivelseAvAnsvarsomraade?.message
                            }
                            defaultValue={ansvarsomraade.beskrivelseAvAnsvarsomraade || ""}
                        />
                    )}
                </div>
                <div className={formsStyle.fieldSection}>
                    <fieldset>
                        <legend className={commonStyle.required}>Tiltaksklasse</legend>
                        <div className={formsStyle.buttonRow}>
                            <Button
                                content="1"
                                size="small"
                                rounded
                                onClick={() => {
                                    handleUpdateAndSaveIfChanged("1", "tiltaksklasseKode", index, () => {
                                        dispatch(validateTiltaksklasseForSingleAnsvarsomraade(index));
                                    });
                                }}
                                noHover
                                required
                                inputType="radio"
                                name={`ansvarsomraader-${index}-tiltaksklasse`}
                                defaultChecked={ansvarsomraade.tiltaksklasseKode === "1"}
                                hasErrors={
                                    !!validationMessages?.ansvarsomraader?.[index]?.tiltaksklasse?.message?.length
                                }
                                aria-describedby={
                                    !!validationMessages?.ansvarsomraader?.[index]?.tiltaksklasse?.message?.length
                                        ? `ansvarsomraader-${index}-tiltaksklasse-errorMessage`
                                        : null
                                }
                            />
                            <Button
                                content="2"
                                size="small"
                                rounded
                                onClick={() => {
                                    handleUpdateAndSaveIfChanged("2", "tiltaksklasseKode", index, () => {
                                        dispatch(validateTiltaksklasseForSingleAnsvarsomraade(index));
                                    });
                                }}
                                noHover
                                required
                                inputType="radio"
                                name={`ansvarsomraader-${index}-tiltaksklasse`}
                                defaultChecked={ansvarsomraade.tiltaksklasseKode === "2"}
                                hasErrors={
                                    !!validationMessages?.ansvarsomraader?.[index]?.tiltaksklasse?.message?.length
                                }
                                aria-describedby={
                                    !!validationMessages?.ansvarsomraader?.[index]?.tiltaksklasse?.message?.length
                                        ? `ansvarsomraader-${index}-tiltaksklasse-errorMessage`
                                        : null
                                }
                            />
                            <Button
                                content="3"
                                size="small"
                                rounded
                                onClick={() => {
                                    handleUpdateAndSaveIfChanged("3", "tiltaksklasseKode", index, () => {
                                        dispatch(validateTiltaksklasseForSingleAnsvarsomraade(index));
                                    });
                                }}
                                noHover
                                required
                                inputType="radio"
                                name={`ansvarsomraader-${index}-tiltaksklasse`}
                                defaultChecked={ansvarsomraade.tiltaksklasseKode === "3"}
                                hasErrors={
                                    !!validationMessages?.ansvarsomraader?.[index]?.tiltaksklasse?.message?.length
                                }
                                aria-describedby={
                                    !!validationMessages?.ansvarsomraader?.[index]?.tiltaksklasse?.message?.length
                                        ? `ansvarsomraader-${index}-tiltaksklasse-errorMessage`
                                        : null
                                }
                            />
                        </div>
                    </fieldset>
                    {validationMessages?.ansvarsomraader?.[index]?.tiltaksklasse?.message?.length ? (
                        <ErrorMessage
                            id={`ansvarsomraader-${index}-tiltaksklasse-errorMessage`}
                            content={validationMessages.ansvarsomraader[index].tiltaksklasse.message}
                        />
                    ) : null}
                </div>
                {ansvarsomraade?.funksjonKode !== "SØK" ? (
                    <React.Fragment>
                        <div className={formsStyle.fieldSection}>
                            <CheckBoxList
                                required
                                compact
                                legend={`${
                                    ansvarsomraade?.funksjonKode === "KONTROLL"
                                        ? "Kontrollerklæringer"
                                        : "Samsvarserklæringer"
                                } vil foreligge ved (flere kan velges)`}
                            >
                                {ansvarsomraade?.funksjonKode !== "UTF" ? (
                                    <React.Fragment>
                                        <CheckBoxListItem
                                            id={`ansvarsomraade-${index}-samsvarKontrollVedRammetillatelse`}
                                            onChange={(event) => {
                                                handleUpdateAndSaveIfChanged(
                                                    event.target.checked,
                                                    "samsvarKontrollVedRammetillatelse",
                                                    index,
                                                    () => {
                                                        dispatch(
                                                            validateSamsvarKontrollCheckboxesForSingleAnsvarsomraade(
                                                                index
                                                            )
                                                        );
                                                    }
                                                );
                                            }}
                                            checked={ansvarsomraade.samsvarKontrollVedRammetillatelse}
                                            checkmarkCharacter="✕"
                                            disabled={disableSamsvarKontrollCheckboxes}
                                            hasErrors={samsvarKontrollHasErrors(ansvarsomraade, index)}
                                            aria-describedby={
                                                samsvarKontrollHasErrors(ansvarsomraade, index)
                                                    ? `ansvarsomraade-${index}-samsvarKontrollVedFerdigattest-errorMessage`
                                                    : null
                                            }
                                        >
                                            Rammesøknad
                                        </CheckBoxListItem>
                                        <CheckBoxListItem
                                            id={`ansvarsomraade-${index}-samsvarKontrollVedIgangsettingstillatelse`}
                                            onChange={(event) => {
                                                handleUpdateAndSaveIfChanged(
                                                    event.target.checked,
                                                    "samsvarKontrollVedIgangsettingstillatelse",
                                                    index,
                                                    () => {
                                                        dispatch(
                                                            validateSamsvarKontrollCheckboxesForSingleAnsvarsomraade(
                                                                index
                                                            )
                                                        );
                                                    }
                                                );
                                            }}
                                            checked={ansvarsomraade.samsvarKontrollVedIgangsettingstillatelse}
                                            checkmarkCharacter="✕"
                                            disabled={disableSamsvarKontrollCheckboxes}
                                            hasErrors={samsvarKontrollHasErrors(ansvarsomraade, index)}
                                            aria-describedby={
                                                samsvarKontrollHasErrors(ansvarsomraade, index)
                                                    ? `ansvarsomraade-${index}-samsvarKontrollVedFerdigattest-errorMessage`
                                                    : null
                                            }
                                        >
                                            Ettrinns-/igangsettingssøknad
                                        </CheckBoxListItem>
                                    </React.Fragment>
                                ) : null}

                                <CheckBoxListItem
                                    id={`ansvarsomraade-${index}-samsvarKontrollVedMidlertidigBrukstillatelse`}
                                    onChange={(event) => {
                                        handleUpdateAndSaveIfChanged(
                                            event.target.checked,
                                            "samsvarKontrollVedMidlertidigBrukstillatelse",
                                            index,
                                            () => {
                                                dispatch(
                                                    validateSamsvarKontrollCheckboxesForSingleAnsvarsomraade(index)
                                                );
                                            }
                                        );
                                    }}
                                    checked={ansvarsomraade.samsvarKontrollVedMidlertidigBrukstillatelse}
                                    checkmarkCharacter="✕"
                                    disabled={disableSamsvarKontrollCheckboxes}
                                    hasErrors={samsvarKontrollHasErrors(ansvarsomraade, index)}
                                    aria-describedby={
                                        samsvarKontrollHasErrors(ansvarsomraade, index)
                                            ? `ansvarsomraade-${index}-samsvarKontrollVedFerdigattest-errorMessage`
                                            : null
                                    }
                                >
                                    Midlertidig brukstillatelse
                                </CheckBoxListItem>
                                <CheckBoxListItem
                                    id={`ansvarsomraade-${index}-samsvarKontrollVedFerdigattest`}
                                    onChange={(event) => {
                                        handleUpdateAndSaveIfChanged(
                                            event.target.checked,
                                            "samsvarKontrollVedFerdigattest",
                                            index,
                                            () => {
                                                dispatch(
                                                    validateSamsvarKontrollCheckboxesForSingleAnsvarsomraade(index)
                                                );
                                            }
                                        );
                                    }}
                                    checked={ansvarsomraade.samsvarKontrollVedFerdigattest}
                                    checkmarkCharacter="✕"
                                    disabled={disableSamsvarKontrollCheckboxes}
                                    hasErrors={samsvarKontrollHasErrors(ansvarsomraade, index)}
                                    aria-describedby={
                                        samsvarKontrollHasErrors(ansvarsomraade, index)
                                            ? `ansvarsomraade-${index}-samsvarKontrollVedFerdigattest-errorMessage`
                                            : null
                                    }
                                >
                                    Ferdigattest
                                </CheckBoxListItem>
                            </CheckBoxList>
                            {samsvarKontrollHasErrors(ansvarsomraade, index) &&
                            ansvarsomraade?.funksjonKode === "KONTROLL" ? (
                                <ErrorMessage
                                    id={`ansvarsomraade-${index}-samsvarKontrollVedFerdigattest-errorMessage`}
                                    content={validationMessages?.ansvarsomraader?.[index]?.kontrollCheckboxes?.message}
                                />
                            ) : null}
                            {samsvarKontrollHasErrors(ansvarsomraade, index) &&
                            (ansvarsomraade?.funksjonKode === "UTF" || ansvarsomraade?.funksjonKode === "PRO") ? (
                                <ErrorMessage
                                    id={`ansvarsomraade-${index}-samsvarKontrollVedFerdigattest-errorMessage`}
                                    content={validationMessages?.ansvarsomraader?.[index]?.samsvarCheckboxes?.message}
                                />
                            ) : null}
                        </div>
                        {selectedForm?.formData?.ansvarligForetak?.harSentralGodkjenning ? (
                            <div className={formsStyle.fieldSection}>
                                <fieldset>
                                    <legend className={commonStyle.required}>
                                        Dekker den sentrale godkjenningen ansvarsområdet?
                                    </legend>
                                    <div className={formsStyle.buttonRow}>
                                        <Button
                                            content="Ja"
                                            size="small"
                                            rounded
                                            onClick={() => {
                                                handleUpdateAndSaveIfChanged(
                                                    true,
                                                    "dekkesOmradetAvSentralGodkjenning",
                                                    index,
                                                    () => {
                                                        dispatch(
                                                            validateDekkesOmradetAvSentralGodkjenningForSingleAnsvarsomraade(
                                                                index
                                                            )
                                                        );
                                                    }
                                                );
                                            }}
                                            noHover
                                            required
                                            inputType="radio"
                                            name={`ansvarsomraader-${index}-dekkesOmradetAvSentralGodkjenning`}
                                            defaultChecked={ansvarsomraade.dekkesOmradetAvSentralGodkjenning === true}
                                            hasErrors={
                                                !!validationMessages?.ansvarsomraader?.[index]
                                                    ?.dekkesOmradetAvSentralGodkjenning?.message?.length
                                            }
                                            aria-describedby={
                                                !!validationMessages?.ansvarsomraader?.[index]
                                                    ?.dekkesOmradetAvSentralGodkjenning?.message?.length
                                                    ? `ansvarsomraader-${index}-dekkesOmradetAvSentralGodkjenning-errorMessage`
                                                    : null
                                            }
                                        />
                                        <Button
                                            content="Nei"
                                            size="small"
                                            rounded
                                            onClick={() => {
                                                handleUpdateAndSaveIfChanged(
                                                    false,
                                                    "dekkesOmradetAvSentralGodkjenning",
                                                    index,
                                                    () => {
                                                        dispatch(
                                                            validateDekkesOmradetAvSentralGodkjenningForSingleAnsvarsomraade(
                                                                index
                                                            )
                                                        );
                                                    }
                                                );
                                            }}
                                            noHover
                                            required
                                            inputType="radio"
                                            name={`ansvarsomraader-${index}-dekkesOmradetAvSentralGodkjenning`}
                                            defaultChecked={ansvarsomraade.dekkesOmradetAvSentralGodkjenning === false}
                                            hasErrors={
                                                !!validationMessages?.ansvarsomraader?.[index]
                                                    ?.dekkesOmradetAvSentralGodkjenning?.message?.length
                                            }
                                            aria-describedby={
                                                !!validationMessages?.ansvarsomraader?.[index]
                                                    ?.dekkesOmradetAvSentralGodkjenning?.message?.length
                                                    ? `ansvarsomraader-${index}-dekkesOmradetAvSentralGodkjenning-errorMessage`
                                                    : null
                                            }
                                        />
                                    </div>
                                </fieldset>
                                {!!validationMessages?.ansvarsomraader?.[index]?.dekkesOmradetAvSentralGodkjenning
                                    ?.message?.length ? (
                                    <ErrorMessage
                                        id={`ansvarsomraader-${index}-dekkesOmradetAvSentralGodkjenning-errorMessage`}
                                        content={
                                            validationMessages.ansvarsomraader[index].dekkesOmradetAvSentralGodkjenning
                                                .message
                                        }
                                    />
                                ) : null}
                            </div>
                        ) : null}
                    </React.Fragment>
                ) : null}
            </Accordion>
        </div>
    );
};

AnsvarIByggeProsjekt.propTypes = {
    ansvarsomraade: PropTypes.object.isRequired,
    index: PropTypes.number,
    updateHandler: PropTypes.func.isRequired,
    validationHandler: PropTypes.func.isRequired
};

export default AnsvarIByggeProsjekt;
