export const FETCH_SUBMISSION = 'FETCH_SUBMISSION';
export const FETCH_SELECTED_FORM = 'FETCH_SELECTED_FORM';
export const UPDATE_SELECTED_FORM = 'UPDATE_SELECTED_FORM';
export const REJECT_SELECTED_FORM = 'REJECT_SELECTED_FORM';
export const SAVE_SELECTED_FORM = 'SAVE_SELECTED_FORM';
export const UPDATE_SNACKBAR_MESSAGE = 'UPDATE_SNACKBAR_MESSAGE';
export const UPDATE_SNACKBAR_VISIBLE = 'UPDATE_SNACKBAR_VISIBLE';
export const UPDATE_SNACKBAR_LASTINIT = 'UPDATE_SNACKBAR_LASTINIT';
export const UPDATE_VALIDATION_MESSAGES = 'UPDATE_VALIDATION_MESSAGES';
export const REMOVE_VALIDATION_MESSAGE = 'REMOVE_VALIDATION_MESSAGE';
export const UPDATE_IS_VALIDATED = 'UPDATE_IS_VALIDATED';
export const UPDATE_IS_SAVING_DATA = 'UPDATE_IS_SAVING_DATA';
export const UPDATE_IS_EDITED = 'UPDATE_IS_EDITED';
export const UPDATE_LOADING_MESSAGE = 'UPDATE_LOADING_MESSAGE';
export const FETCH_SERVICE_STATE = 'FETCH_SERVICE_STATE';
export const UPDATE_LAST_INTERACTION_AT = 'UPDATE_LAST_INTERACTION_AT';