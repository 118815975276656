// Dependencies
import { useEffect } from "react";
import { Link } from "react-router-dom";

// DIBK Design
import { Container, Header, Paper, Table } from "dibk-design";

// Components
import MainHeading from "components/partials/MainHeading";

// Helpers
import { scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const ErklaeringOmInformasjonskapsler = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Container>
            <Paper>
                <MainHeading pageName="Erklæring om informasjonskapsler - Signeringstjenesten for ansvarlige foretak" />
                <i>Sist oppdatert 01.12.2021</i>
                <Header content="Bruk av cookies i Signeringstjenesten for ansvarlige foretak" size={2} />
                <p>
                    Signeringstjenesten for ansvarlige foretak fra Direktoratet for byggkvalitet (DiBK) bruker «session
                    storage» for å kunne fungere. «Session storage» er lagring lokalt på din enhet, og behandler ikke
                    personopplysninger. Lagret informasjon slettes når sesjonen er slutt og nettleseren lukkes.
                </p>
                <p>
                    Vi bruker ikke egne informasjonskapsler (cookies). Informasjonskapsler er små tekstfiler som lagres
                    på enheten du bruker når du besøker et nettsted.
                </p>
                <Table>
                    <caption>Nettstedsdata</caption>
                    <thead>
                        <tr>
                            <th>Leverandør</th>
                            <th>Navn</th>
                            <th>Type</th>
                            <th>Formål</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>DiBK</td>
                            <td>oidc</td>
                            <td>Session storage</td>
                            <td>Autentiserer innloggingen din</td>
                        </tr>
                        <tr>
                            <td>DiBK</td>
                            <td>print</td>
                            <td>Session storage</td>
                            <td>Gir deg mulighet til å laste ned og printe ut en versjon av erklæringen</td>
                        </tr>
                    </tbody>
                </Table>

                <p className={commonStyle.marginTopSmall}>
                    Signeringstjenesten for ansvarlige foretak ligger på DiBK sitt domene. Se{" "}
                    <a href="https://dibk.no/om-oss/personvernerklaring/" target="_blank" rel="noopener noreferrer">
                        DiBK sin personvernerklæring med cookies-informasjon
                    </a>{" "}
                    for mer informasjon om hvordan direktoratet håndterer informasjonskapsler på sine nettsider.
                </p>
                <p>
                    Vi bruker eSignering fra Digitaliseringsdirektoratet til selve signeringsoppdraget. eSignering
                    leveres av Posten Norge. Den som skal bruke tjenesten logger seg inn med ID-porten, mens Signicat
                    står for signeringen. Dette betyr at ID-porten, Posten Norge AS og Signicat AS er underleverandører
                    av Digitaliseringsdirektoratet. Ved innlogging og signering med BankID (som de fleste bruker), er
                    det Vipps AS som er leverandør. Alle disse bruker informasjonskapsler i sine løsninger, og har
                    erklæringer om informasjonskapsler som du kan lese ved å trykke på lenkene under:
                </p>

                <ul>
                    <li>
                        <a
                            href="https://eid.difi.no/nb/sikkerhet-og-informasjonskapsler/personvern-og-informasjonskapsler"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ID-porten sin erklæring om personvern og informasjonskapsler
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://signering.posten.no/cookies/uten-virksomhetskonto"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Posten Norge AS sin cookie-erklæring for Posten signering
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.signicat.com/no/om-oss/signicat-privacy-and-cookie-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Signicat Privacy and Cookie Policy
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.bankid.no/privat/personvern-og-regler/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Vipps AS sin erklæring om personvern og regler
                        </a>
                    </li>
                </ul>

                <p>
                    Bruk av informasjonskapsler på nettsider reguleres av ekomloven § 2-7 b. I den grad en
                    informasjonskapsel også behandler personopplysninger om deg, gjelder også personopplysningsloven.
                    Les mer om hvordan vi innhenter personopplysninger i{" "}
                    <Link to="/personvernerklaering" title="Personvernerklæring">
                        personvernerklæringen vår
                    </Link>
                    .
                </p>

                <p>
                    Hvis du har spørsmål om hvordan vi bruker informasjonskapsler, kan du ta kontakt med oss på{" "}
                    <a href="mailto:ftb@dibk.no">ftb@dibk.no</a>.
                </p>
                <p>
                    Du kan lese mer om informasjonskapsler på{" "}
                    <a
                        href="https://www.nkom.no/internett/informasjonskapsler-cookies"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Nasjonal kommunikasjonsmyndighet sine nettsider
                    </a>
                    .
                </p>
            </Paper>
        </Container>
    );
};

export default ErklaeringOmInformasjonskapsler;
