// Dependencies
import React, { Fragment } from "react";

// DIBK Design
import { Button, Dialog, Header } from "dibk-design";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const RequestErrorDialog = ({ requestError, clickOutsideHandler }) => {
    const renderDialogBody = () => {
        const problemType = requestError?.problemDetails?.problemType;
        switch (problemType) {
            case "AttachmentProtected": {
                const metadata = requestError?.metadata;
                return (
                    <Fragment>
                        <Header content="Det har oppstått en feil ved sending av vedlegg" size={2} />
                        <p>
                            Vi kan ikke sende vedlegget {!!metadata?.fileName?.length ? `'${metadata.fileName}' ` : ""}
                            fordi det inneholder passord eller elektronisk signatur. Vennligst fjern passordet eller
                            signaturen, og last vedlegget opp på nytt. Vedlegget blir automatisk signert når du signerer
                            kontrollerklæringen.
                        </p>
                        <p>
                            Hvis feilen vedvarer, kan du sende oss en mail på{" "}
                            <a href="mailto:ftb@dibk.no">ftb@dibk.no</a>.
                            <br />
                            Oppgi feilkode: {requestError.xCorrelationId}
                        </p>
                        <div className={`${commonStyle.marginTop} ${commonStyle.marginBottomSmall}`}>
                            <Button onClick={clickOutsideHandler} color="primary">
                                Gå tilbake
                            </Button>
                        </div>
                    </Fragment>
                );
            }
            case "AttachmentInfected": {
                return (
                    <Fragment>
                        <Header content="Vi kunne ikke laste opp vedlegget" size={2} />
                        <p>
                            Vedlegget slår ut i virussjekken vår. Av sikkerhetshensyn må du derfor laste opp et annet
                            vedlegg.
                        </p>
                        <p>
                            Hvis du har spørsmål, kan du sende oss en mail på{" "}
                            <a href="mailto:ftb@dibk.no">ftb@dibk.no</a>.
                            <br />
                            Oppgi feilkode: {requestError.xCorrelationId}
                        </p>
                        <p>
                            <button className={commonStyle.link} onClick={clickOutsideHandler}>
                                Avbryt og tilbakestill endringer
                            </button>
                        </p>
                    </Fragment>
                );
            }
            default: {
                return (
                    <Fragment>
                        <Header content="Det har oppstått en feil" size={2} />
                        <p>Det har skjedd en feil ved utfylling av skjemaet. Vennligst prøv igjen senere.</p>
                        <p>
                            Hvis feilen vedvarer, kan du sende oss en mail på{" "}
                            <a href="mailto:ftb@dibk.no">ftb@dibk.no</a>.
                            <br />
                            Oppgi feilkode: {requestError.xCorrelationId}
                        </p>
                        <div className={`${commonStyle.marginTop} ${commonStyle.marginBottomSmall}`}>
                            {!!requestError?.requestMethod ? (
                                <Button onClick={() => requestError?.requestMethod?.()} color="primary">
                                    Prøv igjen
                                </Button>
                            ) : null}
                        </div>
                        <p>
                            <button className={commonStyle.link} onClick={clickOutsideHandler}>
                                Avbryt og tilbakestill endringer
                            </button>
                        </p>
                    </Fragment>
                );
            }
        }
    };

    return (
        <Dialog onClickOutside={clickOutsideHandler} closeButton maxWidth="960px">
            {renderDialogBody()}
        </Dialog>
    );
};

export default RequestErrorDialog;
