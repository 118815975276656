// Dependencies
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const SamsvarOgKontrollAnsvarsomraade = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const renderAvvikValue = (ansvarsrettKontrollerende) => {
        if (ansvarsrettKontrollerende?.observerteAvvik === true) {
            return "Observerte avvik er lukket";
        }
        if (ansvarsrettKontrollerende?.aapneAvvik === true) {
            return "Åpne avvik er rapportert til kommunen";
        }
        if (ansvarsrettKontrollerende?.ingenAvvik === true) {
            return "Ingen avvik er funnet";
        } else {
            return "-";
        }
    };

    const formData = selectedForm?.formData;
    const vedlegg = selectedForm?.vedlegg;
    return (
        <Fragment>
            <table>
                <thead>
                    <tr>
                        <th>Kryss av for om det er funnet avvik og om disse er lukket:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{renderAvvikValue(formData.ansvarsrettKontrollerende)}</td>
                    </tr>
                </tbody>
            </table>
            {vedlegg && Object.keys(vedlegg).length ? (
                <table>
                    <thead>
                        <tr>
                            <th>Dokumentasjon av gjenomført kontroll</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Se vedlagt plan for uavhengig kontroll{" "}
                                {vedlegg.filnavn?.length ? `(${vedlegg.filnavn})` : ""}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : null}
        </Fragment>
    );
};

export default SamsvarOgKontrollAnsvarsomraade;
