// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

export const sendEmail = (emailContent, selectedForm, accessToken) => dispatch => {
    const internalApiUrl = getEnvironmentVariable('internalApiUrl');
    const formPath = `/api/v1/email/${selectedForm.referanseId}?status=${selectedForm.status}`;
    const bearerToken = `Bearer ${accessToken}`;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerToken
        },
        body: JSON.stringify(emailContent)
    };
    return fetch(`${internalApiUrl}${formPath}`, fetchOptions);
}