// Dependencies
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

// DIBK Design
import { Button, Header, InputField } from "dibk-design";

// Actions
import { fetchSubmission } from "actions/SubmissionActions";
import { updateLoadingMessage } from "actions/LoadingMessageActions";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const ErklaeringSearch = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State
    const [errorMessage, setErrorMessage] = useState();
    const [submissionId, setSubmissionId] = useState();

    const submissionIdHasValidFormat = (submissionId) => {
        const submissionIdRegex =
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
        const submissionIdIsCorrectlyFormatted = submissionIdRegex.test(submissionId);
        return submissionIdIsCorrectlyFormatted;
    };

    const handleSubmissionIdOnChange = (submissionId) => {
        setSubmissionId(submissionId);
    };

    const handleGoToButtonClick = () => {
        if (!submissionId?.length) {
            setErrorMessage(
                "Du må fylle inn ID-en du har fått av ansvarlig søker. Sjekk e-posten du har fått eller ta kontakt med ansvarlig søker."
            );
        } else {
            const strippedSubmissionId = submissionId.replace(/\s/g, ""); // Remove whitespace
            if (!submissionIdHasValidFormat(strippedSubmissionId)) {
                setErrorMessage("ID-en du har oppgitt er på feil format. Sjekk at du har limt inn riktig ID.");
            } else {
                dispatch(updateLoadingMessage("Henter innsending"));
                dispatch(fetchSubmission(strippedSubmissionId))
                    .then((submission) => {
                        if (!!submission?.xCorrelationId?.length) {
                            setErrorMessage(
                                "Du har oppgitt en ID som ikke eksisterer. Sjekk at du har limt inn riktig ID."
                            );
                        } else {
                            setErrorMessage(null);
                            const submissionUrl = `/skjema/${strippedSubmissionId}/`;
                            navigate(submissionUrl);
                        }
                    })
                    .catch((error) => {
                        console.log("fetchSubmission", error);
                    })
                    .finally(() => {
                        dispatch(updateLoadingMessage(null));
                    });
            }
        }
    };

    return (
        <div className={`${commonStyle.marginTopSmall} ${commonStyle.marginBottomSmall}`}>
            <Header htmlFor="submission-id-input" size={3} htmlTag="label">
                Finn din erklæring
            </Header>
            Lim inn ID-en du har fått fra ansvarlig søker for å finne riktig erklæring.
            <InputField
                id="submission-id-input"
                elementKey="submission-id-input"
                onChange={(event) => handleSubmissionIdOnChange(event.target.value)}
                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                hasErrors={!!errorMessage?.length}
                errorMessage={errorMessage}
            />
            <Button onClick={handleGoToButtonClick} color="primary">
                Gå til erklæring
            </Button>
        </div>
    );
};

export default ErklaeringSearch;
