// Dependencies
import React from "react";
import { useSelector } from "react-redux";

const ErklaeringAnsvarsrett = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const formData = selectedForm?.formData;
    return formData ? (
        <React.Fragment>
            <p>
                Vi er kjent med reglene om straff og sanksjoner i plan- og bygningslovens kapittel 32, og at det kan
                medføre reaksjoner dersom vi oppgir uriktige opplysninger. Vi bekrefter at kvalitetssikring er utført og
                dokumentert i henhold til erklæring om ansvarsrett og foretakets kvalitetssikring.
            </p>
            {formData.funksjonKode === "PRO" ? (
                <div className="checkbox-container">
                    <span className="checkbox">{formData.erklaeringProsjektering === true ? "X" : ""}</span>
                    <span>
                        Ansvarlig prosjekterende bekrefter at prosjekteringen er i samsvar med ytelser i TEK og
                        preaksepterte ytelser (VTEK), eller ved analyse som viser at forskriftens (TEK) funksjonskrav er
                        oppfylt. Eventuelle behov for dispensasjon/unntak er rapportert.
                    </span>
                </div>
            ) : null}
            {formData.funksjonKode === "UTF" ? (
                <div className="checkbox-container">
                    <span className="checkbox">{formData.erklaeringUtfoerelse === true ? "X" : ""}</span>
                    <span>Ansvarlig utførende bekrefter at utførelsen er i samsvar med produksjonsunderlaget.</span>
                </div>
            ) : null}
        </React.Fragment>
    ) : null;
};

export default ErklaeringAnsvarsrett;
