// Dependencies
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

// DIBK Design
import { Button, Dialog, Header } from "dibk-design";

// Actions
import { getSecondsSinceLastInteraction, renewLastInteractionAt } from "actions/LastInteractionAtActions";

const StaySignedInDialog = ({ userManager }) => {
    const dispatch = useDispatch();

    // State
    const [secondsSinceLastInteraction, setSecondsSinceLastInteraction] = useState();

    // Redux store
    const user = useSelector((state) => state.oidc?.user);
    const selectedSubmission = useSelector((state) => state.selectedSubmission);

    // Timeout durations
    const showDialog = user && secondsSinceLastInteraction > 25 * 60; // 25 minutes
    const autoLogOut = user && secondsSinceLastInteraction > 30 * 60; // 30 minutes

    const handleOutsideClick = () => {
        return false;
    };

    const renewToken = () => {
        userManager
            .signinSilent(user)
            .then(() => {
                dispatch(renewLastInteractionAt());
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (autoLogOut) {
            const referanseId = selectedSubmission?.referanseId;
            if (referanseId?.length) {
                sessionStorage.signinRedirectPath = `/skjema/${referanseId}`;
            }
            userManager.signoutRedirect({
                id_token_hint: user?.id_token
            });
            userManager.removeUser();
        }
    }, [autoLogOut, userManager, user, secondsSinceLastInteraction, selectedSubmission?.referanseId]);

    useEffect(() => {
        if (user) {
            const timer = setInterval(() => {
                const updatedSecondsSinceLastInteraction = dispatch(getSecondsSinceLastInteraction());
                setSecondsSinceLastInteraction(updatedSecondsSinceLastInteraction);
            }, 5000);
            return () => clearInterval(timer);
        }
    }, [dispatch, user]);

    return showDialog ? (
        <Dialog onClickOutside={handleOutsideClick} maxWidth="725px">
            <Header content="Er du fortsatt her?" size={3} htmlTag="h2" />
            <div>
                <p>Om du ikke er her vil du snart bli logget ut av sikkerhetsmessige hensyn.</p>
                <div>
                    <Button
                        color="primary"
                        content="Jeg er her"
                        onClick={() => {
                            renewToken();
                            setSecondsSinceLastInteraction(0);
                        }}
                    />
                </div>
            </div>
        </Dialog>
    ) : null;
};

export default StaySignedInDialog;
