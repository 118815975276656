// Dependencies
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// DIBK Design
import { Button, Container, Header, LoadingAnimation, Paper } from 'dibk-design';

// Partials
import MainHeading from 'components/partials/MainHeading';

// Actions
import { fetchSubmission } from 'actions/SubmissionActions';
import { fetchSelectedForm } from 'actions/FormActions';
import { updateLoadingMessage } from 'actions/LoadingMessageActions';

// Helpers
import { formatDate, formatContactInfoText, formatTime, renderProjectDetails } from 'helpers/formatHelpers';
import { getAnsvarsomraaderFromForm, groupAnsvarsomraaderByFunksjon } from 'helpers/ansvarsomraadeHelpers';
import { scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from 'components/routes/common.module.scss';

const Home = ({ userManager }) => {

  const params = useParams();
  const dispatch = useDispatch();

  const submissionId = params?.submissionId || null

  // Redux store
  const selectedForm = useSelector(state => state.selectedForm);
  const selectedSubmission = useSelector(state => state.selectedSubmission);
  const serviceState = useSelector(state => state.serviceState);
  const loadingMessage = useSelector(state => state.loadingMessage);

  // State
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (submissionId) {
      dispatch(updateLoadingMessage('Henter innsending'));
      dispatch(fetchSubmission(submissionId)).then((submission) => {
        dispatch(updateLoadingMessage('Henter skjema'));

        const submissionIsDeleted = !!submission?.slettetDato?.length;

        if (submission && Object.keys(submission).length && !submissionIsDeleted) {
          return dispatch(fetchSelectedForm(submission)).then((response) => {
            if (response) {
              setErrorMessage(null);
            } else {
              setErrorMessage(`Kunne ikke hente skjema med referanse: ${submissionId}`);
            }
            return response;
          }).catch(error => {
            console.log("fetchSelectedForm", error)
          }).finally(() => {
            dispatch(updateLoadingMessage(null));
          });
        } else if(submissionIsDeleted) {

        }


      }).catch(error => {
        console.log("fetchSubmission", error)
      }).finally(() => {
        dispatch(updateLoadingMessage(null))
      })
    }
  }, [dispatch, submissionId])


  const handleLoginClick = (event) => {
    event.preventDefault();
    sessionStorage.signinRedirectPath = `/skjema/${selectedForm?.referanseId}`;
    userManager.signinRedirect();
  }

  const renderAnsvarsomraaderList = (ansvarsomraader) => {
    const ansvarsomraadeByFunksjon = groupAnsvarsomraaderByFunksjon(ansvarsomraader);
    return ansvarsomraadeByFunksjon && Object.keys(ansvarsomraadeByFunksjon)?.length
      ? Object.keys(ansvarsomraadeByFunksjon).map(ansvarsomraadeFunksjonKey => {
        const ansvarsomraadeFunksjon = ansvarsomraadeByFunksjon[ansvarsomraadeFunksjonKey];
        const ansvarsomraadeListElements = ansvarsomraadeFunksjon.ansvarsomraader.filter(ansvarsomraade => {
          return ansvarsomraadeFunksjonKey !== 'SØK'
        }).map((ansvarsomraade, ansvarsomraadeIndex) => {
          return (
            <li key={ansvarsomraadeIndex}>{ansvarsomraade.beskrivelseAvAnsvarsomraade}</li>
          )
        })
        return (
          <div key={ansvarsomraadeFunksjonKey} className={commonStyle.marginBottomSmall}>
            <Header size={4} content={`${ansvarsomraadeFunksjon?.funksjonBeskrivelse}${ansvarsomraadeFunksjonKey !== 'SØK' ? ':' : ''}`} />
            <ul>{ansvarsomraadeListElements}</ul>
          </div>
        )
      })
      : '';
  }

  const getStatusPageName = (form) => {
    const status = form?.status;
    switch (status) {
        case "tilSignering":
        case "iArbeid":
            return 'Logg inn';
        case "signert":
            return 'Allerede signert';
        case "signertManuelt":
            return 'Signert manuelt';
        case "avvist":
            return 'Avvist';
        case "utgått":
            return 'Utgått';
        case "trukket":
            return 'Trukket';
        case "avsluttet":
            return 'Avsluttet';
        case "feilet":
            return 'Feilet';
        default:
            return null;
    }
};

  

  const renderSelectedForm = (form) => {
    const ansvarsomraader = getAnsvarsomraaderFromForm(form);
    switch (form.status) {
      case "tilSignering":
      case "iArbeid":
        return (
          <React.Fragment>
            <Header size={2} content={`${form?.formData?.ansvarligSoeker?.navn} har sendt en erklæring til signering`} />
            <div className={commonStyle.introText}>
              <div className={commonStyle.paragraphGroup}>
                {renderProjectDetails(form)}
              </div>
              {
                form?.signeringsfrist?.length
                  ? (
                    <div className={commonStyle.paragraphGroup}>
                      <Header size={3} content={`Frist for signering er ${formatDate(form.signeringsfrist)}`} />
                      <div>Etter fristen vil det ikke lenger være mulig å signere erklæringen.</div>
                    </div>
                  )
                  : ''
              }
              {
                ansvarsomraader?.length
                  ? (
                    <div className={commonStyle.paragraphGroup}>
                      <Header size={3} content={ansvarsomraader.length > 1 ? 'Ansvarsområder' : 'Ansvarsområde'} />
                      {renderAnsvarsomraaderList(ansvarsomraader)}
                    </div>
                  )
                  : ''
              }
            </div>
            <Header size={3} content="Har du spørsmål?" />
            <div>{formatContactInfoText(form)}</div>
            <div className={commonStyle.marginTop}>
              <Button content="Logg inn" color='primary' onClick={handleLoginClick} disabled={serviceState?.state === 'offline'} />
            </div>
          </React.Fragment >
        );
      case "signert":
        const foretakEpost = form?.formData?.ansvarligForetak?.kontaktpersonEpost || form?.formData?.ansvarligForetak?.epost;
        return (
          <React.Fragment>
            <Header size={2} content={`${form?.formData?.ansvarligForetak?.navn} har allerede signert erklæringen`} />
            <div className={commonStyle.introText}>
              <div className={commonStyle.paragraphGroup}>
                {
                  form?.signertDato ? <p>Erklæringen ble signert {formatDate(form.signertDato)} kl. {formatTime(form.signertDato)} med referanse {form.ftpbReferanseId}.</p> : ''
                }
              </div>
              <div className={commonStyle.paragraphGroup}>
                <Header size={3} content="Hvor finner jeg erklæringen?" />
                <p>Erklæringen er sendt til {form?.formData?.ansvarligSoeker?.navn}, som er ansvarlig søker.</p>
                {
                  foretakEpost ? <p>En kopi av den signerte erklæringen er også sendt til {foretakEpost}.</p> : ''
                }
              </div>
              <div className={commonStyle.paragraphGroup}>
                {renderProjectDetails(form)}
              </div>
              <Header size={3} content="Har du spørsmål?" />
              <div>{formatContactInfoText(form)}</div>
            </div>
          </React.Fragment>
        );
      case "signertManuelt":
        return (
          <React.Fragment>
            <Header size={2} content="Erklæringen er signert manuelt" />
            <div className={commonStyle.introText}>
              <div className={commonStyle.paragraphGroup}>
                <p>Ansvarlig søker har oppgitt at denne erklæringen har blitt signert manuelt.</p>
              </div>
              <div className={commonStyle.paragraphGroup}>
                {renderProjectDetails(form)}
              </div>
              <Header size={3} content="Har du spørsmål?" />
              <div>{formatContactInfoText(form)}</div>
            </div>
          </React.Fragment>
        );
      case "avvist":
        return (
          <React.Fragment>
            <Header size={2} content={`${form?.formData?.ansvarligForetak?.navn} har avvist erklæringen`} />
            <div className={commonStyle.introText}>
              {
                form?.statusReason?.length ?
                  (
                    <React.Fragment>
                      <div className={commonStyle.paragraphGroup}>
                        <Header size={3} content={`${form?.formData?.ansvarligForetak?.navn} har meldt om følgende feil:`} />
                        <p><i>{form.statusReason}</i></p>
                      </div>
                      <div className={commonStyle.paragraphGroup}>
                        <p>Meldingen er sendt til {form?.formData?.ansvarligSoeker?.navn}, som er ansvarlig søker.</p>
                      </div>
                    </React.Fragment>
                  )
                  : null
              }
              <div className={commonStyle.paragraphGroup}>
                {renderProjectDetails(form)}
              </div>
              <Header size={3} content="Har du spørsmål?" />
              <div>{formatContactInfoText(form)}</div>
            </div>
          </React.Fragment>
        );
      case "utgått":
        return (
          <React.Fragment>
            <Header size={2} content="Fristen for å signere erklæringen har gått ut" />
            <div className={commonStyle.introText}>
              <div className={commonStyle.paragraphGroup}>
                {
                  form?.signeringsfrist ? <p>Fristen gikk ut {formatDate(form.signeringsfrist)}.</p> : ''
                }
              </div>
              <div className={commonStyle.paragraphGroup}>
                <Header size={3} content="Vil du få erklæringen tilsendt på nytt?" />
                <div>{formatContactInfoText(form)}</div>
              </div>
              <div className={commonStyle.paragraphGroup}>
                {renderProjectDetails(form)}
              </div>
            </div>
          </React.Fragment>
        );
      case "trukket":
        return (
          <React.Fragment>
            <Header size={2} content={`${form?.formData?.ansvarligSoeker?.navn} har trukket tilbake erklæringen`} />
            <div className={commonStyle.introText}>
              <div className={commonStyle.paragraphGroup}>
                <Header size={3} content={`${form?.formData?.ansvarligSoeker?.navn} har gitt følgende begrunnelse:`} />
                <p><i>{form.statusReason}</i></p>
              </div>
              <div className={commonStyle.paragraphGroup}>
                <Header size={3} content="Har du spørsmål?" />
                <div>{formatContactInfoText(form)}</div>
              </div>
              <div className={commonStyle.paragraphGroup}>
                {renderProjectDetails(form)}
              </div>
            </div>
          </React.Fragment>
        );
      case "avsluttet":
        return (
          <React.Fragment>
            <Header size={2} content="Erklæringen er avsluttet" />
            <div className={commonStyle.introText}>
              <div className={commonStyle.paragraphGroup}>
                <p>Ansvarlig søker har oppgitt at denne erklæringen er avsluttet.</p>
              </div>
              <div className={commonStyle.paragraphGroup}>
                <Header size={3} content="Har du spørsmål?" />
                <div>{formatContactInfoText(form)}</div>
              </div>
              <div className={commonStyle.paragraphGroup}>
                {renderProjectDetails(form)}
              </div>
            </div>
          </React.Fragment>
        );
      case "feilet":
        return (
          <React.Fragment>
            <Header size={2} content="Erklæringen har feilet" />
            <div className={commonStyle.introText}>
              <div className={commonStyle.paragraphGroup}>
                {
                  form?.statusReason?.length
                    ? (
                      <React.Fragment>
                        <Header size={3} content="Vi kunne ikke laste erklæringen på grunn av følgende feil:" />
                        <div>{form.statusReason}</div>
                      </React.Fragment>
                    ) : ''
                }

              </div>
              <div className={commonStyle.paragraphGroup}>
                <Header size={3} content="Har du spørsmål?" />
                <p>Ta kontakt med Direktoratet for byggkvalitet på e-post <a href="mailto:ftb@dibk.no">ftb@dibk.no</a>.</p>
                <p>Oppgi referansekode: {form?.ftpbReferanseId}</p>
              </div>
              <div className={commonStyle.paragraphGroup}>
                {renderProjectDetails(form)}
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return '';
    }
  }

  const renderDeletedFormInfo = () => {
    return (
      <React.Fragment>
            <Header size={2} content="Lenken er utgått" />
            <div className={commonStyle.introText}>
              <div className={commonStyle.paragraphGroup}>
                <p>Vi har slettet all informasjon om erklæringen, da det har gått 12 måneder siden signeringsoppdraget ble sendt fra ansvarlig søker. <a
                    href={`${window?.location?.origin}/personvernerklaering`}
                    target="_blank"
                    title="Personvernerklæring"
                    rel="noopener noreferrer"
                >
                    Les mer om hvordan vi behandler data i personvernerklæringen.
                </a></p>
              </div>
              <div className={commonStyle.paragraphGroup}>
                <p>Ta kontakt med ansvarlig søker dersom du ønsker å få tilsendt erklæringen på nytt.</p>
              </div>
            </div>
          </React.Fragment>
    )
  }

  const renderMainHeading = () => {
      const submissionIsDeleted = !!selectedSubmission?.slettetDato?.length;
      if (!!selectedForm && !!Object.keys(selectedForm)?.length){
        return  (<MainHeading form={selectedForm} pageName={getStatusPageName(selectedForm)} />)
      } else if (submissionIsDeleted) {
        return  (<MainHeading form={selectedSubmission} pageName="Foreldet" />)
      }
  }

  const renderErrorMessage = (errorMessage) => {
    return (
      <React.Fragment>
        <Header content="Feil" />
        <p>{errorMessage}</p>
      </React.Fragment>
    );
  }

  const renderContent = () => {
    const submissionIsDeleted = !!selectedSubmission?.slettetDato?.length;
    const hasSelectedForm = selectedForm && Object.keys(selectedForm)?.length;
    if (hasSelectedForm){
      return renderSelectedForm(selectedForm);
    } else if (submissionIsDeleted){
      return renderDeletedFormInfo()
    }
  }

  return (
    <Container>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <Paper>
      { renderMainHeading() }
      {errorMessage ? renderErrorMessage(errorMessage) : ''}
      {renderContent()}
      </Paper>
      {
        loadingMessage?.length
          ? <LoadingAnimation fixed message={loadingMessage} />
          : ''
      }
    </Container>
  )

}

export default Home;
