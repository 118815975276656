// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// DIBK Design
import { Accordion, Label } from "dibk-design";

// Actions
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

// Helpers
import { formatAddress } from "helpers/formatHelpers";

// Stylesheets
import formsStyle from "components/partials/Forms/Forms.module.scss";
import commonStyle from "components/routes/common.module.scss";

const EiendomByggestedList = ({ eiendomByggesteder }) => {
    const dispatch = useDispatch();

    const getMunicipalityField = (eiendomByggested) => {
        let municipalityField;
        if (eiendomByggested.kommunenavn) {
            municipalityField = {
                label: "Kommune",
                value: eiendomByggested.kommunenummer
                    ? `${eiendomByggested.kommunenavn} (${eiendomByggested.kommunenummer})`
                    : `${eiendomByggested.kommunenavn}`
            };
        } else if (eiendomByggested.kommunenummer) {
            municipalityField = {
                label: "Kommunenummer",
                value: eiendomByggested.kommunenummer
            };
        }
        return municipalityField;
    };

    const renderEiendomByggestedItem = (eiendomByggested) => {
        const municipalityField = getMunicipalityField(eiendomByggested);
        return (
            <div key={0}>
                {!!eiendomByggested.adresselinje2?.length || !!eiendomByggested.adresselinje3?.length ? (
                    <div className={commonStyle.marginBottomSmall}>
                        <ul className={formsStyle.cleanList}>
                            {!!eiendomByggested.adresselinje2?.length ? (
                                <li>{eiendomByggested.adresselinje2}</li>
                            ) : null}
                            {!!eiendomByggested.adresselinje3?.length ? (
                                <li>{eiendomByggested.adresselinje3}</li>
                            ) : null}
                        </ul>
                    </div>
                ) : null}

                <dl className={formsStyle.fieldList}>
                    {!!municipalityField && (
                        <React.Fragment>
                            <dt>
                                <Label normalCursor htmlTag="span">
                                    {municipalityField.label}
                                </Label>
                            </dt>
                            <dd>{municipalityField.value}</dd>
                        </React.Fragment>
                    )}
                    {!!eiendomByggested.gaardsnummer && (
                        <React.Fragment>
                            <dt>
                                <Label normalCursor htmlTag="span">
                                    Gårdsnummer
                                </Label>
                            </dt>
                            <dd>{eiendomByggested.gaardsnummer}</dd>
                        </React.Fragment>
                    )}
                    {!!eiendomByggested.gaardsnummer && (
                        <React.Fragment>
                            <dt>
                                <Label normalCursor htmlTag="span">
                                    Bruksnummer
                                </Label>
                            </dt>
                            <dd>{eiendomByggested.bruksnummer}</dd>
                        </React.Fragment>
                    )}
                    {!!eiendomByggested.festenummer && (
                        <React.Fragment>
                            <dt>
                                <Label normalCursor htmlTag="span">
                                    Festenummer
                                </Label>
                            </dt>
                            <dd>{eiendomByggested.festenummer}</dd>
                        </React.Fragment>
                    )}
                    {!!eiendomByggested.seksjonsnummer && (
                        <React.Fragment>
                            <dt>
                                <Label normalCursor htmlTag="span">
                                    Seksjonsnummer
                                </Label>
                            </dt>
                            <dd>{eiendomByggested.seksjonsnummer}</dd>
                        </React.Fragment>
                    )}
                    {!!eiendomByggested.bygningsnummer && (
                        <React.Fragment>
                            <dt>
                                <Label normalCursor htmlTag="span">
                                    Bygningsnummer
                                </Label>
                            </dt>
                            <dd>{eiendomByggested.bygningsnummer}</dd>
                        </React.Fragment>
                    )}
                    {!!eiendomByggested.bolignummer && (
                        <React.Fragment>
                            <dt>
                                <Label normalCursor htmlTag="span">
                                    Bolignummer
                                </Label>
                            </dt>
                            <dd>{eiendomByggested.bolignummer}</dd>
                        </React.Fragment>
                    )}
                </dl>
            </div>
        );
    };

    const renderEiendomByggestedListItem = (eiendomByggested, index) => {
        const title = formatAddress(eiendomByggested);

        return (
            <div key={index} className={commonStyle.marginBottomSmall}>
                <Accordion
                    title={title}
                    onToggleExpand={() => {
                        dispatch(renewLastInteractionAt());
                    }}
                >
                    {renderEiendomByggestedItem(eiendomByggested)}
                </Accordion>
            </div>
        );
    };

    return eiendomByggesteder?.length ? (
        eiendomByggesteder.map((eiendomByggested, index) => {
            return renderEiendomByggestedListItem(eiendomByggested, index);
        })
    ) : (
        <p>Ingen data for Eiendom/Byggested</p>
    );
};

EiendomByggestedList.propTypes = {
    eiendomByggesteder: PropTypes.array.isRequired
};

export default EiendomByggestedList;
