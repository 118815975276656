// Dependencies
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { CheckBoxList, CheckBoxListItem, ErrorMessage } from "dibk-design";

// Actions
import { validateErklaeringKontrollCheckbox } from "actions/ValidationActions";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const Erklaering = ({ formData, updateHandler, validationHandler }) => {
    const dispatch = useDispatch();

    // Redux store
    const validationMessages = useSelector((state) => state.validationMessages);

    const handleOnChange = (value, property, validations) => {
        updateHandler({
            ...formData,
            [property]: value
        }).then(() => {
            validationHandler(validations);
        });
    };

    return formData ? (
        <Fragment>
            <CheckBoxList compact>
                <legend className={`${commonStyle.marginBottomSmall} ${commonStyle.normalText}`}>
                    Vi er kjent med reglene om straff og sanksjoner i plan- og bygningsloven kapittel 32, og at det kan
                    medføre reaksjoner dersom vi oppgir uriktige opplysninger.
                </legend>
                <CheckBoxListItem
                    id="erklaeringKontroll"
                    onChange={(event) => {
                        handleOnChange(event.target.checked, "erklaeringKontroll", () => {
                            dispatch(validateErklaeringKontrollCheckbox());
                        });
                    }}
                    checked={formData.erklaeringKontroll ? true : false}
                    checkmarkCharacter="✕"
                    required
                    hasErrors={
                        !!validationMessages?.erklaeringCheckboxes?.message?.length && !formData.erklaeringKontroll
                    }
                    aria-describedby={
                        !!validationMessages?.erklaeringCheckboxes?.message?.length
                            ? "erklaeringCheckboxes-errorMessage"
                            : null
                    }
                >
                    Ansvarlig kontrollerende bekrefter at kontrollen er gjennomført på en forskriftsmessig måte, og at
                    kontrollforetaket er uavhengig av foretakene som er kontrollert.
                </CheckBoxListItem>
            </CheckBoxList>
            {!!validationMessages?.erklaeringCheckboxes?.message?.length && !formData.erklaeringKontroll ? (
                <ErrorMessage
                    id="erklaeringCheckboxes-errorMessage"
                    content={validationMessages.erklaeringCheckboxes.message}
                />
            ) : null}
        </Fragment>
    ) : (
        ""
    );
};

Erklaering.propTypes = {
    formData: PropTypes.object.isRequired,
    updateHandler: PropTypes.func.isRequired,
    validationHandler: PropTypes.func.isRequired
};

export default Erklaering;
