// Dependencies
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import {
    Accordion,
    Button,
    Dialog,
    DragAndDropFileInput,
    ErrorMessage,
    Header,
    RadioButtonList,
    RadioButtonListItem
} from "dibk-design";

// Actions
import { validateAnsvarsrettKontrollerendeRadioButtons, validateAttachment } from "actions/ValidationActions";
import {
    removeAttachmentFromSelectedForm,
    uploadAttachment,
    updateAttachmentWithoutUpload
} from "actions/AttachmentActions";
import { removeSigningProcess } from "actions/SigningActions";
import { updateLoadingMessage } from "actions/LoadingMessageActions";
import { updateIsSavingData } from "actions/IsSavingDataActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

// Stylesheets
import formsStyle from "components/partials/Forms/Forms.module.scss";
import commonStyle from "components/routes/common.module.scss";

const AnsvarsomraadeMedSluttrapport = ({ updateHandler, validationHandler, fetchFormData, setRequestError }) => {
    const dispatch = useDispatch();

    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);
    const validationMessages = useSelector((state) => state.validationMessages);
    const oidc = useSelector((state) => state.oidc);

    // State
    const [showHarAktivSigneringsjobbDialog, setShowHarAktivSigneringsjobbDialog] = useState();
    const [fileToUpload, setFileToUpload] = useState();

    const handleUpdate = (value, property, subProperty) => {
        let updatedFormData = selectedForm.formData;
        if (subProperty) {
            updatedFormData[property][subProperty] = value;
        } else {
            updatedFormData[property] = value;
        }
        return updateHandler(updatedFormData);
    };

    const handleValidate = (validations) => {
        validationHandler(validations);
    };

    const handleFileInputOnChange = (file) => {
        const accessToken = oidc?.user?.access_token;
        dispatch(updateAttachmentWithoutUpload(file, selectedForm));
        const attachmentValidationMessages = dispatch(validateAttachment());
        if (!attachmentValidationMessages?.length) {
            dispatch(updateIsSavingData(true));
            dispatch(updateLoadingMessage("Laster opp vedlegg"));
            dispatch(uploadAttachment(file, selectedForm, accessToken)).then((response) => {
                dispatch(updateIsSavingData(false));
                dispatch(updateLoadingMessage(null));
                const xCorrelationId = response.xCorrelationId;
                const problemDetails = response.problemDetails;
                const metadata = response.metadata;
                if (response.status === 409) {
                    setShowHarAktivSigneringsjobbDialog(true);
                    setFileToUpload(file);
                } else if (response.status === 400 || response.status === 504) {
                    setRequestError({
                        xCorrelationId,
                        problemDetails,
                        metadata
                    });
                    dispatch(removeAttachmentFromSelectedForm(selectedForm));
                    dispatch(validateAttachment());
                }
            });
        }
    };

    const handleClickOutsideHarAktivSigneringsjobbDialog = () => {
        const submissionId = selectedForm.referanseId;
        const scrollPositionBeforeReload = document.getElementById("main-content").scrollTop;
        dispatch(fetchFormData(submissionId)).then(() => {
            setShowHarAktivSigneringsjobbDialog(false);
            setTimeout(() => {
                document.getElementById("main-content").scrollTop = scrollPositionBeforeReload;
            }, 100);
        });
    };

    const handleSubmitHarAktivSigneringsjobbButtonClick = () => {
        const accessToken = oidc?.user?.access_token;
        setShowHarAktivSigneringsjobbDialog(false);
        dispatch(removeSigningProcess(selectedForm, accessToken)).then(() => {
            dispatch(uploadAttachment(fileToUpload, selectedForm, accessToken));
        });
    };

    const formData = selectedForm?.formData;
    return formData ? (
        <React.Fragment>
            <div className={commonStyle.marginBottomSmall}>
                Du må svare på om det er funnet avvik og legge ved dokumentasjon av gjennomført kontroll.
            </div>
            <div className={commonStyle.marginBottomSmall}>
                <Accordion
                    title="Sluttrapport for kontroll"
                    expanded
                    onToggleExpand={() => {
                        dispatch(renewLastInteractionAt());
                    }}
                >
                    <div className={formsStyle.fieldSection}>
                        <RadioButtonList
                            compact
                            required
                            legend="Kryss av for om det er funnet avvik og om disse er lukket:"
                        >
                            <RadioButtonListItem
                                id={`ansvarsrettKontrollerende-ingenAvvik`}
                                name="ansvarsrettKontrollerende"
                                onChange={() => {
                                    handleUpdate(true, "ansvarsrettKontrollerende", "ingenAvvik");
                                    handleUpdate(false, "ansvarsrettKontrollerende", "observerteAvvik");
                                    handleUpdate(false, "ansvarsrettKontrollerende", "aapneAvvik");
                                    handleValidate(() => {
                                        dispatch(validateAnsvarsrettKontrollerendeRadioButtons());
                                    });
                                }}
                                inputValue="ingenAvvik"
                                checked={formData.ansvarsrettKontrollerende?.ingenAvvik}
                                hasErrors={
                                    !!validationMessages?.ansvarsrettKontrollerendeRadioButtons?.message?.length
                                        ? true
                                        : false
                                }
                                aria-describedby={
                                    !!validationMessages?.ansvarsrettKontrollerendeRadioButtons?.message?.length
                                        ? "ansvarsrettKontrollerendeRadioButtons-errorMessage"
                                        : null
                                }
                                compact
                            >
                                Ingen avvik er funnet
                            </RadioButtonListItem>
                            <RadioButtonListItem
                                id={`ansvarsrettKontrollerende-observerteAvvik`}
                                name="ansvarsrettKontrollerende"
                                onChange={(event) => {
                                    handleUpdate(false, "ansvarsrettKontrollerende", "ingenAvvik");
                                    handleUpdate(true, "ansvarsrettKontrollerende", "observerteAvvik");
                                    handleUpdate(false, "ansvarsrettKontrollerende", "aapneAvvik");
                                    handleValidate(() => {
                                        dispatch(validateAnsvarsrettKontrollerendeRadioButtons());
                                    });
                                }}
                                inputValue="observerteAvvik"
                                checked={formData.ansvarsrettKontrollerende?.observerteAvvik}
                                hasErrors={
                                    !!validationMessages?.ansvarsrettKontrollerendeRadioButtons?.message?.length
                                        ? true
                                        : false
                                }
                                aria-describedby={
                                    !!validationMessages?.ansvarsrettKontrollerendeRadioButtons?.message?.length
                                        ? "ansvarsrettKontrollerendeRadioButtons-errorMessage"
                                        : null
                                }
                                compact
                            >
                                Observerte avvik er lukket
                            </RadioButtonListItem>
                            <RadioButtonListItem
                                id={`ansvarsrettKontrollerende-aapneAvvik`}
                                name="ansvarsrettKontrollerende"
                                onChange={(event) => {
                                    handleUpdate(false, "ansvarsrettKontrollerende", "ingenAvvik");
                                    handleUpdate(false, "ansvarsrettKontrollerende", "observerteAvvik");
                                    handleUpdate(true, "ansvarsrettKontrollerende", "aapneAvvik");
                                    handleValidate(() => {
                                        dispatch(validateAnsvarsrettKontrollerendeRadioButtons());
                                    });
                                }}
                                inputValue="aapneAvvik"
                                checked={formData.ansvarsrettKontrollerende?.aapneAvvik}
                                hasErrors={
                                    !!validationMessages?.ansvarsrettKontrollerendeRadioButtons?.message?.length
                                        ? true
                                        : false
                                }
                                aria-describedby={
                                    !!validationMessages?.ansvarsrettKontrollerendeRadioButtons?.message?.length
                                        ? "ansvarsrettKontrollerendeRadioButtons-errorMessage"
                                        : null
                                }
                                compact
                            >
                                Åpne avvik er rapportert til kommunen
                            </RadioButtonListItem>
                        </RadioButtonList>
                        {validationMessages?.ansvarsrettKontrollerendeRadioButtons?.message?.length ? (
                            <ErrorMessage
                                id="ansvarsrettKontrollerendeRadioButtons-errorMessage"
                                content={validationMessages.ansvarsrettKontrollerendeRadioButtons.message}
                            />
                        ) : null}
                    </div>
                    <div className={formsStyle.fieldSection}>
                        <DragAndDropFileInput
                            id="vedlegg"
                            label="Dokumentasjon av gjennomført kontroll"
                            buttonContent="Velg fil"
                            buttonContentWhenSelectedFile="Velg annen fil"
                            onSelectChange={(event) => handleFileInputOnChange(event.target.files?.[0])}
                            onDragAndDropChange={(value) => handleFileInputOnChange(value?.[0])}
                            selectedFileName={selectedForm?.vedlegg?.filnavn || ""}
                            required
                            hasErrors={validationMessages?.attachment?.message?.length > 0 ? true : false}
                            errorMessage={validationMessages?.attachment?.message || ""}
                        >
                            <div className={commonStyle.marginBottomSmall}>
                                <p>
                                    Du kan laste opp egen dokumentasjon eller bruke skjemaet{" "}
                                    <a
                                        href="https://dibk.no/globalassets/blanketter_utfyllbare/alle-blanketter/5191-plan-for-uavhengig-kontroll.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Plan for uavhengig kontroll på DiBK sine sider
                                    </a>
                                    . Filen må være i PDF-format, maks 10MB stor og med kortere navn enn 80 tegn.
                                </p>
                                <p>Vedlegget blir automatisk signert når du signerer kontrollerklæringen.</p>
                            </div>
                        </DragAndDropFileInput>
                    </div>
                </Accordion>
            </div>
            {showHarAktivSigneringsjobbDialog ? (
                <Dialog onClickOutside={handleClickOutsideHarAktivSigneringsjobbDialog} closeButton maxWidth="960px">
                    <Header content="Erklæringen har allerede blitt sendt til signering" size={2} />
                    <p>
                        Denne erklæringen har tidligere blitt sendt til signering, men signeringen ble ikke fullført.
                        Vil du avbryte den andre signeringsjobben og fortsette redigering?
                    </p>
                    <div className={`${commonStyle.marginTop} ${commonStyle.marginBottomSmall}`}>
                        <Button
                            content="Ja, fortsett redigering"
                            onClick={handleSubmitHarAktivSigneringsjobbButtonClick}
                            color="primary"
                        />
                    </div>
                    <p>
                        <span className={commonStyle.link} onClick={handleClickOutsideHarAktivSigneringsjobbDialog}>
                            Avbryt og tilbakestill endringer
                        </span>
                    </p>
                </Dialog>
            ) : (
                ""
            )}
        </React.Fragment>
    ) : (
        ""
    );
};

AnsvarsomraadeMedSluttrapport.propTypes = {
    updateHandler: PropTypes.func.isRequired,
    validationHandler: PropTypes.func.isRequired,
    fetchFormData: PropTypes.func.isRequired
};

export default AnsvarsomraadeMedSluttrapport;
