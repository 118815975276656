// Dependencies
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";
import debounce from "lodash.debounce";

// Components
import App from "App";

// DIBK Design
import { Button, Container, Dialog, ErrorBox, Header, InfoBox, LoadingAnimation, Paper, Textarea } from "dibk-design";

// Partials
import Ansvarsrett from "components/partials/Forms/Ansvarsrett";
import KontrollErklaering from "components/partials/Forms/KontrollErklaering";
import SamsvarsErklaering from "components/partials/Forms/SamsvarsErklaering";
import RequestErrorDialog from "components/partials/RequestErrorDialog";

// Actions
import { fetchSubmission } from "actions/SubmissionActions";
import { fetchSelectedForm, rejectSelectedForm, saveSelectedForm, updateSelectedForm } from "actions/FormActions";
import { initateSigning, removeSigningProcess } from "actions/SigningActions";
import { convertSelectedFormToPDF } from "actions/PrintActions";
import { updateIsSavingData } from "actions/IsSavingDataActions";
import { updateIsEdited } from "actions/IsEditedActions";
import { updateLoadingMessage } from "actions/LoadingMessageActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

import {
    // Alle skjemaer
    updateIsValidated,
    validateAnsvarligForetakKontaktpersonEpost,
    validateAnsvarligForetakKontaktpersonNavn,
    validateAnsvarligForetakKontaktpersonTelefonnummer,
    // Erklæring om ansvarsrett
    validateBeskrivelseForAllAnsvarsomraader,
    validateTiltaksklasseForAllAnsvarsomraader,
    validateDekkesOmradetAvSentralGodkjenningForAllAnsvarsomraader,
    validateSamsvarKontrollCheckboxesForAllAnsvarsomraader,
    validateErklaeringCheckboxes,
    // Samsvarserklæring
    validateAnsvarsrettProsjekterendeCheckboxes,
    validateAnsvarsomraadetAvsluttet,
    validateAnsvarsrettUtfoerendeTillatelse,
    validateMidlertidigBrukstillatelseGjennstaaendeInnenfor,
    validateHarTilstrekkeligSikkerhet,
    validateTypeArbeider,
    validateUtfoertInnen,
    validateErklaeringSamsvarCheckboxes,
    // Kontrollerklæring
    validateAnsvarsrettKontrollerendeRadioButtons,
    validateAttachment,
    getValidationMessageSummary,
    validateErklaeringKontrollCheckbox
} from "actions/ValidationActions";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";
import style from "components/routes/Forms/FormType/Form.module.scss";

/* eslint import/no-webpack-loader-syntax: off */
import printStyle from "!!raw-loader!sass-loader!../../../../print.scss";

const Form = ({ showRejectDialog }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    // Redux store
    const loadingMessage = useSelector((state) => state.loadingMessage);
    const selectedSubmission = useSelector((state) => state.selectedSubmission);
    const selectedForm = useSelector((state) => state.selectedForm);
    const oidc = useSelector((state) => state.oidc);
    const isEdited = useSelector((state) => state.isEdited);
    const isSavingData = useSelector((state) => state.isSavingData);
    const isValidated = useSelector((state) => state.isValidated);
    const validationMessages = useSelector((state) => state.validationMessages);

    // State
    const [isLoaded, setIsLoaded] = useState();
    const [rejectionMessage, setRejectionMessage] = useState();
    const [showHarAktivSigneringsjobbDialog, setShowHarAktivSigneringsjobbDialog] = useState();
    const [requestError, setRequestError] = useState();
    const [selectedFormSaveRequestAbort, setSelectedFormSaveRequestAbort] = useState();

    // Params
    const submissionId = params.submissionId;

    const fetchFormData = useCallback(
        (submissionId) => {
            dispatch(updateLoadingMessage("Henter innsending"));
            return dispatch(fetchSubmission(submissionId))
                .then((fetchedSubmission) => {
                    dispatch(updateLoadingMessage("Henter skjema"));
                    if (fetchedSubmission && Object.keys(fetchedSubmission).length) {
                        return dispatch(fetchSelectedForm(fetchedSubmission))
                            .then((fetchedForm) => {
                                dispatch(updateLoadingMessage(null));
                                return fetchedForm;
                            })
                            .catch((error) => {
                                console.log("fetchSelectedForm", error);
                                dispatch(updateLoadingMessage(null));
                            });
                    }
                })
                .catch((error) => {
                    console.log("fetchSubmission", error);
                    dispatch(updateLoadingMessage(null));
                });
        },
        [dispatch]
    );

    const handleValidate = (validations) => {
        if (validations) {
            validations();
        }
    };

    const handleApiResponseStatus = useCallback(
        (status, selectedForm, xCorrelationId, requestMethod) => {
            if (status === 409) {
                // Conflict
                setShowHarAktivSigneringsjobbDialog(true);
            } else if (status === 400) {
                // Bad request
                navigate(`/skjema/${selectedForm.referanseId}`);
            } else if (status === 401) {
                // Unauthorized
                navigate(`/skjema/${selectedForm.referanseId}`);
            } else if (status === 404) {
                // Not found
                setRequestError({
                    requestMethod,
                    xCorrelationId
                });
            } else if (status === 500) {
                // Internal server error
                setRequestError({
                    requestMethod,
                    xCorrelationId
                });
            }
        },
        [navigate]
    );

    const saveForm = useCallback(
        (selectedForm, accessToken, selectedFormSaveRequestAbort) => {
            const isAlreadySavingData = isSavingData;
            if (isAlreadySavingData) {
                selectedFormSaveRequestAbort?.abort();
                setSelectedFormSaveRequestAbort(null);
                dispatch(updateIsSavingData(false));
            } else {
                dispatch(updateIsSavingData(true));
            }
            dispatch(updateIsEdited(false));
            const selectedFormSaveRequest = dispatch(saveSelectedForm(selectedForm, accessToken));
            selectedFormSaveRequest.ready.then((response) => {
                dispatch(updateIsSavingData(false));
                handleApiResponseStatus(response.status, selectedForm, response.xCorrelationId, () => {
                    dispatch(saveSelectedForm(selectedForm, accessToken));
                });
            });
            setSelectedFormSaveRequestAbort(selectedFormSaveRequest.abortController);
        },
        [dispatch, isSavingData, handleApiResponseStatus]
    );

    const debouncedSave = useMemo(
        () =>
            debounce(async (selectedForm, accessToken, selectedFormSaveRequestAbort) => {
                await saveForm(selectedForm, accessToken, selectedFormSaveRequestAbort);
            }, 2000),
        [saveForm]
    );

    useEffect(() => {
        const accessToken = oidc?.user?.access_token;

        if (isEdited) {
            debouncedSave(selectedForm, accessToken, selectedFormSaveRequestAbort);
            dispatch(renewLastInteractionAt());
        }
    }, [isEdited, selectedForm, oidc, debouncedSave, selectedFormSaveRequestAbort, dispatch]);

    const redirectIfNotValidStatus = useCallback(
        (formData) => {
            const validStatuses = ["tilSignering", "iArbeid"];
            const isValidStatus = validStatuses.some((status) => {
                return formData?.status === status;
            });
            if (!isValidStatus) {
                navigate(`/skjema/${formData.referanseId}`);
            }
        },
        [navigate]
    );

    // Redirect if not authenticated
    useEffect(() => {
        if (!oidc?.user) {
            navigate(`/skjema/${submissionId}/utlogget`);
        }
    }, [navigate, oidc?.user, submissionId]);

    useEffect(() => {
        const isAuthenticated = oidc?.user && Object.keys(oidc.user).length;
        if (isAuthenticated) {
            if (!selectedForm || !Object.keys(selectedForm).length) {
                fetchFormData(submissionId).then((fetchedForm) => {
                    redirectIfNotValidStatus(fetchedForm);
                    setIsLoaded(true);
                });
            } else {
                redirectIfNotValidStatus(selectedForm);
            }
        }
    }, [selectedForm, oidc, navigate, fetchFormData, submissionId, redirectIfNotValidStatus]);

    const renderForm = (formType, selectedSubmission) => {
        switch (formType) {
            case "ansvarsrett":
                return (
                    <Ansvarsrett
                        selectedSubmission={selectedSubmission}
                        validationHandler={(validations) => handleValidate(validations)}
                    />
                );
            case "kontrollerklaering":
                return (
                    <KontrollErklaering
                        selectedSubmission={selectedSubmission}
                        validationHandler={(validations) => handleValidate(validations)}
                        fetchFormData={(submissionId) => fetchFormData(submissionId)}
                        setRequestError={(requestError) => setRequestError(requestError)}
                    />
                );
            case "samsvarserklaering":
                return (
                    <SamsvarsErklaering
                        selectedSubmission={selectedSubmission}
                        validationHandler={(validations) => handleValidate(validations)}
                    />
                );
            default:
                return "";
        }
    };

    const renderHtmlContentForPdf = () => {
        sessionStorage.print = "true";
        const htmlString = renderToStaticMarkup(
            <div className="page signed-document">
                <App />
            </div>
        );
        sessionStorage.print = "false";
        const htmlContentString = `<html><head><style>${printStyle.replace(
            /\r?\n|\r/g,
            ""
        )}</style></head><body>${htmlString}</body></html>`;
        return htmlContentString;
    };

    const handleSigningButtonClick = () => {
        if (selectedForm?.signeringsUrl?.length) {
            window.location.href = selectedForm?.signeringsUrl;
        } else {
            dispatch(updateLoadingMessage("Genererer PDF-fil"));

            const accessToken = oidc?.user?.access_token;
            const stage = "signing_preparation";
            const selectedFormSaveRequest = dispatch(saveSelectedForm(selectedForm, accessToken, stage));

            selectedFormSaveRequest.ready.then((response) => {
                dispatch(updateSelectedForm(response.data)).then(() => {
                    const htmlContentForPdf = renderHtmlContentForPdf();
                    dispatch(convertSelectedFormToPDF(htmlContentForPdf, selectedSubmission.referanseId, accessToken))
                        .then(() => {
                            dispatch(updateLoadingMessage("Klargjør signering"));
                            dispatch(initateSigning(selectedSubmission.referanseId, accessToken))
                                .then((response) => {
                                    dispatch(updateLoadingMessage(null));
                                    if (response.status === 200) {
                                        let signingUrl = response.data.signingUrl;
                                        signingUrl += `?skjema=${selectedSubmission.referanseId}`;
                                        window.location.href = signingUrl;
                                    } else {
                                        handleApiResponseStatus(
                                            response.status,
                                            selectedForm,
                                            response.xCorrelationId,
                                            handleSigningButtonClick
                                        );
                                    }
                                })
                                .catch((error) => {
                                    dispatch(updateLoadingMessage(null));
                                });
                        })
                        .catch((error) => {
                            dispatch(updateLoadingMessage(null));
                        });
                });
            });
        }
    };

    const handleClickOutsideRejectDialog = () => {
        setRejectionMessage(null);
        navigate(`/skjema/${submissionId}/rediger`);
    };

    const handleSubmitRejectionButtonClick = () => {
        const accessToken = oidc?.user?.access_token;
        dispatch(rejectSelectedForm(selectedForm, rejectionMessage, accessToken)).then((response) => {
            if (response.status === 200) {
                navigate(`/skjema/${selectedForm.referanseId}/signatur-avvist`);
            } else {
                handleApiResponseStatus(
                    response.status,
                    selectedForm,
                    response.xCorrelationId,
                    handleSubmitRejectionButtonClick
                );
            }
        });
    };

    const handleClickOutsideHarAktivSigneringsjobbDialog = () => {
        const scrollPositionBeforeReload = document.getElementById("main-content").scrollTop;
        setIsLoaded(false);
        fetchFormData(submissionId).then(() => {
            setIsLoaded(true);
            setShowHarAktivSigneringsjobbDialog(false);
            setTimeout(() => {
                document.getElementById("main-content").scrollTop = scrollPositionBeforeReload;
            }, 100);
        });
    };

    const handleSubmitHarAktivSigneringsjobbButtonClick = () => {
        const accessToken = oidc?.user?.access_token;
        setShowHarAktivSigneringsjobbDialog(false);
        dispatch(removeSigningProcess(selectedForm, accessToken)).then(() => {
            saveForm(selectedForm, accessToken);
        });
    };

    const handleClickOutsideRequestErrorDialog = () => {
        setRequestError(null);
    };

    const runValidations = (formType) => {
        switch (formType) {
            case "ansvarsrett":
                dispatch(validateAnsvarligForetakKontaktpersonEpost());
                dispatch(validateAnsvarligForetakKontaktpersonNavn());
                dispatch(validateAnsvarligForetakKontaktpersonTelefonnummer());
                dispatch(validateErklaeringCheckboxes());
                dispatch(validateBeskrivelseForAllAnsvarsomraader());
                dispatch(validateTiltaksklasseForAllAnsvarsomraader());
                dispatch(validateDekkesOmradetAvSentralGodkjenningForAllAnsvarsomraader());
                dispatch(validateSamsvarKontrollCheckboxesForAllAnsvarsomraader());
                break;
            case "samsvarserklaering":
                dispatch(validateAnsvarligForetakKontaktpersonEpost());
                dispatch(validateAnsvarligForetakKontaktpersonNavn());
                dispatch(validateAnsvarligForetakKontaktpersonTelefonnummer());
                dispatch(validateAnsvarsrettProsjekterendeCheckboxes());
                dispatch(validateAnsvarsomraadetAvsluttet());
                dispatch(validateAnsvarsrettUtfoerendeTillatelse());
                dispatch(validateMidlertidigBrukstillatelseGjennstaaendeInnenfor());
                dispatch(validateHarTilstrekkeligSikkerhet());
                dispatch(validateTypeArbeider());
                dispatch(validateUtfoertInnen());
                dispatch(validateErklaeringSamsvarCheckboxes());
                break;
            case "kontrollerklaering":
                dispatch(validateAnsvarligForetakKontaktpersonEpost());
                dispatch(validateAnsvarligForetakKontaktpersonNavn());
                dispatch(validateAnsvarligForetakKontaktpersonTelefonnummer());
                dispatch(validateAnsvarsomraadetAvsluttet());
                dispatch(validateAnsvarsrettKontrollerendeRadioButtons());
                dispatch(validateAttachment());
                dispatch(validateErklaeringKontrollCheckbox());
                break;
            default:
                return;
        }
    };

    return selectedSubmission && isLoaded && oidc?.user ? (
        <Container>
            <Paper>
                {renderForm(selectedSubmission?.innsendingstype, selectedSubmission)}
                <section>
                    <div className={style.signingInfoContainer}>
                        {validationMessages && Object.keys(validationMessages)?.length ? (
                            <div className={commonStyle.marginBottomSmall} aria-live="polite" role="alert">
                                <ErrorBox>
                                    <div>
                                        <h2 className={commonStyle.boxTitle}>
                                            Du kan ikke signere erklæringen før alle opplysningene er fylt ut:
                                        </h2>
                                        <ul className={commonStyle.boxList}>
                                            {dispatch(getValidationMessageSummary()).map((validationMessage, index) => {
                                                return <li key={index}>{validationMessage}</li>;
                                            })}
                                        </ul>
                                    </div>
                                </ErrorBox>
                            </div>
                        ) : null}
                        {isValidated && !Object.keys(validationMessages)?.length ? (
                            <InfoBox>
                                Når du trykker Til signering, blir du sendt til Postens tjeneste for signering. Der kan
                                du se gjennom og signere ved å logge inn på nytt. Den signerte erklæringen sendes
                                automatisk til ansvarlig søker med kopi til e-postadressen du har oppgitt.
                            </InfoBox>
                        ) : null}
                    </div>

                    {isValidated ? (
                        <Button
                            content="Til signering"
                            color="primary"
                            disabled={
                                (validationMessages && Object.keys(validationMessages)?.length ? true : false) ||
                                isEdited ||
                                isSavingData
                            }
                            onClick={() => {
                                handleSigningButtonClick();
                                dispatch(renewLastInteractionAt());
                            }}
                        />
                    ) : (
                        <Button
                            content="Kontroller"
                            color="primary"
                            disabled={isEdited || isSavingData}
                            onClick={() => {
                                runValidations(selectedSubmission?.innsendingstype);
                                dispatch(updateIsValidated(true));
                                dispatch(renewLastInteractionAt());
                            }}
                        />
                    )}

                    {loadingMessage?.length ? <LoadingAnimation fixed message={loadingMessage} /> : ""}
                    <div className={commonStyle.marginTop}>
                        <Header size={5} htmlTag="h2" content="Har du oppdaget noe feil i erklæringen?" />
                        <Link
                            to={`/skjema/${submissionId}/avvis`}
                            title="Avvis erklæring"
                            onClick={() => {
                                dispatch(renewLastInteractionAt());
                            }}
                        >
                            Meld fra til ansvarlig søker
                        </Link>
                    </div>
                </section>
            </Paper>
            {showRejectDialog ? (
                <Dialog
                    onClickOutside={() => {
                        handleClickOutsideRejectDialog();
                        dispatch(renewLastInteractionAt());
                    }}
                    closeButton
                    maxWidth="960px"
                >
                    <Header content="Meld inn feil til ansvarlig søker" size={2} />
                    <p>
                        Skriv en begrunnelse i tekstboksen under. Ansvarlig søker må rette opp feilen og sende en ny
                        lenke for signering.
                    </p>
                    <Textarea
                        id="rejectionMessage"
                        defaultValue={rejectionMessage}
                        onChange={(event) => {
                            dispatch(renewLastInteractionAt());
                            const submitRejectionButton = document.getElementById("submitRejectionButton");
                            const disabledSubmitRejectionButton = !event?.target?.value?.trim()?.length > 0;
                            submitRejectionButton.disabled = disabledSubmitRejectionButton;
                        }}
                        onBlur={(event) => {
                            dispatch(renewLastInteractionAt());
                            setRejectionMessage(event.target.value);
                        }}
                        resize="vertical"
                    />
                    <div className={`${commonStyle.marginTop} ${commonStyle.marginBottomSmall}`}>
                        <Button
                            id="submitRejectionButton"
                            content="Avvis erklæring"
                            onClick={() => {
                                dispatch(renewLastInteractionAt());
                                handleSubmitRejectionButtonClick();
                            }}
                            color="primary"
                            disabled={!rejectionMessage?.trim()?.length}
                        />
                    </div>
                    <p>
                        <Link
                            to={`/skjema/${submissionId}/rediger`}
                            title="Avvis erklæring"
                            onClick={() => {
                                dispatch(renewLastInteractionAt());
                            }}
                        >
                            Avbryt og gå tilbake til erklæringen.
                        </Link>
                    </p>
                </Dialog>
            ) : null}
            {showHarAktivSigneringsjobbDialog ? (
                <Dialog
                    onClickOutside={() => {
                        handleClickOutsideHarAktivSigneringsjobbDialog();
                        dispatch(renewLastInteractionAt());
                    }}
                    closeButton
                    maxWidth="960px"
                >
                    <Header content="Erklæringen har allerede blitt sendt til signering" size={2} />
                    <p>
                        Denne erklæringen har tidligere blitt sendt til signering, men signeringen ble ikke fullført.
                        Vil du avbryte den andre signeringsjobben og fortsette redigering?
                    </p>
                    <div className={`${commonStyle.marginTop} ${commonStyle.marginBottomSmall}`}>
                        <Button
                            content="Ja, fortsett redigering"
                            onClick={handleSubmitHarAktivSigneringsjobbButtonClick}
                            color="primary"
                        />
                    </div>
                    <p>
                        <button
                            className={commonStyle.link}
                            onClick={() => {
                                handleClickOutsideHarAktivSigneringsjobbDialog();
                                dispatch(renewLastInteractionAt());
                            }}
                        >
                            Avbryt og tilbakestill endringer
                        </button>
                    </p>
                </Dialog>
            ) : null}
            {requestError ? (
                <RequestErrorDialog
                    requestError={requestError}
                    clickOutsideHandler={() => {
                        handleClickOutsideRequestErrorDialog();
                        dispatch(renewLastInteractionAt());
                    }}
                />
            ) : null}
        </Container>
    ) : !oidc?.user || !Object.keys(oidc.user).length ? (
        "Du er utlogget"
    ) : null;
};

export default Form;
