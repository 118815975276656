// Dependencies
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { CheckBoxList, CheckBoxListItem, ErrorMessage } from "dibk-design";

// Actions
import { validateErklaeringSamsvarCheckboxes } from "actions/ValidationActions";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const Erklaering = ({ formData, updateHandler, validationHandler }) => {
    const dispatch = useDispatch();

    // Redux store
    const validationMessages = useSelector((state) => state.validationMessages);

    const handleOnChange = (value, property, validations) => {
        updateHandler({
            ...formData,
            [property]: value
        }).then(() => {
            validationHandler(validations);
        });
    };

    const hasAnsvarsomraadeFunksjon = (funksjonKode) => {
        return formData.funksjonKode === funksjonKode;
    };

    const hasUncheckedAnsvarsomraadeFunksjon = () => {
        return [
            hasAnsvarsomraadeFunksjon("PRO") && !formData.erklaeringProsjektering,
            hasAnsvarsomraadeFunksjon("UTF") && !formData.erklaeringUtfoerelse
        ].some((condition) => condition);
    };

    return formData ? (
        <Fragment>
            <CheckBoxList compact>
                <legend className={`${commonStyle.marginBottomSmall} ${commonStyle.normalText}`}>
                    Vi er kjent med reglene om straff og sanksjoner i plan- og bygningsloven kapittel 32, og at det kan
                    medføre reaksjoner dersom vi oppgir uriktige opplysninger. Vi bekrefter at kvalitetssikring er
                    utført og dokumentert i henhold til erklæring om ansvarsrett og foretakets kvalitetssikring.
                </legend>
                {hasAnsvarsomraadeFunksjon("PRO") ? (
                    <CheckBoxListItem
                        id="erklaeringProsjektering"
                        onChange={(event) => {
                            handleOnChange(event.target.checked, "erklaeringProsjektering", () => {
                                dispatch(validateErklaeringSamsvarCheckboxes());
                            });
                        }}
                        checked={formData.erklaeringProsjektering ? true : false}
                        checkmarkCharacter="✕"
                        required
                        hasErrors={
                            !!validationMessages?.erklaeringCheckboxes?.message?.length &&
                            !formData.erklaeringProsjektering
                        }
                        aria-describedby={
                            !!validationMessages?.erklaeringCheckboxes?.message?.length
                                ? "erklaeringCheckboxes-errorMessage"
                                : null
                        }
                    >
                        Ansvarlig prosjekterende bekrefter at prosjekteringen er i samsvar med ytelser i TEK og
                        preaksepterte ytelser (VTEK), eller ved analyse som viser at forskriftens (TEK) funksjonskrav er
                        oppfylt. Eventuelle behov for dispensasjon/unntak er rapportert.
                    </CheckBoxListItem>
                ) : (
                    <Fragment />
                )}
                {hasAnsvarsomraadeFunksjon("UTF") ? (
                    <CheckBoxListItem
                        id="erklaeringUtfoerelse"
                        onChange={(event) => {
                            handleOnChange(event.target.checked, "erklaeringUtfoerelse", () => {
                                dispatch(validateErklaeringSamsvarCheckboxes());
                            });
                        }}
                        checked={formData.erklaeringUtfoerelse ? true : false}
                        checkmarkCharacter="✕"
                        required
                        hasErrors={
                            !!validationMessages?.erklaeringCheckboxes?.message?.length &&
                            !formData.erklaeringUtfoerelse
                        }
                        aria-describedby={
                            !!validationMessages?.erklaeringCheckboxes?.message?.length
                                ? "erklaeringCheckboxes-errorMessage"
                                : null
                        }
                    >
                        Ansvarlig utførende bekrefter at utførelsen er i samsvar med produksjonsunderlaget.
                    </CheckBoxListItem>
                ) : (
                    <Fragment />
                )}
            </CheckBoxList>
            {!!validationMessages?.erklaeringCheckboxes?.message?.length && hasUncheckedAnsvarsomraadeFunksjon() ? (
                <ErrorMessage
                    id="erklaeringCheckboxes-errorMessage"
                    content={validationMessages.erklaeringCheckboxes.message}
                />
            ) : null}
        </Fragment>
    ) : null;
};

Erklaering.propTypes = {
    formData: PropTypes.object.isRequired,
    updateHandler: PropTypes.func.isRequired,
    validationHandler: PropTypes.func.isRequired
};

export default Erklaering;
