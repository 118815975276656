// Actions
import { showSnackbarMessage } from 'actions/SnackbarActions';

// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

export const initateSigning = (submissionId, accessToken) => () => {
    const internalApiUrl = getEnvironmentVariable('internalApiUrl');
    const formPath = `/api/v1/signering/${submissionId}/initiate`;
    const bearerToken = `Bearer ${accessToken}`;
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerToken
        }
    };
    return fetch(`${internalApiUrl}${formPath}`, fetchOptions).then(res => {
        if (res.ok) {
            return res.json().then(response => {
                return {
                    status: res.status,
                    data: response
                }
            })
        }else {
            return {
                status: res.status,
                xCorrelationId: res.headers?.get?.('x-correlation-id')
            }
        }
    });
}


const getFilenameFromContentDisposition = contentDisposition => {
    const regex = new RegExp(`filename=(.*?);`, 'gm')
    return regex.exec(contentDisposition)?.[1] || null;
}

export const getSignedDocument = (submissionId, accessToken) => dispatch => {
    const internalApiUrl = getEnvironmentVariable('internalApiUrl');
    const formPath = `/api/v1/signering/${submissionId}/signed-document`;
    const bearerToken = `Bearer ${accessToken}`;
    const fetchOptions = {
        headers: {
            'Authorization': bearerToken
        }
    };
    return fetch(`${internalApiUrl}${formPath}`, fetchOptions).then(response => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = getFilenameFromContentDisposition(contentDisposition) || 'skjema.pdf';
        return response.blob().then(blob => {
            return {
                filename,
                blob
            }
        });
    });
}


export const removeSigningProcess = (form, accessToken) => dispatch => {
    dispatch(showSnackbarMessage('Fjerner signeringsjobb', 3000))
    const internalApiUrl = getEnvironmentVariable('internalApiUrl');
    const formPath = form._links?.signering?.href;
    const bearerToken = `Bearer ${accessToken}`;
    const fetchOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': bearerToken
        }
    };
    return fetch(`${internalApiUrl}${formPath}`, fetchOptions).then(res => res.json()).then(() => {
        dispatch(showSnackbarMessage('Signeringsjobb er fjernet', 3000));
    });
}



