// Dependencies
import React from "react";
import { useSelector } from "react-redux";

const ErklaeringKontroll = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const formData = selectedForm?.formData;
    return formData ? (
        <React.Fragment>
            <p>
                Vi er kjent med reglene om straff og sanksjoner i plan- og bygningslovens kapittel 32, og at det kan
                medføre reaksjoner dersom vi oppgir uriktige opplysninger.
            </p>
            <div className="checkbox-container">
                <span className="checkbox">{formData.erklaeringKontroll === true ? "X" : ""}</span>
                <span>
                    Ansvarlig kontrollerende bekrefter at kontrollen er gjennomført på en forskriftsmessig måte, og at
                    kontrollforetaket er uavhengig av foretakene som er kontrollert.
                </span>
            </div>
        </React.Fragment>
    ) : null;
};

export default ErklaeringKontroll;
