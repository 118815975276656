export const scrollToTop = () => {
    document.getElementById("main-content").scrollTop = 0;
};

export const setFocusToElement = (element) => {
    const autoFocusElement = document.createElement("button");
    element.prepend(autoFocusElement);
    autoFocusElement.focus();
    autoFocusElement.remove();
};

export const getFocusableElementsInsideElement = (element) => {
    return element.querySelectorAll('button, [href], input, [tabindex="0"]');
};

export const addFocusTrapInsideElement = (element) => {
    setFocusToElement(element);
    const focusableElements = getFocusableElementsInsideElement(element);
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];
    firstFocusableElement.onkeydown = (event) => {
        if (event.keyCode === 9 && event.shiftKey) {
            lastFocusableElement.focus();
        }
    };
    lastFocusableElement.onclick = () => {
        firstFocusableElement.focus();
    };
    lastFocusableElement.onkeydown = (event) => {
        if (event.keyCode === 9 && !event.shiftKey) {
            event.preventDefault();
            firstFocusableElement.focus();
        }
    };
};

export const classNameArrayToClassNameString = (classNameArray) => {
    return classNameArray?.filter((className) => className)?.join(" ") || "";
};
