// Dependencies
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { Header } from "dibk-design";

// Components
import MainHeading from "components/partials/MainHeading";
import EiendomByggestedList from "components/partials/Forms/FormParts/EiendomByggestedList";
import AnsvarligForetak from "components/partials/Forms/FormParts/AnsvarligForetak";
import Ansvarsomraade from "components/partials/Forms/KontrollErklaering/Ansvarsomraade";
import Sluttrapport from "components/partials/Forms/KontrollErklaering/Sluttrapport";
import Erklaering from "components/partials/Forms/KontrollErklaering/Erklaering";

// Partials
import FormIntroText from "components/partials/FormIntroText";

// Actions
import { updateSelectedForm } from "actions/FormActions";
import { updateIsEdited } from "actions/IsEditedActions";

// Helpers
import { classNameArrayToClassNameString } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const KontrollErklaering = ({ validationHandler, fetchFormData, setRequestError }) => {
    const dispatch = useDispatch();

    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const updateFormData = (formData) => {
        return dispatch(
            updateSelectedForm({
                ...selectedForm,
                formData
            })
        ).then(() => {
            dispatch(updateIsEdited(true));
        });
    };

    const updateFormDataField = (value, property) => {
        return updateFormData({
            ...selectedForm.formData,
            [property]: value
        });
    };

    const formData = selectedForm?.formData;
    return formData ? (
        <React.Fragment>
            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <MainHeading form={selectedForm} pageName="Skjemautfylling" />
                <FormIntroText form={selectedForm} />
            </section>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Eiendom/Byggested" size={2} />
                <div className={commonStyle.marginBottomSmall}>
                    Trykk på eiendommen for å se detaljer om byggestedet.
                </div>
                <EiendomByggestedList eiendomByggesteder={formData.eiendomByggesteder} />
            </section>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Ansvarlig foretak" size={2}></Header>
                <AnsvarligForetak
                    foretak={formData.ansvarligForetak}
                    innsendingstype={selectedForm.innsendingstype}
                    updateHandler={(foretak) => updateFormDataField(foretak, "ansvarligForetak")}
                    validationHandler={(validations) => validationHandler(validations)}
                />
            </section>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Ansvarsområde" size={2}></Header>
                <Ansvarsomraade
                    updateHandler={(formData) => updateFormData(formData)}
                    validationHandler={(validations) => validationHandler(validations)}
                />
            </section>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Sluttrapport" size={2}></Header>
                <Sluttrapport
                    updateHandler={(formData) => updateFormData(formData)}
                    validationHandler={(validations) => validationHandler(validations)}
                    fetchFormData={(submissionId) => fetchFormData(submissionId)}
                    setRequestError={(requestError) => setRequestError(requestError)}
                />
            </section>

            <section className={classNameArrayToClassNameString([commonStyle.paddingTop, commonStyle.paddingBottom])}>
                <Header content="Erklæring" size={2}></Header>
                <Erklaering
                    formData={formData}
                    updateHandler={(formData) => updateFormData(formData)}
                    validationHandler={(validations) => validationHandler(validations)}
                />
            </section>
        </React.Fragment>
    ) : (
        <p>Ingen data for samsvarserklæring</p>
    );
};

KontrollErklaering.propTypes = {
    validationHandler: PropTypes.func.isRequired,
    fetchFormData: PropTypes.func.isRequired
};

export default KontrollErklaering;
