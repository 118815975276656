// Types
import { FETCH_SERVICE_STATE } from 'constants/types';

// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';

export const fetchServiceState = (guid) => dispatch => {
    const internalApiUrl = getEnvironmentVariable('internalApiUrl');
    const apiUrl = `${internalApiUrl}/api/v1/adminstatus/servicestate`
    return fetch(apiUrl).then(res => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error('Network response was not OK');
        }
    }).then(serviceState => {
        return dispatch({ type: FETCH_SERVICE_STATE, payload: serviceState })
    }).catch(() => {
        const serviceState = {
            state: "unavailable",
            messageHeader: "Tjenesten er ikke tilgjengelig",
            messageBody: "Tjenesten er midlertidig utilgjengelig grunner planlagt vedlikehold.\nVennligst prøv igjen om noen minutter."
        }
        return dispatch({ type: FETCH_SERVICE_STATE, payload: serviceState })
    });
}
