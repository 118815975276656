// Dependencies
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";

// Actions
import { fetchSubmission } from "actions/SubmissionActions";
import { fetchSelectedForm } from "actions/FormActions";
import { updateLoadingMessage } from "actions/LoadingMessageActions";

// DIBK Design
import { Container, Header, Paper } from "dibk-design";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";
import MainHeading from "components/partials/MainHeading";
import { formatContactInfoText, renderProjectDetails } from "helpers/formatHelpers";

const SignedOut = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const submissionId = params?.submissionId || null;

    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    // State
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (submissionId) {
            dispatch(updateLoadingMessage("Henter innsending"));
            dispatch(fetchSubmission(submissionId))
                .then((submission) => {
                    dispatch(updateLoadingMessage("Henter skjema"));
                    if (submission && Object.keys(submission).length) {
                        return dispatch(fetchSelectedForm(submission))
                            .then((response) => {
                                if (response) {
                                    setErrorMessage(null);
                                } else {
                                    setErrorMessage(`Kunne ikke hente skjema med referanse: ${submissionId}`);
                                }
                                return response;
                            })
                            .catch((error) => {
                                console.log("fetchSelectedForm", error);
                            })
                            .finally(() => {
                                dispatch(updateLoadingMessage(null));
                            });
                    }
                })
                .catch((error) => {
                    console.log("fetchSubmission", error);
                })
                .finally(() => {
                    dispatch(updateLoadingMessage(null));
                });
        }
    }, [dispatch, submissionId]);

    const renderErrorMessage = (errorMessage) => {
        return (
            <Fragment>
                <Header size={2} content="Feil" />
                <p>{errorMessage}</p>
            </Fragment>
        );
    };

    return (
        <Container>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <MainHeading form={selectedForm} pageName="Utlogget" />
            {errorMessage ? renderErrorMessage(errorMessage) : ""}
            <Paper>
                <Header content="Du har logget ut" size={2}></Header>
                <p>Endringene er lagret.</p>
                <div className={commonStyle.paragraphGroup}>
                    <Header content="Hvordan kommer jeg tilbake til erklæringen?" size={3} />
                    <p>Du kan bruke den samme lenken som første gang du åpnet erklæringen.</p>
                </div>
                {selectedForm && Object.keys(selectedForm).length ? (
                    <Fragment>
                        <div className={commonStyle.paragraphGroup}>{renderProjectDetails(selectedForm)}</div>
                        <Header content="Har du spørsmål?" size={3} />
                        <div>{formatContactInfoText(selectedForm)}</div>
                    </Fragment>
                ) : null}
            </Paper>
        </Container>
    );
};

export default SignedOut;
