// Dependencies
import { createUserManager } from 'redux-oidc';

// Helpers
import { getEnvironmentVariable } from 'helpers/environmentVariableHelpers.js';
import { getServerDate } from 'helpers/serverHelpers';

const configIsLoaded = () => {
  return new Promise((resolve, reject) => {
    if (getEnvironmentVariable('oidc_client_id', null, true)) {
      const userManagerConfig = {
        client_id: getEnvironmentVariable('oidc_client_id', null, true),
        authority: getEnvironmentVariable('oidc_authority'),
        redirect_uri: getEnvironmentVariable('oidc_redirect_uri'),
        post_logout_redirect_uri: getEnvironmentVariable('oidc_post_logout_redirect_uri'),
        silent_redirect_uri: getEnvironmentVariable('oidc_silent_redirect_uri'),
        response_type: "code",
        scope: "openid profile",
        acr_values: "idporten-loa-high",
        ui_locales: "nb",
        resource: getEnvironmentVariable('oidc_resource'),
        loadUserInfo: false,
        revokeAccessTokenOnSignout: true,
        clockService: {
          getEpochTime: getServerDate
        }
      }
      resolve(userManagerConfig);
    } else {
      window.setTimeout(() => {
        resolve(configIsLoaded());
      }, 100)
    }
  });
}
const getUserManagerConfigWhenReady = configIsLoaded().then((userManagerConfig) => {
  return createUserManager(userManagerConfig);
})

export default getUserManagerConfigWhenReady;
