// Dependencies
import React from "react";
import { useSelector } from "react-redux";

const ErklaeringAnsvarsrett = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const hasAnsvarsomraadeFunksjon = (funksjonKode) => {
        const ansvarsomraader = selectedForm?.formData?.ansvarsomraader;
        return ansvarsomraader?.length
            ? ansvarsomraader.some((ansvarsomraade) => {
                  return ansvarsomraade.funksjonKode === funksjonKode;
              })
            : false;
    };

    const formData = selectedForm?.formData;
    return formData ? (
        <React.Fragment>
            <p>
                Vi er kjent med reglene om straff og sanksjoner i plan- og bygningslovens kapittel 32, og at det kan
                medføre reaksjoner dersom vi oppgir uriktige opplysninger. Vi forplikter oss til å stille med riktig
                kompetanse i byggeprosjektet, jf. SAK10 kapittel 10 og 11.
            </p>
            {hasAnsvarsomraadeFunksjon("SØK") ? (
                <p>
                    Ansvarlig søker bekrefter at hele tiltaket belegges med ansvar, og dekker kravene i plan- og
                    bygningsloven.
                </p>
            ) : null}
            {hasAnsvarsomraadeFunksjon("PRO") ? (
                <div className="checkbox-container">
                    <span className="checkbox">{formData.erklaeringAnsvarligProsjekterende === true ? "X" : ""}</span>
                    <span>
                        Ansvarlig prosjekterende erklærer at prosjekteringen skal være planlagt, gjennomført og
                        kvalitetssikret i henhold til plan- og bygningsloven, jf. SAK10 § 12-3.
                    </span>
                </div>
            ) : null}
            {hasAnsvarsomraadeFunksjon("UTF") ? (
                <div className="checkbox-container">
                    <span className="checkbox">{formData.erklaeringAnsvarligUtfoerende === true ? "X" : ""}</span>
                    <span>
                        Ansvarlig utførende erklærer at arbeidet ikke skal starte før det foreligger kvalitetssikret
                        produksjonsunderlag for respektive del av utførelsen, jf. SAK10, § 12-4.
                    </span>
                </div>
            ) : null}
            {hasAnsvarsomraadeFunksjon("KONTROLL") ? (
                <div className="checkbox-container">
                    <span className="checkbox">{formData.erklaeringAnsvarligKontrollerende === true ? "X" : ""}</span>
                    <span>
                        Ansvarlig kontrollerende erklærer uavhengighet, jf. SAK10 § 14-1, og vil redegjøre for endringer
                        som kan påvirke uavhengigheten, jf. SAK10 § 12-5.
                    </span>
                </div>
            ) : null}
        </React.Fragment>
    ) : null;
};

export default ErklaeringAnsvarsrett;
