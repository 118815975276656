// Dependencies
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const GjenstaaendeArbeider = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const formData = selectedForm?.formData;
    return (
        <Fragment>
            <table>
                <thead>
                    <tr>
                        <th>
                            Innenfor den delen av tiltaket det søkes midlertidig brukstillatelse for, gjenstår disse
                            arbeidene av mindre vesentlig betydning
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="whitespace-pre-wrap">
                            {formData?.ansvarsrettUtfoerende?.midlertidigBrukstillatelseGjennstaaendeInnenfor?.length
                                ? formData?.ansvarsrettUtfoerende?.midlertidigBrukstillatelseGjennstaaendeInnenfor
                                : "-"}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th>Disse delene av tiltaket søkes det ikke om midlertidig brukstillatelse for nå</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="whitespace-pre-wrap">
                            {formData?.ansvarsrettUtfoerende?.midlertidigBrukstillatelseGjennstaaendeUtenfor?.length
                                ? formData?.ansvarsrettUtfoerende?.midlertidigBrukstillatelseGjennstaaendeUtenfor
                                : "-"}
                        </td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};

export default GjenstaaendeArbeider;
