// Dependencies
import React, { useCallback, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import {
    Accordion,
    Button,
    CheckBoxList,
    CheckBoxListItem,
    ErrorMessage,
    InputField,
    Label,
    Textarea
} from "dibk-design";

// Actions
import {
    updateIsValidated,
    validateAnsvarsrettProsjekterendeCheckboxes,
    validateAnsvarsomraadetAvsluttet,
    validateAnsvarsrettUtfoerendeTillatelse,
    validateMidlertidigBrukstillatelseGjennstaaendeInnenfor,
    removeValidationForMidlertidigBrukstillatelseGjennstaaendeInnenfor,
    validateHarTilstrekkeligSikkerhet,
    removeValidationForHarTilstrekkeligSikkerhet,
    validateTypeArbeider,
    removeValidationForTypeArbeider,
    validateUtfoertInnen,
    removeValidationForUtfoertInnen
} from "actions/ValidationActions";
import { renewLastInteractionAt } from "actions/LastInteractionAtActions";

// Helpers
import { convertFromInputValueToTimestamp, convertFromTimestampToInputValue, formatDate } from "helpers/formatHelpers";

// Stylesheets
import formsStyle from "components/partials/Forms/Forms.module.scss";
import commonStyle from "components/routes/common.module.scss";

const Ansvarsomraade = ({ updateHandler, validationHandler }) => {
    const dispatch = useDispatch();

    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);
    const validationMessages = useSelector((state) => state.validationMessages);
    const isValidated = useSelector((state) => state.isValidated);

    const handleUpdate = useCallback(
        (value, property, subProperty) => {
            let updatedFormData = selectedForm.formData;
            if (subProperty) {
                if (!updatedFormData[property]) {
                    updatedFormData[property] = {};
                }
                updatedFormData[property][subProperty] = value;
            } else {
                updatedFormData[property] = value;
            }
            return updateHandler(updatedFormData);
        },
        [selectedForm, updateHandler]
    );

    const handleValidate = useCallback(
        (validations) => {
            validationHandler(validations);
        },
        [validationHandler]
    );

    const handleUpdateAndSaveIfChanged = useCallback(
        (newValue, property, subProperty, validations) => {
            const oldValue = subProperty
                ? selectedForm.formData[property]?.[subProperty]
                : selectedForm.formData[property];
            if (newValue !== oldValue) {
                handleUpdate(newValue, property, subProperty);
                handleValidate(validations);
            }
        },
        [selectedForm, handleUpdate, handleValidate]
    );

    useEffect(() => {
        if (selectedForm && Object.keys(selectedForm).length) {
            const ansvarsrettUtfoerende = selectedForm?.formData?.ansvarsrettUtfoerende;
            if (
                ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet === false &&
                ansvarsrettUtfoerende?.okForMidlertidigBrukstillatelse === true
            ) {
                handleUpdateAndSaveIfChanged(false, "ansvarsomraadetAvsluttet");
            }
        }
    }, [selectedForm, handleUpdateAndSaveIfChanged]);

    const formData = selectedForm?.formData;
    const twoWeeksLater = () => {
        const date = new Date(Date.now());
        date.setDate(date.getDate() + 14);
        return date;
    };

    const hasCheckedValueInAnsvarsrettProsjekterende = () => {
        return [
            formData.ansvarsrettProsjekterende?.okForRammetillatelse,
            formData.ansvarsrettProsjekterende?.okForIgangsetting,
            formData.ansvarsrettProsjekterende?.okForMidlertidigBrukstillatelse,
            formData.ansvarsrettProsjekterende?.okForFerdigattest
        ].some((value) => value);
    };

    return formData ? (
        <React.Fragment>
            <div className={commonStyle.marginBottomSmall}>
                Noen opplysninger er lagt inn fra erklæring om ansvarsrett, men du må fylle ut de resterende feltene.
            </div>

            {formData.funksjonKode === "PRO" ? (
                <div className={commonStyle.marginBottomSmall}>
                    <Accordion
                        title="Ansvarlig prosjekterende"
                        expanded
                        onToggleExpand={() => {
                            dispatch(renewLastInteractionAt());
                        }}
                    >
                        <div className={formsStyle.fieldSection}>
                            <dl className={formsStyle.fieldList}>
                                <dt>
                                    <Label normalCursor htmlTag="span">
                                        Beskrivelse av ansvarsområdet
                                    </Label>
                                </dt>
                                <dd>{formData.beskrivelseAvAnsvarsomraadet}</dd>
                                {!!formData.ansvarsrettErklaert && (
                                    <React.Fragment>
                                        <dt>
                                            <Label normalCursor htmlTag="span">
                                                Vi erklærte ansvarsrett den
                                            </Label>
                                        </dt>
                                        <dd>{formatDate(formData.ansvarsrettErklaert)}</dd>
                                    </React.Fragment>
                                )}
                            </dl>
                        </div>
                        <div className={formsStyle.fieldSection}>
                            <CheckBoxList
                                required={!hasCheckedValueInAnsvarsrettProsjekterende()}
                                compact
                                legend="Vi kan ikke se at det gjenstår arbeider innenfor ansvarsområdet som er til hinder for å gi"
                            >
                                <CheckBoxListItem
                                    id={`gjenstaaendeArbeiderTilHinder-rammetillatelse`}
                                    name="gjenstaaendeArbeiderTilHinder"
                                    onChange={(event) => {
                                        handleUpdateAndSaveIfChanged(
                                            event.target.checked,
                                            "ansvarsrettProsjekterende",
                                            "okForRammetillatelse",
                                            () => {
                                                dispatch(validateAnsvarsrettProsjekterendeCheckboxes());
                                            }
                                        );
                                    }}
                                    checked={formData.ansvarsrettProsjekterende?.okForRammetillatelse}
                                    checkmarkCharacter="✕"
                                    hasErrors={
                                        !!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length
                                    }
                                    aria-describedby={
                                        !!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length
                                            ? "ansvarsrettProsjekterendeCheckboxes-errorMessage"
                                            : null
                                    }
                                    compact
                                >
                                    Rammesøknad
                                </CheckBoxListItem>
                                <CheckBoxListItem
                                    id={`gjenstaaendeArbeiderTilHinder-igangsettingstillatelseEttTrinnsTillatelse`}
                                    name="gjenstaaendeArbeiderTilHinder"
                                    onChange={(event) => {
                                        handleUpdateAndSaveIfChanged(
                                            event.target.checked,
                                            "ansvarsrettProsjekterende",
                                            "okForIgangsetting",
                                            () => {
                                                dispatch(validateAnsvarsrettProsjekterendeCheckboxes());
                                            }
                                        );
                                    }}
                                    checked={formData.ansvarsrettProsjekterende?.okForIgangsetting}
                                    checkmarkCharacter="✕"
                                    hasErrors={
                                        !!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length
                                    }
                                    aria-describedby={
                                        !!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length
                                            ? "ansvarsrettProsjekterendeCheckboxes-errorMessage"
                                            : null
                                    }
                                    compact
                                >
                                    Ettrinns-/igangsettingssøknad
                                </CheckBoxListItem>
                                <CheckBoxListItem
                                    id={`gjenstaaendeArbeiderTilHinder-midlertidigBrukstillatelse`}
                                    name="gjenstaaendeArbeiderTilHinder"
                                    onChange={(event) => {
                                        handleUpdateAndSaveIfChanged(
                                            event.target.checked,
                                            "ansvarsrettProsjekterende",
                                            "okForMidlertidigBrukstillatelse",
                                            () => {
                                                dispatch(validateAnsvarsrettProsjekterendeCheckboxes());
                                            }
                                        );
                                    }}
                                    checked={formData.ansvarsrettProsjekterende?.okForMidlertidigBrukstillatelse}
                                    checkmarkCharacter="✕"
                                    hasErrors={
                                        !!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length
                                    }
                                    aria-describedby={
                                        !!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length
                                            ? "ansvarsrettProsjekterendeCheckboxes-errorMessage"
                                            : null
                                    }
                                    compact
                                >
                                    Midlertidig brukstillatelse
                                </CheckBoxListItem>
                                <CheckBoxListItem
                                    id={`gjenstaaendeArbeiderTilHinder-ferdigattest`}
                                    name="gjenstaaendeArbeiderTilHinder"
                                    onChange={(event) => {
                                        handleUpdateAndSaveIfChanged(
                                            event.target.checked,
                                            "ansvarsrettProsjekterende",
                                            "okForFerdigattest",
                                            () => {
                                                dispatch(validateAnsvarsrettProsjekterendeCheckboxes());
                                            }
                                        );
                                    }}
                                    checked={formData.ansvarsrettProsjekterende?.okForFerdigattest}
                                    checkmarkCharacter="✕"
                                    hasErrors={
                                        !!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length
                                    }
                                    aria-describedby={
                                        !!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length
                                            ? "ansvarsrettProsjekterendeCheckboxes-errorMessage"
                                            : null
                                    }
                                    compact
                                >
                                    Ferdigattest
                                </CheckBoxListItem>
                            </CheckBoxList>
                            {!!validationMessages?.ansvarsrettProsjekterendeCheckboxes?.message?.length ? (
                                <ErrorMessage
                                    id="ansvarsrettProsjekterendeCheckboxes-errorMessage"
                                    content={validationMessages.ansvarsrettProsjekterendeCheckboxes.message}
                                />
                            ) : null}
                        </div>
                        <div className={formsStyle.fieldSection}>
                            <fieldset>
                                <legend className={commonStyle.required}>
                                    Er alt arbeid innenfor ansvarsområdet avsluttet?
                                </legend>
                                <div className={formsStyle.buttonRow}>
                                    <Button
                                        content="Ja"
                                        size="small"
                                        rounded
                                        onClick={() => {
                                            handleUpdateAndSaveIfChanged(true, "ansvarsomraadetAvsluttet", null, () => {
                                                dispatch(validateAnsvarsomraadetAvsluttet());
                                            });
                                        }}
                                        noHover
                                        required
                                        inputType="radio"
                                        name="ansvarsomraadetAvsluttet"
                                        defaultChecked={formData.ansvarsomraadetAvsluttet === true}
                                        hasErrors={!!validationMessages?.ansvarsomraadetAvsluttet?.message?.length}
                                        aria-describedby={
                                            !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                ? "ansvarsomraadetAvsluttet-errorMessage"
                                                : null
                                        }
                                    />
                                    <Button
                                        content="Nei"
                                        size="small"
                                        rounded
                                        onClick={() => {
                                            handleUpdateAndSaveIfChanged(
                                                false,
                                                "ansvarsomraadetAvsluttet",
                                                null,
                                                () => {
                                                    dispatch(validateAnsvarsomraadetAvsluttet());
                                                }
                                            );
                                        }}
                                        noHover
                                        required
                                        inputType="radio"
                                        name="ansvarsomraadetAvsluttet"
                                        defaultChecked={formData.ansvarsomraadetAvsluttet === false}
                                        hasErrors={!!validationMessages?.ansvarsomraadetAvsluttet?.message?.length}
                                        aria-describedby={
                                            !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                ? "ansvarsomraadetAvsluttet-errorMessage"
                                                : null
                                        }
                                    />
                                </div>
                            </fieldset>
                            {!!validationMessages?.ansvarsomraadetAvsluttet?.message?.length ? (
                                <ErrorMessage
                                    id="ansvarsomraadetAvsluttet-errorMessage"
                                    content={validationMessages.ansvarsomraadetAvsluttet.message}
                                />
                            ) : null}
                        </div>
                    </Accordion>
                </div>
            ) : (
                ""
            )}
            {formData.funksjonKode === "UTF" ? (
                <React.Fragment>
                    <div className={commonStyle.marginBottomSmall}>
                        <Accordion
                            title="Ansvarlig utførende"
                            expanded
                            onToggleExpand={() => {
                                dispatch(renewLastInteractionAt());
                            }}
                        >
                            <dl className={formsStyle.fieldList}>
                                <dt>
                                    <Label normalCursor htmlTag="span">
                                        Beskrivelse av ansvarsområdet
                                    </Label>
                                </dt>
                                <dd>{formData.beskrivelseAvAnsvarsomraadet}</dd>
                                {!!formData.ansvarsrettErklaert && (
                                    <React.Fragment>
                                        <dt>
                                            <Label normalCursor htmlTag="span">
                                                Vi erklærte ansvarsrett den
                                            </Label>
                                        </dt>
                                        <dd>{formatDate(formData.ansvarsrettErklaert)}</dd>
                                    </React.Fragment>
                                )}
                            </dl>
                            <div className={formsStyle.fieldSection}>
                                <fieldset>
                                    <legend className={commonStyle.required}>
                                        Vi kan ikke se at det gjenstår arbeider innenfor ansvarsområdet som er til
                                        hinder for å gi
                                    </legend>
                                    <div className={formsStyle.buttonRow}>
                                        <Button
                                            content="Ferdigattest"
                                            size="small"
                                            rounded
                                            onClick={() => {
                                                handleUpdate(true, "ansvarsrettUtfoerende", "okForFerdigattest");
                                                handleUpdate(
                                                    false,
                                                    "ansvarsrettUtfoerende",
                                                    "okForMidlertidigBrukstillatelse"
                                                );
                                                handleUpdate(null, "ansvarsomraadetAvsluttet");
                                                handleValidate(() => {
                                                    dispatch(
                                                        removeValidationForMidlertidigBrukstillatelseGjennstaaendeInnenfor()
                                                    );
                                                    dispatch(removeValidationForHarTilstrekkeligSikkerhet());
                                                    dispatch(removeValidationForTypeArbeider());
                                                    dispatch(removeValidationForUtfoertInnen());
                                                    dispatch(validateAnsvarsrettUtfoerendeTillatelse());
                                                    if (isValidated) {
                                                        dispatch(updateIsValidated(false));
                                                    }
                                                });
                                            }}
                                            noHover
                                            required
                                            inputType="radio"
                                            name="ansvarsrettUtfoerendeTillatelse"
                                            defaultChecked={formData.ansvarsrettUtfoerende?.okForFerdigattest === true}
                                            hasErrors={
                                                !!validationMessages?.ansvarsrettUtfoerendeTillatelse?.message?.length
                                            }
                                            aria-describedby={
                                                !!validationMessages?.ansvarsrettUtfoerendeTillatelse?.message?.length
                                                    ? "ansvarsrettUtfoerendeTillatelse-errorMessage"
                                                    : null
                                            }
                                        />
                                        <Button
                                            content="Midlertidig brukstillatelse"
                                            size="small"
                                            rounded
                                            onClick={() => {
                                                handleUpdate(
                                                    true,
                                                    "ansvarsrettUtfoerende",
                                                    "okForMidlertidigBrukstillatelse"
                                                );
                                                handleUpdate(false, "ansvarsrettUtfoerende", "okForFerdigattest");
                                                handleUpdate(null, "ansvarsomraadetAvsluttet");
                                                handleValidate(() => {
                                                    dispatch(validateAnsvarsrettUtfoerendeTillatelse());
                                                    if (isValidated) {
                                                        dispatch(updateIsValidated(false));
                                                    }
                                                });
                                            }}
                                            noHover
                                            required
                                            inputType="radio"
                                            name="ansvarsrettUtfoerendeTillatelse"
                                            defaultChecked={
                                                formData.ansvarsrettUtfoerende?.okForMidlertidigBrukstillatelse === true
                                            }
                                            hasErrors={
                                                !!validationMessages?.ansvarsrettUtfoerendeTillatelse?.message?.length
                                            }
                                            aria-describedby={
                                                !!validationMessages?.ansvarsrettUtfoerendeTillatelse?.message?.length
                                                    ? "ansvarsrettUtfoerendeTillatelse-errorMessage"
                                                    : null
                                            }
                                        />
                                    </div>
                                </fieldset>
                                {!!validationMessages?.ansvarsrettUtfoerendeTillatelse?.message?.length ? (
                                    <ErrorMessage
                                        id="ansvarsrettUtfoerendeTillatelse-errorMessage"
                                        content={validationMessages.ansvarsrettUtfoerendeTillatelse.message}
                                    />
                                ) : null}
                            </div>

                            {formData.ansvarsrettUtfoerende?.okForMidlertidigBrukstillatelse === true ? (
                                <React.Fragment>
                                    <div className={formsStyle.fieldSection}>
                                        <Textarea
                                            id="midlertidigBrukstillatelseGjennstaaendeInnenfor"
                                            elementKey="midlertidigBrukstillatelseGjennstaaendeInnenfor"
                                            label="Innenfor den delen av tiltaket det søkes midlertidig brukstillatelse for, gjenstår disse arbeidene av mindre vesentlig betydning"
                                            onBlur={(event) => {
                                                handleUpdateAndSaveIfChanged(
                                                    event.target.value,
                                                    "ansvarsrettUtfoerende",
                                                    "midlertidigBrukstillatelseGjennstaaendeInnenfor",
                                                    () => {
                                                        dispatch(
                                                            validateMidlertidigBrukstillatelseGjennstaaendeInnenfor()
                                                        );
                                                    }
                                                );
                                            }}
                                            required
                                            resize="vertical"
                                            hasErrors={
                                                !!validationMessages?.midlertidigBrukstillatelseGjennstaaendeInnenfor
                                                    ?.message?.length
                                            }
                                            errorMessage={
                                                validationMessages?.midlertidigBrukstillatelseGjennstaaendeInnenfor
                                                    ?.message
                                            }
                                            defaultValue={
                                                formData.ansvarsrettUtfoerende
                                                    ?.midlertidigBrukstillatelseGjennstaaendeInnenfor || ""
                                            }
                                        />
                                    </div>
                                    <div className={formsStyle.fieldSection}>
                                        <Textarea
                                            id="midlertidigBrukstillatelseGjennstaaendeUtenfor"
                                            elementKey="midlertidigBrukstillatelseGjennstaaendeUtenfor"
                                            label="Disse delene av tiltaket søkes det ikke om midlertidig brukstillatelse for nå"
                                            onBlur={(event) => {
                                                handleUpdateAndSaveIfChanged(
                                                    event.target.value,
                                                    "ansvarsrettUtfoerende",
                                                    "midlertidigBrukstillatelseGjennstaaendeUtenfor"
                                                );
                                            }}
                                            resize="vertical"
                                            hasErrors={
                                                !!validationMessages?.midlertidigBrukstillatelseGjennstaaendeUtenfor
                                                    ?.message?.length
                                            }
                                            errorMessage={
                                                validationMessages?.midlertidigBrukstillatelseGjennstaaendeUtenfor
                                                    ?.message
                                            }
                                            defaultValue={
                                                formData.ansvarsrettUtfoerende
                                                    ?.midlertidigBrukstillatelseGjennstaaendeUtenfor || ""
                                            }
                                        />
                                    </div>
                                </React.Fragment>
                            ) : null}
                            {formData.ansvarsrettUtfoerende?.okForFerdigattest === true ? (
                                <div className={formsStyle.fieldSection}>
                                    <fieldset>
                                        <legend className={commonStyle.required}>
                                            Er alt arbeid innenfor ansvarsområdet avsluttet?
                                        </legend>
                                        <div className={formsStyle.buttonRow}>
                                            <Button
                                                content="Ja"
                                                size="small"
                                                rounded
                                                onClick={() => {
                                                    handleUpdateAndSaveIfChanged(
                                                        true,
                                                        "ansvarsomraadetAvsluttet",
                                                        null,
                                                        () => {
                                                            dispatch(validateAnsvarsomraadetAvsluttet());
                                                        }
                                                    );
                                                }}
                                                noHover
                                                required
                                                inputType="radio"
                                                name="ansvarsomraadetAvsluttet"
                                                defaultChecked={formData.ansvarsomraadetAvsluttet === true}
                                                hasErrors={
                                                    !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                }
                                                aria-describedby={
                                                    !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                        ? "ansvarsomraadetAvsluttet-errorMessage"
                                                        : null
                                                }
                                            />
                                            <Button
                                                content="Nei"
                                                size="small"
                                                rounded
                                                onClick={() => {
                                                    handleUpdateAndSaveIfChanged(
                                                        false,
                                                        "ansvarsomraadetAvsluttet",
                                                        null,
                                                        () => {
                                                            dispatch(validateAnsvarsomraadetAvsluttet());
                                                        }
                                                    );
                                                }}
                                                noHover
                                                required
                                                inputType="radio"
                                                name="ansvarsomraadetAvsluttet"
                                                defaultChecked={formData.ansvarsomraadetAvsluttet === false}
                                                hasErrors={
                                                    !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                }
                                                aria-describedby={
                                                    !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                        ? "ansvarsomraadetAvsluttet-errorMessage"
                                                        : null
                                                }
                                            />
                                        </div>
                                    </fieldset>
                                    {!!validationMessages?.ansvarsomraadetAvsluttet?.message?.length ? (
                                        <ErrorMessage
                                            id="ansvarsomraadetAvsluttet-errorMessage"
                                            content={validationMessages.ansvarsomraadetAvsluttet.message}
                                        />
                                    ) : null}
                                </div>
                            ) : null}
                        </Accordion>
                    </div>
                    {formData.ansvarsrettUtfoerende?.okForMidlertidigBrukstillatelse === true ? (
                        <div className={commonStyle.marginBottomSmall}>
                            <Accordion
                                title="Sikkerhetsnivå og arbeider som vil utføres innen 14 dager"
                                expanded
                                onToggleExpand={() => {
                                    dispatch(renewLastInteractionAt());
                                }}
                            >
                                <div className={formsStyle.fieldSection}>
                                    <fieldset>
                                        <legend className={commonStyle.required}>
                                            Har tiltaket tilstrekkelig sikkerhetsnivå til å tas i bruk i dag?
                                        </legend>
                                        <div className={formsStyle.buttonRow}>
                                            <Button
                                                content="Ja"
                                                size="small"
                                                rounded
                                                onClick={() => {
                                                    if (
                                                        !formData.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet ===
                                                        true
                                                    ) {
                                                        // Prevent change value to null if button is allready selected when clicked
                                                        handleUpdate(null, "ansvarsomraadetAvsluttet");
                                                    }
                                                    handleUpdate(
                                                        true,
                                                        "ansvarsrettUtfoerende",
                                                        "harTilstrekkeligSikkerhet"
                                                    );
                                                    handleValidate(() => {
                                                        dispatch(removeValidationForTypeArbeider());
                                                        dispatch(removeValidationForUtfoertInnen());
                                                        if (isValidated) {
                                                            dispatch(updateIsValidated(false));
                                                        }
                                                        dispatch(validateHarTilstrekkeligSikkerhet());
                                                    });
                                                }}
                                                noHover
                                                required
                                                inputType="radio"
                                                name="harTilstrekkeligSikkerhet"
                                                defaultChecked={
                                                    formData.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet === true
                                                }
                                                hasErrors={
                                                    !!validationMessages?.harTilstrekkeligSikkerhet?.message?.length
                                                }
                                                aria-describedby={
                                                    !!validationMessages?.harTilstrekkeligSikkerhet?.message?.length
                                                        ? "harTilstrekkeligSikkerhet-errorMessage"
                                                        : null
                                                }
                                            />
                                            <Button
                                                content="Nei"
                                                size="small"
                                                rounded
                                                onClick={() => {
                                                    handleUpdate(
                                                        false,
                                                        "ansvarsrettUtfoerende",
                                                        "harTilstrekkeligSikkerhet"
                                                    );
                                                    handleValidate(() => {
                                                        dispatch(validateHarTilstrekkeligSikkerhet());
                                                    });
                                                }}
                                                noHover
                                                required
                                                inputType="radio"
                                                name="harTilstrekkeligSikkerhet"
                                                defaultChecked={
                                                    formData.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet === false
                                                }
                                                hasErrors={
                                                    !!validationMessages?.harTilstrekkeligSikkerhet?.message?.length
                                                }
                                                aria-describedby={
                                                    !!validationMessages?.harTilstrekkeligSikkerhet?.message?.length
                                                        ? "harTilstrekkeligSikkerhet-errorMessage"
                                                        : null
                                                }
                                            />
                                        </div>
                                    </fieldset>
                                    {validationMessages?.harTilstrekkeligSikkerhet?.message?.length ? (
                                        <ErrorMessage
                                            id="harTilstrekkeligSikkerhet-errorMessage"
                                            content={validationMessages.harTilstrekkeligSikkerhet.message}
                                        />
                                    ) : null}
                                </div>
                                {formData.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet === false ? (
                                    <React.Fragment>
                                        <div className={formsStyle.fieldSection}>
                                            <Textarea
                                                id="typeArbeider"
                                                elementKey="typeArbeider"
                                                label="Vi vil utføre følgende arbeid for å oppnå tilstrekkelig sikkerhetsnivå"
                                                onBlur={(event) => {
                                                    handleUpdateAndSaveIfChanged(
                                                        event.target.value,
                                                        "ansvarsrettUtfoerende",
                                                        "typeArbeider",
                                                        () => {
                                                            dispatch(validateTypeArbeider());
                                                        }
                                                    );
                                                }}
                                                required
                                                resize="vertical"
                                                hasErrors={!!validationMessages?.typeArbeider?.message?.length}
                                                errorMessage={validationMessages?.typeArbeider?.message}
                                                defaultValue={formData.ansvarsrettUtfoerende?.typeArbeider || ""}
                                            />
                                        </div>
                                        <div className={formsStyle.fieldSection}>
                                            <InputField
                                                id="utfoertInnen"
                                                elementKey="utfoertInnen"
                                                label="Vi vil utføre arbeidene innen (maks 14 dager)"
                                                width="190px"
                                                type="date"
                                                onChange={(event) => {
                                                    handleUpdateAndSaveIfChanged(
                                                        convertFromInputValueToTimestamp(event.target.value),
                                                        "ansvarsrettUtfoerende",
                                                        "utfoertInnen",
                                                        () => {
                                                            dispatch(validateUtfoertInnen());
                                                        }
                                                    );
                                                }}
                                                required
                                                onBlur={(event) => null}
                                                hasErrors={!!validationMessages?.utfoertInnen?.message?.length}
                                                errorMessage={validationMessages?.utfoertInnen?.message}
                                                defaultValue={
                                                    formData.ansvarsrettUtfoerende?.utfoertInnen
                                                        ? convertFromTimestampToInputValue(
                                                              formData.ansvarsrettUtfoerende?.utfoertInnen
                                                          )
                                                        : null
                                                }
                                                min={convertFromTimestampToInputValue(new Date())}
                                                max={convertFromTimestampToInputValue(twoWeeksLater())}
                                                dateFormat="dd.MM.yyyy"
                                            />
                                        </div>
                                    </React.Fragment>
                                ) : null}
                                {
                                    <div className={formsStyle.fieldSection}>
                                        <fieldset>
                                            <legend className={commonStyle.required}>
                                                Er alt arbeid innenfor ansvarsområdet avsluttet?
                                            </legend>
                                            <div className={formsStyle.buttonRow}>
                                                <Button
                                                    content="Ja"
                                                    size="small"
                                                    rounded
                                                    onClick={() => {
                                                        handleUpdateAndSaveIfChanged(
                                                            true,
                                                            "ansvarsomraadetAvsluttet",
                                                            null,
                                                            () => {
                                                                dispatch(validateAnsvarsomraadetAvsluttet());
                                                            }
                                                        );
                                                    }}
                                                    noHover
                                                    required
                                                    inputType="radio"
                                                    name="ansvarsomraadetAvsluttet"
                                                    defaultChecked={formData.ansvarsomraadetAvsluttet === true}
                                                    hasErrors={
                                                        !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                    }
                                                    aria-describedby={
                                                        !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                            ? "ansvarsomraadetAvsluttet-errorMessage"
                                                            : null
                                                    }
                                                    disabled={
                                                        formData.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet ===
                                                        false
                                                    }
                                                    color={
                                                        formData.ansvarsomraadetAvsluttet === true
                                                            ? "primary"
                                                            : "default"
                                                    }
                                                />
                                                <Button
                                                    content="Nei"
                                                    size="small"
                                                    rounded
                                                    onClick={() => {
                                                        handleUpdateAndSaveIfChanged(
                                                            false,
                                                            "ansvarsomraadetAvsluttet",
                                                            null,
                                                            () => {
                                                                dispatch(validateAnsvarsomraadetAvsluttet());
                                                            }
                                                        );
                                                    }}
                                                    noHover
                                                    required
                                                    inputType="radio"
                                                    name="ansvarsomraadetAvsluttet"
                                                    defaultChecked={formData.ansvarsomraadetAvsluttet === false}
                                                    hasErrors={
                                                        !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                    }
                                                    aria-describedby={
                                                        !!validationMessages?.ansvarsomraadetAvsluttet?.message?.length
                                                            ? "ansvarsomraadetAvsluttet-errorMessage"
                                                            : null
                                                    }
                                                    disabled={
                                                        formData.ansvarsrettUtfoerende?.harTilstrekkeligSikkerhet ===
                                                        false
                                                    }
                                                />
                                            </div>
                                        </fieldset>
                                        {!!validationMessages?.ansvarsomraadetAvsluttet?.message?.length ? (
                                            <ErrorMessage
                                                id="ansvarsomraadetAvsluttet-errorMessage"
                                                content={validationMessages.ansvarsomraadetAvsluttet.message}
                                            />
                                        ) : null}
                                    </div>
                                }
                            </Accordion>
                        </div>
                    ) : null}
                </React.Fragment>
            ) : null}
        </React.Fragment>
    ) : (
        <p>Ingen data for foretak</p>
    );
};

Ansvarsomraade.propTypes = {
    updateHandler: PropTypes.func.isRequired,
    validationHandler: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    selectedForm: state.selectedForm,
    validationMessages: state.validationMessages,
    isValidated: state.isValidated
});

const mapDispatchToProps = {
    updateIsValidated,
    validateAnsvarsrettProsjekterendeCheckboxes,
    validateAnsvarsomraadetAvsluttet,
    validateAnsvarsrettUtfoerendeTillatelse,
    validateMidlertidigBrukstillatelseGjennstaaendeInnenfor,
    removeValidationForMidlertidigBrukstillatelseGjennstaaendeInnenfor,
    validateHarTilstrekkeligSikkerhet,
    removeValidationForHarTilstrekkeligSikkerhet,
    validateTypeArbeider,
    removeValidationForTypeArbeider,
    validateUtfoertInnen,
    removeValidationForUtfoertInnen
};

export default connect(mapStateToProps, mapDispatchToProps)(Ansvarsomraade);
