// Dependencies
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { fetchServiceState } from "actions/ServiceStateActions";

// Stylesheets
import { ErrorBox, Header } from "dibk-design";

const ServiceStateInfo = () => {
    const dispatch = useDispatch();

    // Redux store
    const serviceState = useSelector((state) => state.serviceState);

    useEffect(() => {
        dispatch(fetchServiceState());
    }, [dispatch]);

    return serviceState?.state === "offline" ||
        serviceState?.state === "degraded" ||
        serviceState?.state === "unavailable" ? (
        <ErrorBox fullScreen>
            <Header htmlTag="p" size="2">
                {serviceState?.messageHeader}
            </Header>
            {serviceState?.messageBody}
        </ErrorBox>
    ) : null;
};

export default ServiceStateInfo;
