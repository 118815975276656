// Dependencies
import React, { Fragment } from "react";

// DIBK Design
import { Header } from "dibk-design";

export const formatAddress = addressObject => {
    if (!addressObject || !Object.keys(addressObject).length) {
        return null
    }
    const adresse = addressObject.adresselinje1?.length ? addressObject.adresselinje1 : null;
    const postNrSted = addressObject.postnr?.length && addressObject.poststed?.length ? `${addressObject.postnr} ${addressObject.poststed}` : null
    const kommunenavn = addressObject.kommunenavn?.length ? `${addressObject.kommunenavn} kommune` : null;
    const gnrBnrShort = addressObject.gaardsnummer && addressObject.bruksnummer ? `(${addressObject.gaardsnummer}/${addressObject.bruksnummer})` : null;
    const gnrBnrLong = addressObject.gaardsnummer && addressObject.bruksnummer ? `Gårdsnr. ${addressObject.gaardsnummer}, bruksnr. ${addressObject.bruksnummer}` : null;
    if (adresse && postNrSted) {
        return `${adresse}, ${postNrSted} ${gnrBnrShort ? gnrBnrShort : ''}`
    } else if (adresse) {
        return `${adresse}${kommunenavn ? `, ${kommunenavn}` : ''}${gnrBnrShort ? ` ${gnrBnrShort}` : ''}`
    } else if (postNrSted) {
        return `${gnrBnrLong ? `${gnrBnrLong}, ` : ''}${postNrSted}`
    } else if (gnrBnrLong || kommunenavn) {
        return `${gnrBnrLong ? `${gnrBnrLong}, ` : ''}${kommunenavn}`
    } else {
        return null
    }
}

export const formatDate = timestamp => {
    if (!timestamp) { return null }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${day}.${month}.${year}`;
}

export const formatTime = timestamp => {
    if (!timestamp) { return null }
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return `${hours}:${minutes}`;
}

export const formatProjectNameForForm = form => {
    let projectName = '';
    if (form?.formData?.prosjektnavn) {
        projectName += ` ${form.formData.prosjektnavn}`;
    } else if (form?.formData?.eiendomByggesteder?.[0]?.adresselinje1) {
        projectName += ` for ${form.formData.eiendomByggesteder[0].adresselinje1}`;
    }
    if (form?.formData?.eiendomByggesteder?.[0]?.kommunenavn) {
        projectName += ` i ${form.formData.eiendomByggesteder[0].kommunenavn}`;
    }
    return projectName;
}

export const formatProjectNameForEmail = form => {
    const addressObject = form?.formData?.eiendomByggesteder?.[0];
    const gnrBnrLong = addressObject?.gaardsnummer && addressObject?.bruksnummer ? `Gårdsnr. ${addressObject.gaardsnummer}, bruksnr. ${addressObject.bruksnummer}` : null;

    if (form?.formData?.prosjektnavn) {
        return form.formData.prosjektnavn;
    } else if (addressObject?.adresselinje1) {
        return addressObject.adresselinje1;
    } else if (gnrBnrLong) {
        return gnrBnrLong;
    } else {
        return null;
    }
}


export const formatSaksnummer = form => {
    return form?.formData?.kommunenssaksaar && form?.formData?.kommunenssakssekvensnummer
        ? `${form?.formData?.kommunenssaksaar}/${form?.formData?.kommunenssakssekvensnummer}`
        : null
}

export const formatAnsvarligSoekerTelefonnummer = form => {
    const ansvarligSoeker = form?.formData?.ansvarligSoeker;
    if (ansvarligSoeker?.kontaktpersonMobilnummer) {
        return ansvarligSoeker?.kontaktpersonMobilnummer
    } else if (ansvarligSoeker?.kontaktpersonTelefonnummer) {
        return ansvarligSoeker?.kontaktpersonTelefonnummer;
    } else if (ansvarligSoeker?.mobilnummer) {
        return ansvarligSoeker?.mobilnummer;
    } else if (ansvarligSoeker?.telefonnummer) {
        return ansvarligSoeker?.telefonnummer;
    } else {
        return null;
    }
}

export const formatAnsvarligSoekerEpost = form => {
    const ansvarligSoeker = form?.formData?.ansvarligSoeker;
    return ansvarligSoeker?.kontaktpersonEpost || ansvarligSoeker?.epost;
}

export const formatAnsvarligSoekerNavn = form => {
    const navn = form?.formData?.ansvarligSoeker?.navn;
    const kontaktpersonNavn = form?.formData?.ansvarligSoeker?.kontaktpersonNavn;
    return kontaktpersonNavn ? `${kontaktpersonNavn} hos ${navn}` : navn;
}

export const formatOrgNavnOgNummer = organizationObject => {
    let orgNavnOgNummer;
    if (organizationObject?.navn?.length) {
        orgNavnOgNummer = organizationObject.navn;
        if (organizationObject.organisasjonsnummer?.length) {
            orgNavnOgNummer += ` (org.nr. ${organizationObject.organisasjonsnummer})`;
        }
    }
    return orgNavnOgNummer;
}

export const formatContactInfoText = form => {
    if (!form) {
        return
    }

    const name = formatAnsvarligSoekerNavn(form);
    const phoneNumber = formatAnsvarligSoekerTelefonnummer(form);
    const emailAddress = formatAnsvarligSoekerEpost(form);
    const emailAddressLinkElement = emailAddress ? <a href={`mailto:${emailAddress}`}>{emailAddress}</a> : '';

    let phoneOrEmailString;

    if (phoneNumber && emailAddress) {
        phoneOrEmailString = [` på telefon ${phoneNumber} eller e-post `, <React.Fragment key="emailLink">{emailAddressLinkElement}</React.Fragment>];
    } else if (phoneNumber) {
        phoneOrEmailString = ` på telefon ${phoneNumber}`;
    } else if (emailAddress) {
        phoneOrEmailString = ` på e-post ${emailAddress}`;
    }
    return [`Ta kontakt med ${name}`, phoneOrEmailString, `.`];
}

export const formatFormIntroText = form => {
    if (!form) {
        return
    }
    const ansvarligSoekerNavn = form?.formData?.ansvarligSoeker?.navn;
    return [`Noen opplysninger er allerede lagt inn av ${ansvarligSoekerNavn}, som er ansvarlig søker. Du kan endre beskrivelsen og valgene. Endringene dine lagres automatisk.`];
}

export const convertFromTimestampToInputValue = (timestamp) => {
    if (!timestamp) { return null }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${year}-${month}-${day}`;
}

export const convertFromInputValueToTimestamp = (inputValue) => {
    return new Date(inputValue).toISOString();
}


export const renderProjectDetails = (form) => {
    const adresse = formatAddress(form?.formData?.eiendomByggesteder?.[0]);
    const saksnummer = formatSaksnummer(form);
    const ansvarligForetakNavnOgNummer = formatOrgNavnOgNummer(form?.formData?.ansvarligForetak);

    return (
    <Fragment>
        <Header size={3} content={form?.formData?.prosjektnavn?.length ? form.formData.prosjektnavn : 'Erklæringen gjelder'} />
        {
          adresse ? <p>Byggested: {adresse}.</p> : ''
        }
        {
          saksnummer ? <p>Kommunens saksnummer: {saksnummer}.</p> : ''
        }
        {
          ansvarligForetakNavnOgNummer ? <p>Ansvarlig foretak: {ansvarligForetakNavnOgNummer}.</p> : ''
        }
        {
          form?.formData?.ansvarligForetak?.kontaktpersonNavn ? <p>Kontaktperson hos ansvarlig foretak er: {form.formData.ansvarligForetak.kontaktpersonNavn}.</p> : ''
        }
    </Fragment>
    )
  }

export const formatOrganiationNumberForPdf = (organisationNumber) => {
    const strippedOrganizationNumber = organisationNumber.replace(/\s/g,''); // Remove whitespace
    const parts = strippedOrganizationNumber.match(/.{1,3}/g); // Split
    return parts.join(" "); // Merge with whitespace
}

export const formatPhoneNumberForPdf = (phoneNumber) => {
    const strippedPhoneNumber = phoneNumber.replace(/\s/g,''); // Remove whitespace
    const parts = strippedPhoneNumber.match(/.{1,2}/g); // Split
    return parts.join(" "); // Merge with whitespace
}
