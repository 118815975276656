// Dependencies
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Snackbar from 'components/template/Snackbar';

// Actions
import { hideSnackbarMessage } from 'actions/SnackbarActions';

const SnackbarContainer = () => {

    const dispatch = useDispatch();

    // Redux store
    const snackbarMessage = useSelector(state => state.snackbarMessage);
    const snackbarVisible = useSelector(state => state.snackbarVisible);

    const handleOnCloseClick = () => {
        dispatch(hideSnackbarMessage);
    }

    return snackbarVisible ? <Snackbar message={snackbarMessage} onCloseClick={handleOnCloseClick} /> : '';

}

export default SnackbarContainer;
