// Dependencies
import React from "react";
import { useSelector } from "react-redux";

// Components
import AnsvarligForetak from "components/partials/Pdf/PdfParts/AnsvarligForetak";
import EiendomByggesteder from "components/partials/Pdf/PdfParts/EiendomByggesteder";
import SamsvarOgKontrollAnsvarsomraade from "components/partials/Pdf/PdfParts/SamsvarOgKontrollAnsvarsomraade";
import GjenstaaendeArbeider from "components/partials/Pdf/PdfParts/GjenstaaendeArbeider";
import ArbeiderSomVilUtfoeres from "components/partials/Pdf/PdfParts/ArbeiderSomVilUtfoeres";
import ErklaeringSamsvar from "components/partials/Pdf/PdfParts/ErklaeringSamsvar";
import Heading from "components/partials/Pdf/PdfParts/Heading";

const SamsvarsErklaering = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const formData = selectedForm?.formData;
    return formData ? (
        <div class="content-container signed-document">
            <Heading />
            <section>
                <h2>Eiendom/byggested</h2>
                <EiendomByggesteder />
            </section>
            <section>
                <h2>Ansvarlig foretak</h2>
                <AnsvarligForetak />
            </section>
            <section>
                <h2>Ansvarsområde</h2>
                <SamsvarOgKontrollAnsvarsomraade />
            </section>
            {formData?.funksjonKode === "UTF" && formData?.ansvarsrettUtfoerende?.okForMidlertidigBrukstillatelse ? (
                <React.Fragment>
                    <section>
                        <h2>Gjenstående arbeider</h2>
                        <GjenstaaendeArbeider />
                    </section>
                    <section>
                        <h2>Sikkerhetsnivå og arbeider som vil utføres innen 14 dager</h2>
                        <ArbeiderSomVilUtfoeres />
                    </section>
                </React.Fragment>
            ) : null}
            <section>
                <h2>Erklæring</h2>
                <ErklaeringSamsvar />
            </section>
        </div>
    ) : (
        <p>Henter skjema</p>
    );
};

export default SamsvarsErklaering;
