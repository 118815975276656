// Dependencies
import { useEffect } from "react";
import { Link } from "react-router-dom";

// DIBK Design
import { Container, Header, Paper, Table } from "dibk-design";

// Components
import MainHeading from "components/partials/MainHeading";

// Helpers
import { scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const Personvernerklaering = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Container>
            <Paper>
                <MainHeading pageName="Personvernerklæring - Signeringstjenesten for ansvarlige foretak" />
                <i>Sist oppdatert 30.05.2023</i>
                <Header
                    content="Behandling av personopplysninger i Signeringstjenesten for ansvarlig foretak"
                    size={2}
                ></Header>
                <p>
                    Direktoratet for byggkvalitet (DiBK) eier og driver Signeringstjenesten for ansvarlige foretak som
                    en del av Fellestjenester BYGG. For å kunne tilby tjenesten, er vi nødt til å behandle noen
                    personopplysninger om deg som bruker tjenesten (ofte kalt «den registrerte»).
                </p>

                <Header content="Slik fungerer Signeringstjenesten for ansvarlige foretak" size={3} />
                <ol className={commonStyle.noMarginTop}>
                    <li>
                        Ansvarlig søker velger en av{" "}
                        <a
                            href="https://dibk.no/verktoy-og-veivisere/andre-fagomrader/fellestjenester-bygg/tjenestene/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            våre godkjente leverandører
                        </a>{" "}
                        og klargjør erklæringen der.
                    </li>
                    <li>
                        Når erklæringen er klar, sender ansvarlige søker en unik lenke til det ansvarlige foretaket.
                        Leken går til Signeringstjenesten for ansvarlig foretak.
                    </li>
                    <li>Ansvarlig foretak logger seg inn, fyller ut og signerer erklæringen.</li>
                    <li>
                        Etter signering blir erklæringen tilgjengelig for ansvarlig søker i søknadsløsningen som er
                        valgt. Det er ansvarlig søker sitt ansvar å sende inn relevante erklæringer til kommunen sammen
                        med gjennomføringsplanen.
                    </li>
                </ol>

                <Header content="Behandlingsansvarlig og behandlingsgrunnlag" size={3} />
                <p>
                    DiBK er behandlingsansvarlig for personopplysningene som behandles av oss gjennom
                    Signeringstjenesten for ansvarlige foretak. Det betyr at vi er ansvarlig for å behandle
                    personopplysninger på en{" "}
                    <a
                        href="https://www.datatilsynet.no/rettigheter-og-plikter/personvernprinsippene/grunnleggende-personvernprinsipper/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        lovlig, rettferdig og gjennomsiktig måte
                    </a>
                    .
                </p>
                <p>
                    Vi behandler personopplysningene dine for å kunne drifte Signeringstjenesten for ansvarlige foretak
                    på en sikker måte, og for å utøve våre lovpålagte plikter som direktorat, nasjonalt kompetansesenter
                    på bygningsområdet og sentral myndighet innenfor flere områder av plan- og bygningsloven. DiBK har
                    rettslige grunnlag etter personvernforordningen («GDPR») artikkel 6 nr. 1 e). Artikkelen sier at
                    behandlingen er nødvendig for å utføre en oppgave i a
                </p>
                <p className={commonStyle.noMarginBottom}>
                    Vi behandler personopplysninger om deg for å kunne tilby Signeringstjenesten for ansvarlige foretak
                    slik at du kan:
                </p>
                <ol className={`${commonStyle.lowerAlphaList} ${commonStyle.noMarginTop}`}>
                    <li>Logge deg inn med entydig identifisering</li>
                    <li>Motta en erklæring klar for utfylling</li>
                    <li>Signere den ferdig utfylte erklæringen</li>
                    <li>Sende den juridisk bindende erklæringen sikkert til ansvarlig søker</li>
                </ol>
                <p>
                    I henhold til forvaltningsloven og eForvaltningsforskriften er elektronisk kommunikasjon
                    hovedregelen når vi kommuniserer med publikum.
                </p>

                <Header content="Slik deler vi dataene med våre samarbeidspartnere" size={3} />
                <p>
                    Personopplysningene vi behandler deles kun med aktører og samarbeidspartnere på bakgrunn av en
                    avtale eller en lovpålagt plikt. De leverandørene som behandler personopplysninger på vegne av oss,
                    har vi inngått en databehandleravtale med. I databehandleravtalen sikrer vi at personopplysningene
                    dine blir behandlet etter GDPR-regelverket. Vi setter tydelige rammer for hvordan
                    samarbeidspartneren kan behandle opplysningene, hva som er våre plikter og hvilke plikter
                    samarbeidspartneren har.
                </p>

                <Header content="Mottakere av erklæringen" size={4} />
                <p>
                    Når du signerer og sender erklæringen, sender vi den elektronisk til den ansvarlige søkeren som er
                    ansvarlig for byggeprosjektet. Dette betyr at vi sender informasjonen og personopplysningene dine
                    til den søknadsløsningen ansvarlig søker bruker. Dersom ansvarlig søker senere skal sende den
                    signerte erklæringen sammen med byggesøknaden, blir personopplysningene med til kommunen som skal
                    saksbehandle søknaden.
                </p>

                <Header content="Oversikt over databehandlere" size={4} />
                <p className={commonStyle.noMarginBottom}>
                    Signeringstjenesten for ansvarlige foretak bruker tjenesten eSignering fra
                    Digitaliseringsdirektoratet til selve signeringsoppdraget.{" "}
                    <a
                        href="https://samarbeid.digdir.no/digital-postkasse/bruksvilkar-digdirs-digitale-felleslosninger/70#68_behandling_av_personopplysninger_databehandleravtale"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Les om hvordan de behandler personopplysninger på denne siden
                    </a>
                    . eSignering har også underleverandører som alle har egne personvernerklæringer:
                </p>
                <ul className={commonStyle.noMarginTop}>
                    <li>
                        <a
                            href="https://eid.difi.no/nb/sikkerhet-og-informasjonskapsler/personvern-og-informasjonskapsler"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ID-porten
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://signering.posten.no/personvern/uten-virksomhetskonto  "
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Posten Norge AS
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.signicat.com/about/privacy-statement-sign"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Signicat AS
                        </a>
                    </li>
                </ul>
                <Table>
                    <caption>I tillegg har DiBK følgende databehandlere:</caption>
                    <thead>
                        <tr>
                            <th>Databehandlere</th>
                            <th>Beskrivelse av rolle og personvernerklæring</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Arkitektum AS</td>
                            <td>
                                Utviklingsleverandør og leverandør av applikasjonsdrift.{" "}
                                <a
                                    href="https://arkitektum.no/personvernerklaering/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Personvernerklæring
                                </a>
                                .
                            </td>
                        </tr>
                        <tr>
                            <td>IVER AS</td>
                            <td>
                                Leverandør av teknisk serverdrift.{" "}
                                <a
                                    href="https://www.iver.com/en/privacy-policy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Personvernerklæring
                                </a>
                                .
                            </td>
                        </tr>
                        <tr>
                            <td>Microsoft Azure</td>
                            <td>
                                Leverandør av lagring og plattformtjenester.{" "}
                                <a
                                    href="https://privacy.microsoft.com/nb-no/privacystatement"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Personvernerklæring
                                </a>
                                .
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <p className={commonStyle.marginTopSmall}>
                    Personopplysningene vil ikke bli brukt i noen andre sammenhenger. Vi selger, bytter eller
                    videreformidler ikke personopplysningene dine.
                </p>

                <Header content="Disse opplysninger behandler vi" size={3} />
                <p>
                    Når du logger deg inn med BankID eller annen elektronisk ID og tar i bruk Signeringstjenesten for
                    ansvarlige foretak, behandler DiBK personopplysninger om deg. Selv om du bruker tjenesten som
                    representant for et foretak, behandler vi navnet ditt, fødselsnummeret ditt, navnet på foretaket,
                    e-postadresser og telefonnummer. Vi vet også at du er koblet til foretaket. I erklæringene kan det
                    oppgis en kontaktperson. Hvis denne kontaktpersonen er en annen enn den som logger seg inn,
                    behandler DiBK opplysninger om navn, telefonnummer, e-postadresse og kobling til foretaket.
                </p>
                <p>
                    De fleste opplysningene gir du oss selv ved å logge inn, eller ved å oppgi opplysningene i
                    erklæringen. Noen felt vil kunne være forhåndsutfylt med forslag fra ansvarlig søker.
                </p>
                <p>
                    Vi mottar fra tid til annen informasjon fra nasjonale registre om foretaket. Dette er kun
                    informasjon om foretaket du representerer, og inneholder ikke personopplysninger.
                </p>

                <Header content="Slik tar vi vare på personopplysningene dine" size={3} />
                <p>
                    Personopplysningene dine behandles på en sikker måte. Signeringstjenesten for ansvarlige foretak har
                    system-til-system-integrasjon, som betyr at det kun er ansatte som har tjenstlig behov for det, som
                    har tilgang til personopplysningene dine. Den elektroniske overføringen (HTTPS-kommunikasjonen) er
                    alltid kryptert mellom de ulike systemmottakerene. Systemmottakerne er de samarbeidspartnerne vi har
                    inngått avtaler med, og disse må identifisere seg med unike ID-er i overføringen, før de kan motta
                    informasjonen.
                </p>

                <Header content="Så lenge lagrer vi dataene" size={3} />
                <p>
                    DiBK lagrer ikke personopplysningene dine lenger enn det som er nødvendig for å administrere og
                    drifte tjenesten, og følge opp eventuelle feil ved tjenesten.
                </p>
                <p>
                    Signeringstjenesten for ansvarlige foretak er ikke et arkiv. Det er ansvarlig søker og ansvarlig
                    foretak som har ansvaret for å lagre og ta vare på signerte erklæringer. Erklæringer som blir sendt
                    til kommunen, vil langtidslagres i kommunens arkiv.
                </p>
                <p>
                    DiBK sletter signerte og usignerte erklæringer, samt data om erklæringen, 12 måneder etter at
                    signeringsoppdraget ble sendt fra ansvarlig søker. E-postkvitteringer som sendes fra løsningen vil
                    slettes fra vår mailserver etter 60 dager.
                </p>

                <Header content="Bruk av informasjonskapsler" size={3} />
                <p>
                    Signeringstjenesten for ansvarlige foretak bruker informasjonskapsler (cookies) for at tjenesten
                    skal fungere. Du kan lese mer om dette i{" "}
                    <Link to="/cookieerklaering" title="Personvernerklæring">
                        erklæringen om informasjonskapsler
                    </Link>
                    .
                </p>

                <Header content="Dine rettigheter" size={3} />
                <p>
                    Personopplysningsloven gir deg en rekke rettigheter når vi behandler personopplysningene dine. Du
                    har blant annet rett til å få innsyn i og informasjon om, hvilke personopplysninger vi behandler og
                    lagrer om deg. Hvis opplysningene vi har behandlet eller lagret er uriktige, har du rett til å få
                    dem korrigert. Men merk deg at den signerte erklæringen er et juridisk dokument som ikke kan
                    korrigeres. I særlige tilfeller kan du ha rett til å få opplysninger om deg slettet, innen
                    tidspunktet for de alminnelige generelle sletterutinene.
                </p>

                <p>
                    Hvis du har spørsmål om vår behandling, eller ønsker å utøve dine rettigheter som registrert, kan du
                    kontakte oss på <a href="mailto:ftb@dibk.no">ftb@dibk.no</a> så blir henvendelsen din videresendt
                    vårt personvernombud.
                </p>
                <p>
                    <a href="https://www.datatilsynet.no/" target="_blank" rel="noopener noreferrer">
                        Hvis du vil lese mer om personvern og rettighetene dine, anbefaler vi Datatilsynets nettsider
                    </a>
                    .
                </p>
            </Paper>
        </Container>
    );
};

export default Personvernerklaering;
