// Dependecies
import React, { useState, useEffect, Fragment } from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { Route, Routes } from 'react-router';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { renderToStaticMarkup } from 'react-dom/server'
import { HelmetProvider } from 'react-helmet-async';

// Utils
import configureStore, { history } from 'utils/configureStore';
import userManagerPromise from 'utils/userManager';

// Routes
import About from 'components/routes/About';
import Home from 'components/routes/Home';
import Form from 'components/routes/Forms/FormType/Form';
import Receipt from 'components/routes/Receipt';
import SignedOut from 'components/routes/SignedOut';
import Personvernerklaering from 'components/routes/Personvernerklaering';
import ErklaeringOmInformasjonskapsler from 'components/routes/ErklaeringOmInformasjonskapsler';
import NotFound from 'components/routes/NotFound';
import OidcCallback from 'components/routes/OidcCallback';
import OidcSignoutCallback from 'components/routes/OidcSignoutCallback';
import Pdf from 'components/routes/Pdf';

// Partials
import MainNavigationBar from 'components/partials/MainNavigationBar';
import ServiceStateInfo from 'components/partials/ServiceStateInfo';
import Footer from 'components/partials/Footer';

// Template
import SnackbarContainer from 'components/template/SnackbarContainer';

// Stylesheets
import style from 'App.module.scss';

/* eslint import/no-webpack-loader-syntax: off */
import printStyle from '!!raw-loader!sass-loader!./print.scss';
import StaySignedInDialog from 'components/partials/StaySignedInDialog';


const initialState = {};
const storePromise = configureStore(initialState, userManagerPromise);
let store = null;
let userManager = null;


const renderHtmlString = () => {
  sessionStorage.print = "true";
  const htmlString = renderToStaticMarkup(<div className="page signed-document"><App /></div>);
  sessionStorage.print = "false";
  document.head.innerHTML = `<style>${printStyle}</style>`;
  document.body.innerHTML = htmlString;
}

const App = () => {

  // State
  const [storeIsLoaded, setStoreIsLoaded] = useState(false);
  const [userManagerIsLoaded, setUserManagerIsLoaded] = useState(false);

  useEffect(() => {
    storePromise.then((storeConfig) => {
      store = storeConfig;
      setStoreIsLoaded(true)
    });
    userManagerPromise.then(userManagerConfig => {
      userManager = userManagerConfig;
      setUserManagerIsLoaded(true)
    })
  }, []);

  const isPrint = sessionStorage.print === "true";
  const showPreviewPdfButton = false;

  if ((userManager && userManagerIsLoaded && storeIsLoaded) || isPrint) {
    return (<Provider store={store}>
      <OidcProvider userManager={userManager} store={store}>
        <HelmetProvider>
          <Router history={history}>
            {
              !isPrint ? (
                <React.Fragment>
                  <MainNavigationBar userManager={userManager} />
                  <ServiceStateInfo />
                </React.Fragment>
              ) : ''
            }
            {
              !isPrint && showPreviewPdfButton ? (<button onClick={() => renderHtmlString()}>Preview PDF</button>) : ''
            }
            {
              isPrint
                ? (
                  <Routes>
                    <Route exact={true} path="/skjema/:submissionId/rediger" element={<Pdf />} />
                  </Routes>
                )
                : (
                  <Fragment>
                    <main id="main-content" className={style.appContainer}>
                      <StaySignedInDialog userManager={userManager} />
                      <Routes>
                        <Route exact path="/signin-oidc" element={<OidcCallback userManager={userManager} />} />
                        <Route exact path="/signout-callback-oidc" element={<OidcSignoutCallback userManager={userManager} />} />
                        <Route exact={true} path="/cookieerklaering" element={<ErklaeringOmInformasjonskapsler />} />
                        <Route exact={true} path="/personvernerklaering" element={<Personvernerklaering />} />
                        <Route exact={true} path="/utlogget" element={<SignedOut />} />
                        <Route exact={true} path="/skjema/:submissionId/utlogget" element={<SignedOut />} />
                        <Route exact={true} path="/skjema/:submissionId/signert" element={<Receipt />} />
                        <Route exact={true} path="/skjema/:submissionId/signatur-avvist" element={<Receipt />} />
                        <Route exact={true} path="/skjema/:submissionId/avvis" element={<Form showRejectDialog />} />
                        <Route exact={true} path="/skjema/:submissionId/rediger" element={<Form />} />
                        <Route exact={true} path="/skjema/:submissionId" element={<Home userManager={userManager} />} />
                        <Route exact={true} path="/" element={<About />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                      <SnackbarContainer />
                    </main>
                    <Footer />
                  </Fragment>
                )
            }
          </Router>
        </HelmetProvider>
      </OidcProvider>
    </Provider>);
  } else return '';
}


export default App;
