// Dependencies
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// DIBK Design
import { Button, Container, Header, InputField, LoadingAnimation, Paper } from "dibk-design";

// Partials
import MainHeading from "components/partials/MainHeading";

// Actions
import { fetchSubmission } from "actions/SubmissionActions";
import { fetchSelectedForm } from "actions/FormActions";
import { getSignedDocument } from "actions/SigningActions";
import { sendEmail } from "actions/EmailActions";
import { updateLoadingMessage } from "actions/LoadingMessageActions";

// Helpers
import { formatContactInfoText } from "helpers/formatHelpers";
import { saveFileContentFromBlob } from "helpers/fileHelpers";
import { generateRejectionEmail, generateSignedEmail } from "helpers/emailHelpers";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const Receipt = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);
    const oidc = useSelector((state) => state.oidc);
    const loadingMessage = useSelector((state) => state.loadingMessage);

    // State
    const [kontaktpersonEpost, setKontaktpersonEpost] = useState();
    const [hasSentEmail, setHasSentEmail] = useState();

    // Params
    const submissionId = params.submissionId;

    const handleDownloadButtonClick = () => {
        const accessToken = oidc?.user?.access_token;
        dispatch(updateLoadingMessage("Henter signert dokument"));

        dispatch(getSignedDocument(submissionId, accessToken))
            .then((signedDocument) => {
                saveFileContentFromBlob(signedDocument.blob, signedDocument.filename);
            })
            .catch((error) => {
                console.log("handleDownloadButtonClick", error);
            })
            .finally(() => {
                dispatch(updateLoadingMessage(null));
            });
    };

    const handlePerformEmailPostRequest = useCallback(
        (toAddress) => {
            const getEmail = (toAddress) => {
                switch (selectedForm?.status) {
                    case "signert":
                        return generateSignedEmail(selectedForm, toAddress);
                    case "avvist":
                        return generateRejectionEmail(selectedForm, toAddress);
                    default:
                        return null;
                }
            };

            const performEmailPostRequest = (toAddress) => {
                const accessToken = oidc?.user?.access_token;
                const email = getEmail(toAddress);
                if (email) {
                    dispatch(updateLoadingMessage("Sender e-post"));
                    dispatch(sendEmail(email, selectedForm, accessToken))
                        .then(() => {
                            dispatch(updateLoadingMessage("E-post er sendt"));
                        })
                        .catch((error) => {
                            dispatch(updateLoadingMessage("Kunne ikke sende e-post"));
                        })
                        .finally(() => {
                            setTimeout(() => {
                                dispatch(updateLoadingMessage(null));
                            }, 3000);
                        });
                }
            };

            performEmailPostRequest(toAddress);
        },
        [dispatch, oidc, selectedForm]
    );

    useEffect(() => {
        const fecthFormData = () => {
            dispatch(updateLoadingMessage("Henter innsending"));
            return dispatch(fetchSubmission(submissionId))
                .then((submission) => {
                    if (submission && Object.keys(submission).length) {
                        dispatch(updateLoadingMessage("Henter skjema"));
                        return dispatch(fetchSelectedForm(submission))
                            .then((response) => {
                                const selectedForm = response.payload;
                                if (selectedForm) {
                                    dispatch(updateLoadingMessage(null));
                                } else {
                                    dispatch(updateLoadingMessage(null));
                                }
                                return selectedForm;
                            })
                            .catch((error) => {
                                console.log("fetchSelectedForm", error);
                                dispatch(updateLoadingMessage(null));
                            });
                    }
                })
                .catch((error) => {
                    console.log("fetchSubmission", error);
                })
                .finally(() => {
                    dispatch(updateLoadingMessage(null));
                });
        };

        const fetchAndUpdateData = () => {
            return new Promise((resolve) => {
                if (submissionId) {
                    fecthFormData().then(() => {
                        resolve(selectedForm);
                    });
                }
            });
        };

        const accessToken = oidc?.user?.access_token;
        const hasSelectedForm = selectedForm && Object.keys(selectedForm).length;
        if (!hasSelectedForm && accessToken) {
            fetchAndUpdateData();
        }
    }, [selectedForm, oidc, submissionId, dispatch]);

    useEffect(() => {
        const redirectIfNotValidStatus = () => {
            const validStatuses = ["signert", "avvist"];
            const isValidStatus = validStatuses.some((status) => {
                return selectedForm.status === status;
            });
            const isAuthenticated = oidc?.user && Object.keys(oidc.user).length;
            if (!isValidStatus || !isAuthenticated) {
                navigate(`/skjema/${selectedForm.referanseId}`);
            }
        };

        const hasSelectedForm = selectedForm && Object.keys(selectedForm).length;
        if (hasSelectedForm) {
            const foretakKontaktpersonEpost = selectedForm?.formData?.ansvarligForetak?.kontaktpersonEpost;
            setKontaktpersonEpost(foretakKontaktpersonEpost);
            if (selectedForm?.status === "signert" && !hasSentEmail) {
                handlePerformEmailPostRequest(foretakKontaktpersonEpost);
                setHasSentEmail(true);
            }
            redirectIfNotValidStatus();
        }
    }, [selectedForm, hasSentEmail, handlePerformEmailPostRequest, oidc, navigate]);

    const renderContent = () => {
        switch (selectedForm.status) {
            case "signert":
                const foretakEpost =
                    selectedForm?.formData?.ansvarligForetak?.kontaktpersonEpost ||
                    selectedForm?.formData?.ansvarligForetak?.epost;
                return (
                    <React.Fragment>
                        <Header size={2} content="Erklæringen har blitt signert" />
                        <div className={commonStyle.introText}>
                            <div className={commonStyle.paragraphGroup}>
                                <Header size={3} content="Hvor har erklæringen blitt sendt?" />
                                <p>
                                    Erklæringen er sendt til {selectedForm?.formData?.ansvarligSoeker?.navn}, som er
                                    ansvarlig søker.
                                </p>
                                {foretakEpost ? (
                                    <p>En kopi av den signerte erklæringen er også sendt til {foretakEpost}.</p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className={commonStyle.paragraphGroup}>
                                <Header size={3} content="Husk å arkivere erklæringen" />
                                <p>Ansvarlig foretak må selv holde arkiv over signerte erklæringer.</p>
                                <p>Du kan laste ned erklæringen ved å trykke på knappen under.</p>
                            </div>
                            <div className={commonStyle.paragraphGroup}>
                                <Button
                                    onClick={handleDownloadButtonClick}
                                    content="Last ned en kopi"
                                    color="primary"
                                />
                            </div>
                            <Header size={3} content="Har du spørsmål?" />
                            <div>{formatContactInfoText(selectedForm)}</div>
                        </div>
                    </React.Fragment>
                );
            case "avvist":
                return (
                    <React.Fragment>
                        <Header size={2} content="Erklæringen har blitt avvist" />

                        <div className={commonStyle.introText}>
                            {selectedForm?.statusReason?.length ? (
                                <React.Fragment>
                                    <div className={commonStyle.paragraphGroup}>
                                        <Header size={3} content="Du har meldt om følgende feil:" />
                                        <p>
                                            <i>{selectedForm.statusReason}</i>
                                        </p>
                                    </div>
                                    <div className={commonStyle.paragraphGroup}>
                                        <p>
                                            Meldingen er sendt til {selectedForm?.formData?.ansvarligSoeker?.navn}, som
                                            er ansvarlig søker
                                        </p>
                                    </div>
                                </React.Fragment>
                            ) : null}
                            <div className={commonStyle.paragraphGroup}>
                                <Header size={3} content="Vil du ha kvitteringen på e-post?" />
                                <p>Skriv inn e-postadressen din under for å få sendt en bekreftelse på e-post.</p>
                                <InputField
                                    id="foretakEpost"
                                    elementKey="foretakEpost"
                                    defaultValue={kontaktpersonEpost}
                                    type="email"
                                    onChange={(event) => setKontaktpersonEpost(event.target.value)}
                                    name="email"
                                    aria-autocomplete="inline"
                                />
                            </div>
                            <div className={commonStyle.paragraphGroup}>
                                <Button
                                    content="Send kvittering"
                                    color="primary"
                                    onClick={() => handlePerformEmailPostRequest(kontaktpersonEpost)}
                                />
                            </div>
                            <Header size={3} content="Har du spørsmål?" />
                            <div>{formatContactInfoText(selectedForm)}</div>
                        </div>
                    </React.Fragment>
                );
            default:
                return selectedForm.status;
        }
    };

    return (
        <Container>
            <Helmet>
                <meta name="robots" content="noindex"></meta>
            </Helmet>
            <Paper>
                {selectedForm.status === "signert" ? (
                    <MainHeading form={selectedForm} pageName="Signaturen er sendt" />
                ) : selectedForm.status === "avvist" ? (
                    <MainHeading form={selectedForm} pageName="Avvisningen er sendt" />
                ) : null}
                {loadingMessage?.length ? <LoadingAnimation fixed message={loadingMessage} /> : ""}
                {renderContent()}
            </Paper>
        </Container>
    );
};

export default Receipt;
