// Dependencies
import React from 'react';
import { useSelector } from 'react-redux';

const AnsvarsrettAnsvarsomraader = () => {

    // Redux store
    const selectedForm = useSelector(state => state.selectedForm);

    const renderPlanlagtErklaeringTitle = (ansvarsomraader) => {
        const containsPro = ansvarsomraader.some(ansvarsomraade => { return ansvarsomraade.funksjonKode === 'PRO' });
        const containsUtf = ansvarsomraader.some(ansvarsomraade => { return ansvarsomraade.funksjonKode === 'UTF' });
        const containsKontroll = ansvarsomraader.some(ansvarsomraade => { return ansvarsomraade.funksjonKode === 'KONTROLL' });

        if ((containsPro || containsUtf) && containsKontroll) {
            return 'Planlagt samsvars-/ kontrollerklæring';
        } else if (containsPro || containsUtf) {
            return 'Planlagt samsvarserklæring';
        } else if (containsKontroll) {
            return 'Planlagt kontrollerklæring';
        } else {
            return null;
        }
    }

    const renderPlanlagtErklaeringValues = (ansvarsomraade) => {
        return [
            ansvarsomraade.samsvarKontrollVedRammetillatelse === true ? 'Rammesøknad' : null,
            ansvarsomraade.samsvarKontrollVedIgangsettingstillatelse === true ? 'Ettrinns-/igangsettingssøknad' : null,
            ansvarsomraade.samsvarKontrollVedMidlertidigBrukstillatelse === true ? 'Midlertidig brukstillatelse' : null,
            ansvarsomraade.samsvarKontrollVedFerdigattest === true ? 'Ferdigattest' : null
        ].filter(planlagtErklaeringValue => { return planlagtErklaeringValue }).map((planlagtErklaeringValue, index) => {
            return (<div key={index}>{planlagtErklaeringValue}</div>)
        });
    }


    const renderAnsvarsomraader = (ansvarsomraader, harPlanlagtErklaering, harSentralGodkjenning) => {
        return ansvarsomraader?.length ? ansvarsomraader.map((ansvarsomraade, index) => {
            const dekkesOmradetAvSentralGodkjenningValue = ansvarsomraade.dekkesOmradetAvSentralGodkjenning === true
                ? 'Ja'
                : ansvarsomraade.dekkesOmradetAvSentralGodkjenning === false
                    ? 'Nei'
                    : '-';
            const planlagtErklaeringValues = renderPlanlagtErklaeringValues(ansvarsomraade);
            return (
                <tr key={index}>
                    <td>{ansvarsomraade.funksjonKode?.length ? ansvarsomraade.funksjonKode : '-'}</td>
                    <td>{ansvarsomraade.beskrivelseAvAnsvarsomraade?.length ? ansvarsomraade.beskrivelseAvAnsvarsomraade : '-'}</td>
                    <td>{ansvarsomraade.tiltaksklasseKode?.length ? ansvarsomraade.tiltaksklasseKode : '-'}</td>
                    {harPlanlagtErklaering ? <td className="whitespace-nowrap">{planlagtErklaeringValues?.length ? planlagtErklaeringValues : '-'}</td> : ''}
                    {harSentralGodkjenning ? <td>{dekkesOmradetAvSentralGodkjenningValue}</td> : ''}
                </tr>
            )
        }) : ''
    }

    const ansvarsomraader = selectedForm?.formData?.ansvarsomraader;
    const planlagtErklaeringTitle = renderPlanlagtErklaeringTitle(ansvarsomraader);
    const harPlanlagtErklaering = planlagtErklaeringTitle?.length > 0 ? true : false;
    const harSentralGodkjenning = selectedForm?.formData?.ansvarligForetak?.harSentralGodkjenning;
    return ansvarsomraader ? (
        <table>
            <thead>
                <tr>
                    <th>Funksjon</th>
                    <th>Beskrivelse av ansvarsområdet</th>
                    <th>Tiltaks-<br />klasse</th>
                    {
                        planlagtErklaeringTitle ? <th>{planlagtErklaeringTitle}</th> : ''
                    }
                    {
                        harSentralGodkjenning ? <th>Dekkes av sentral godkjenning?</th> : ''
                    }
                </tr>
            </thead>
            <tbody>
                {renderAnsvarsomraader(ansvarsomraader, harPlanlagtErklaering, harSentralGodkjenning)}
            </tbody>
        </table>
    ) : ''
}

export default AnsvarsrettAnsvarsomraader;
