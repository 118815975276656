// Constants
import { UPDATE_SELECTED_FORM } from "constants/types";

// Helpers
import { getEnvironmentVariable } from "helpers/environmentVariableHelpers.js";

export const updateAttachmentWithoutUpload = (file, selectedForm) => (dispatch) => {
    if (!file) {
        return false;
    }
    const vedlegg = {
        filnavn: file.name,
        storrelse: file.size,
        vedleggstype: null,
        mimeType: file.type
    };
    const updatedForm = {
        ...selectedForm,
        vedlegg
    };
    return dispatch({ type: UPDATE_SELECTED_FORM, payload: updatedForm });
};

export const removeAttachmentFromSelectedForm = (selectedForm) => (dispatch) => {
    const updatedForm = {
        ...selectedForm,
        vedlegg: null
    };
    return dispatch({ type: UPDATE_SELECTED_FORM, payload: updatedForm });
};

export const uploadAttachment = (file, selectedForm, accessToken) => (dispatch) => {
    const internalApiUrl = getEnvironmentVariable("internalApiUrl");
    const formPath = selectedForm?._links?.vedlegg?.href;
    const bearerToken = `Bearer ${accessToken}`;

    const formData = new FormData();
    formData.append("attachment", file);

    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: bearerToken
        },
        body: formData
    };

    return fetch(`${internalApiUrl}${formPath}`, fetchOptions)
        .then((res) => {
            if (res.ok) {
                return res.json().then((vedlegg) => {
                    const updatedForm = {
                        ...selectedForm,
                        vedlegg
                    };
                    dispatch({ type: UPDATE_SELECTED_FORM, payload: updatedForm });
                    return {
                        status: res.status,
                        data: updatedForm
                    };
                });
            } else {
                return res.json().then((problemDetails) => {
                    return {
                        status: res.status,
                        xCorrelationId: res.headers?.get?.("x-correlation-id"),
                        problemDetails,
                        metadata: {
                            fileName: file.name
                        }
                    };
                });
            }
        })
        .catch((status) => {
            return {
                status
            };
        });
};
