// Dependencies
import { Helmet } from 'react-helmet-async';

// Template
import { Container } from 'dibk-design';

const NotFound = () => {
  return (
    <Container>
      <Helmet>
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <h1>404</h1>
      <p>Siden finnes ikke</p>
    </Container>
  )
}

export default NotFound;
