// Dependencies
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

// Helpers
import { formatDate } from "helpers/formatHelpers";

const SamsvarOgKontrollAnsvarsomraade = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const renderPlanlagtErklaeringValues = (formData) => {
        let planlagtErklaeringOptions;
        if (formData.funksjonKode === "PRO") {
            planlagtErklaeringOptions = [
                formData.ansvarsrettProsjekterende?.okForRammetillatelse === true ? "Rammesøknad" : null,
                formData.ansvarsrettProsjekterende?.okForIgangsetting === true ? "Ettrinns-/igangsettingssøknad" : null,
                formData.ansvarsrettProsjekterende?.okForMidlertidigBrukstillatelse === true
                    ? "Midlertidig brukstillatelse"
                    : null,
                formData.ansvarsrettProsjekterende?.okForFerdigattest === true ? "Ferdigattest" : null
            ];
        } else if (formData.funksjonKode === "UTF") {
            planlagtErklaeringOptions = [
                formData.ansvarsrettUtfoerende?.okForMidlertidigBrukstillatelse === true
                    ? "Midlertidig brukstillatelse"
                    : null,
                formData.ansvarsrettUtfoerende?.okForFerdigattest === true ? "Ferdigattest" : null
            ];
        }
        return planlagtErklaeringOptions?.length
            ? planlagtErklaeringOptions
                  .filter((planlagtErklaeringValue) => {
                      return planlagtErklaeringValue;
                  })
                  .map((planlagtErklaeringValue, index) => {
                      return <div key={index}>{planlagtErklaeringValue}</div>;
                  })
            : "-";
    };

    const formData = selectedForm?.formData;
    const ansvarsomraadetAvsluttetText =
        formData.ansvarsomraadetAvsluttet === true ? "Ja" : formData.ansvarsomraadetAvsluttet === false ? "Nei" : "-";
    return (
        <Fragment>
            <table>
                <thead>
                    <tr>
                        <th>Funksjon</th>
                        <th>Beskrivelse av ansvarsområdet</th>
                        <th>Ansvarsrett ble erklært</th>
                        <th>Er arbeidet avsluttet?</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{formData?.funksjonKode?.length ? formData?.funksjonKode : "-"}</td>
                        <td>
                            {formData?.beskrivelseAvAnsvarsomraadet?.length
                                ? formData?.beskrivelseAvAnsvarsomraadet
                                : "-"}
                        </td>
                        <td>{formData?.ansvarsrettErklaert ? formatDate(formData.ansvarsrettErklaert) : "-"}</td>
                        <td>{ansvarsomraadetAvsluttetText}</td>
                    </tr>
                </tbody>
            </table>
            {formData.funksjonKode === "PRO" || formData.funksjonKode === "UTF" ? (
                <table>
                    <thead>
                        <tr>
                            <th colSpan="4">
                                Vi har ikke avdekket gjenstående arbeider innenfor ansvarsområdet som er til hinder for
                                å gi
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="4">{renderPlanlagtErklaeringValues(formData)}</td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                ""
            )}
        </Fragment>
    );
};

export default SamsvarOgKontrollAnsvarsomraade;
