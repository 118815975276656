export const getAnsvarsomraaderFromForm = form => {
    switch (form?.innsendingstype) {
        case 'ansvarsrett':
            return form?.formData?.ansvarsomraader;
        case 'kontrollerklaering':
        case 'samsvarserklaering':
            return [
                {
                    funksjonKode: form?.formData?.funksjonKode,
                    funksjonBeskrivelse: form?.formData?.funksjonBeskrivelse,
                    beskrivelseAvAnsvarsomraade: form?.formData?.beskrivelseAvAnsvarsomraadet
                }
            ];
        default:
            return null;
    }
}

export const groupAnsvarsomraaderByFunksjon = ansvarsomraader => {
    let ansvarsomraaderGrouped = {}
    if (ansvarsomraader?.length) {
        ansvarsomraader.filter(ansvarsomraade => {
            return ansvarsomraade.beskrivelseAvAnsvarsomraade?.length || ansvarsomraade.funksjonKode === 'SØK';
        }).forEach(ansvarsomraade => {
            if (!ansvarsomraaderGrouped[ansvarsomraade.funksjonKode]) {
                ansvarsomraaderGrouped[ansvarsomraade.funksjonKode] = {
                    funksjonBeskrivelse: ansvarsomraade.funksjonBeskrivelse,
                    ansvarsomraader: []
                };
            }
            ansvarsomraaderGrouped[ansvarsomraade.funksjonKode].ansvarsomraader.push(ansvarsomraade);
        })
    }
    return ansvarsomraaderGrouped;
}
