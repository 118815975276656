// Dependencies
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// DIBK Design
import { CheckBoxList, CheckBoxListItem, ErrorMessage } from "dibk-design";

// Actions
import { validateErklaeringCheckboxes } from "actions/ValidationActions";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const Erklaering = ({ formData, updateHandler, validationHandler }) => {
    const dispatch = useDispatch();

    // Redux store
    const validationMessages = useSelector((state) => state.validationMessages);

    const handleOnChange = (value, property, validations) => {
        updateHandler({
            ...formData,
            [property]: value
        }).then(() => {
            validationHandler(validations);
        });
    };

    const hasAnsvarsomraadeFunksjon = (funksjonKode) => {
        return formData?.ansvarsomraader?.length
            ? formData?.ansvarsomraader.some((ansvarsomraade) => {
                  return ansvarsomraade.funksjonKode === funksjonKode;
              })
            : false;
    };

    const hasUncheckedAnsvarsomraadeFunksjon = () => {
        return [
            hasAnsvarsomraadeFunksjon("PRO") && !formData.erklaeringAnsvarligProsjekterende,
            hasAnsvarsomraadeFunksjon("UTF") && !formData.erklaeringAnsvarligUtfoerende,
            hasAnsvarsomraadeFunksjon("KONTROLL") && !formData.erklaeringAnsvarligKontrollerende
        ].some((condition) => condition);
    };

    return formData ? (
        <Fragment>
            <CheckBoxList compact>
                <legend className={commonStyle.normalText}>
                    <span className={`${commonStyle.block} ${commonStyle.marginBottomSmall}`}>
                        Vi er kjent med reglene om straff og sanksjoner i plan- og bygningsloven kapittel 32, og at det
                        kan medføre reaksjoner dersom vi oppgir uriktige opplysninger. Vi forplikter oss å stille med
                        riktig kompetanse i byggeprosjekt, jf. SAK10 kapittel 10 og 11.
                    </span>
                    {hasAnsvarsomraadeFunksjon("SØK") ? (
                        <span className={`${commonStyle.block} ${commonStyle.marginBottomSmall}`}>
                            Ansvarlig søker bekrefter at hele tiltaket belegges med ansvar, og dekker kravene i plan- og
                            bygningsloven.
                        </span>
                    ) : null}
                </legend>
                {hasAnsvarsomraadeFunksjon("PRO") ? (
                    <CheckBoxListItem
                        id="erklaeringAnsvarligProsjekterende"
                        onChange={(event) => {
                            handleOnChange(event.target.checked, "erklaeringAnsvarligProsjekterende", () => {
                                dispatch(validateErklaeringCheckboxes());
                            });
                        }}
                        checked={formData.erklaeringAnsvarligProsjekterende ? true : false}
                        checkmarkCharacter="✕"
                        required
                        hasErrors={
                            validationMessages?.erklaeringCheckboxes && !formData.erklaeringAnsvarligProsjekterende
                        }
                        aria-describedby={
                            !!validationMessages?.erklaeringCheckboxes?.message?.length
                                ? "erklaeringCheckboxes-errorMessage"
                                : null
                        }
                    >
                        Ansvarlig prosjekterende erklærer at prosjekteringen skal være planlagt, gjennomført og
                        kvalitetssikret i henhold til plan- og bygningsloven, jf. SAK10 § 12-3.
                    </CheckBoxListItem>
                ) : null}
                {hasAnsvarsomraadeFunksjon("UTF") ? (
                    <CheckBoxListItem
                        id="erklaeringAnsvarligUtfoerende"
                        onChange={(event) => {
                            handleOnChange(event.target.checked, "erklaeringAnsvarligUtfoerende", () => {
                                dispatch(validateErklaeringCheckboxes());
                            });
                        }}
                        checked={formData.erklaeringAnsvarligUtfoerende ? true : false}
                        checkmarkCharacter="✕"
                        required
                        hasErrors={validationMessages?.erklaeringCheckboxes && !formData.erklaeringAnsvarligUtfoerende}
                        aria-describedby={
                            !!validationMessages?.erklaeringCheckboxes?.message?.length
                                ? "erklaeringCheckboxes-errorMessage"
                                : null
                        }
                    >
                        Ansvarlig utførende erklærer at arbeidet ikke skal starte før det foreligger kvalitetssikret
                        produksjonsunderlag for den respektive del av utførelsen, jf. SAK10, § 12-4.
                    </CheckBoxListItem>
                ) : null}
                {hasAnsvarsomraadeFunksjon("KONTROLL") ? (
                    <CheckBoxListItem
                        id="erklaeringAnsvarligKontrollerende"
                        onChange={(event) => {
                            handleOnChange(event.target.checked, "erklaeringAnsvarligKontrollerende", () => {
                                dispatch(validateErklaeringCheckboxes());
                            });
                        }}
                        checked={formData.erklaeringAnsvarligKontrollerende ? true : false}
                        checkmarkCharacter="✕"
                        required
                        hasErrors={
                            validationMessages?.erklaeringCheckboxes && !formData.erklaeringAnsvarligKontrollerende
                        }
                        aria-describedby={
                            !!validationMessages?.erklaeringCheckboxes?.message?.length
                                ? "erklaeringCheckboxes-errorMessage"
                                : null
                        }
                    >
                        Ansvarlig kontrollerende erklærer uavhengighet, jf. SAK10 § 14-1, og vil redegjøre for endringer
                        som kan påvirke uavhengigheten, jf. SAK10 § 12-5.
                    </CheckBoxListItem>
                ) : null}
            </CheckBoxList>
            {!!validationMessages?.erklaeringCheckboxes?.message?.length && hasUncheckedAnsvarsomraadeFunksjon() ? (
                <ErrorMessage
                    id="erklaeringCheckboxes-errorMessage"
                    content={validationMessages.erklaeringCheckboxes.message}
                />
            ) : null}
        </Fragment>
    ) : null;
};

Erklaering.propTypes = {
    formData: PropTypes.object.isRequired,
    updateHandler: PropTypes.func.isRequired,
    validationHandler: PropTypes.func.isRequired
};

export default Erklaering;
