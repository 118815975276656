// Dependencies
import React from 'react';

// Components
import AnsvarIByggeProsjekt from 'components/partials/Forms/FormParts/AnsvarIByggeProsjektList/AnsvarIByggeProsjekt';


const AnsvarIByggeProsjektList = ({ ansvarsomraader, updateHandler, validationHandler }) => {

    const handleUpdate = (value, property, index) => {
        let updatedAnsvarsomraader = ansvarsomraader;
        updatedAnsvarsomraader[index][property] = value;
        return updateHandler(updatedAnsvarsomraader);
    }

    const handleValidate = (validations) => {
        validationHandler(validations);
    }

    return ansvarsomraader?.length
        ? ansvarsomraader.map((ansvarsomraade, index) => {
            return (
                <AnsvarIByggeProsjekt
                    ansvarsomraade={ansvarsomraade}
                    index={index}
                    key={index}
                    updateHandler={(value, property, index) => handleUpdate(value, property, index)}
                    validationHandler={validations => handleValidate(validations)}
                />
            )
        })
        : (
            <p>Ingen data for Eiendom/Byggested</p>
        )
}

export default AnsvarIByggeProsjektList;
