// Dependencies
import React from "react";
import PropTypes from "prop-types";

// Helpers
import { formatSaksnummer, formatDate, formatFormIntroText, formatContactInfoText } from "helpers/formatHelpers";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";
import { Header } from "dibk-design";

const FormIntroText = ({ form }) => {
    const prosjektnavn = form?.formData?.prosjektnavn?.length ? form?.formData?.prosjektnavn : null;
    const saksnummer = formatSaksnummer(form);
    const frist = formatDate(form.signeringsfrist);
    return form && Object.keys(form).length ? (
        <div className={commonStyle.introText}>
            <div className={commonStyle.paragraphGroup}>
                {prosjektnavn ? <Header size={3} htmlTag="h2" content={prosjektnavn} /> : null}
                {saksnummer ? <p>Kommunens saksnummer: {formatSaksnummer(form)}</p> : ""}
                {frist ? <p>Frist for signering: {frist}</p> : ""}
            </div>
            <div className={commonStyle.paragraphGroup}>
                <Header size={3} htmlTag="h2" content="Utfylling av skjemaet" />
                <div>{formatFormIntroText(form)}</div>
            </div>
            <div>
                <Header size={3} htmlTag="h2" content="Har du spørsmål?" />
                <div>{formatContactInfoText(form)}</div>
            </div>
        </div>
    ) : null;
};

FormIntroText.propTypes = {
    form: PropTypes.object
};

export default FormIntroText;
