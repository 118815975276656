// Dependencies
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';


// Partials
import Ansvarsrett from 'components/partials/Pdf/Ansvarsrett';
import SamsvarsErklaering from 'components/partials/Pdf/SamsvarsErklaering';
import KontrollErklaering from 'components/partials/Pdf/KontrollErklaering';

// Actions
import { fetchSubmission } from 'actions/SubmissionActions';
import { fetchSelectedForm } from 'actions/FormActions';


const Pdf = () => {

  const dispatch = useDispatch();
  const params = useParams();

  const submissionId = params?.submissionId || null


  // Redux store
  const selectedSubmission = useSelector(state => state.selectedSubmission);

  useEffect(() => {
    if (submissionId && (!selectedSubmission || !Object.keys(selectedSubmission).length)) {
      return dispatch(fetchSubmission(submissionId)).then(() => {
        if (selectedSubmission && Object.keys(selectedSubmission).length) {
          return dispatch(fetchSelectedForm(selectedSubmission)).then((response) => {
            return response.payload;
          }).catch(error => {
            console.log("fetchSelectedForm", error)
          });
        }
      }).catch(error => {
        console.log("fetchSubmission", error)
      });
    }
  }, [dispatch, submissionId, selectedSubmission])


  const renderForm = (formType) => {
    switch (formType) {
      case 'ansvarsrett':
        return <Ansvarsrett />
      case 'samsvarserklaering':
        return <SamsvarsErklaering />
      case 'kontrollerklaering':
        return <KontrollErklaering />
      default:
        return ''
    }
  }

  return selectedSubmission
    ? renderForm(selectedSubmission.innsendingstype)
    : ''
}

export default Pdf;
