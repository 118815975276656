// Dependencies
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// DIBK Design
import { Container, Header, Paper } from "dibk-design";

// Components
import MainHeading from "components/partials/MainHeading";
import SubmissionIdSearch from "components/partials/SubmissionIdSearch";

// Helpers
import { scrollToTop } from "helpers/guiHelpers";

// Stylesheets
import commonStyle from "components/routes/common.module.scss";

const About = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const signinRedirectPath = sessionStorage?.signinRedirectPath;
        if (!!signinRedirectPath?.length) {
            sessionStorage.removeItem("signinRedirectPath");
            navigate(signinRedirectPath);
        }
    }, [navigate]);

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Container>
            <Paper>
                <MainHeading pageName="Om signeringstjenesten for ansvarlige foretak" />
                <div>
                    <Header size={2}>Signér erklæringer om ansvar, samsvar eller kontroll</Header>
                    <SubmissionIdSearch />
                    <p className={commonStyle.paragraphGroup}>
                        Når du trykker Gå til erklæring, blir du sendt til en egen side for din erklæring. Der kan du
                        logge inn med ID-porten for å fylle ut og signere erklæringen.
                    </p>

                    <Header size={3}>Slik fungerer Signeringstjenesten for ansvarlig foretak</Header>
                    <ol className={commonStyle.noMarginTop}>
                        <li>
                            Ansvarlig søker sender en lenke til denne siden sammen med en unik ID til erklæringen som
                            skal signeres.
                        </li>
                        <li>
                            Ansvarlig foretak limer inn den unike ID-en i tekstboksen over, og trykker "Gå til
                            erklæring."
                        </li>
                        <li>
                            Ansvarlig foretak logger seg inn for å se på erklæringen og fylle ut informasjonen om sitt
                            ansvarsområde.
                        </li>
                        <li>Ansvarlig foretak signerer erklæringen. Husk også å lagre erklæringen etter signering.</li>
                        <li>
                            Det signerte dokumentet blir sendt til ansvarlig søker, som sender inn relevante erklæringer
                            til kommunen sammen med gjennomføringsplanen.
                        </li>
                    </ol>

                    <p>Tjenesten er utviklet av Direktoratet for byggkvalitet.</p>

                    <Header size={3}>Har du spørsmål?</Header>
                    <p>
                        Hvis du har spørsmål om innholdet i en erklæring, må du kontakte ansvarlig søker.
                        <br />
                        <a href="https://dibk.no/signering" target="_blank" rel="noopener noreferrer">
                            Se også vanlig stilte spørsmål om Signeringstjenesten for ansvarlige foretak
                        </a>
                        .
                    </p>
                </div>
            </Paper>
        </Container>
    );
};

export default About;
