// Dependencies
import React from "react";
import { useSelector } from "react-redux";

// Components
import AnsvarligForetak from "../Pdf/PdfParts/AnsvarligForetak";
import AnsvarsrettAnsvarsomraader from "./PdfParts/AnsvarsrettAnsvarsomraader";
import EiendomByggesteder from "../Pdf/PdfParts/EiendomByggesteder";
import ErklaeringAnsvarsrett from "../Pdf/PdfParts/ErklaeringAnsvarsrett";
import Heading from "components/partials/Pdf/PdfParts/Heading";

const Ansvarsrett = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    return selectedForm ? (
        <div class="content-container signed-document">
            <Heading />
            <section>
                <h2>Eiendom/byggested</h2>
                <EiendomByggesteder />
            </section>
            <section>
                <h2>Ansvarlig foretak</h2>
                <AnsvarligForetak />
            </section>
            <section>
                <h2>{selectedForm?.formData?.ansvarsomraader?.length > 1 ? "Ansvarsområder" : "Ansvarsområde"}</h2>
                <AnsvarsrettAnsvarsomraader />
            </section>
            <section>
                <h2>Erklæring</h2>
                <ErklaeringAnsvarsrett />
            </section>
        </div>
    ) : (
        <p>Henter skjema</p>
    );
};

export default Ansvarsrett;
