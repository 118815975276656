import { FETCH_SERVICE_STATE } from 'constants/types';

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICE_STATE:
            return action.payload;
        default:
            return state;
    }
}

export default reducer;
