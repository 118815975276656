// Dependencies
import React from "react";
import { useSelector } from "react-redux";

// Helpers
import { formatAddress } from "helpers/formatHelpers";

const EiendomByggesteder = () => {
    // Redux store
    const selectedForm = useSelector((state) => state.selectedForm);

    const renderEiendomByggesteder = (eiendomByggesteder) => {
        return eiendomByggesteder?.length
            ? eiendomByggesteder.map((eiendomByggested, index) => {
                return (
                    <tr key={index}>
                        <td>
                            {formatAddress(eiendomByggested)}
                            {!!eiendomByggested.adresselinje2?.length ? (
                                <div>{eiendomByggested.adresselinje2}</div>
                            ) : null}
                            {!!eiendomByggested.adresselinje3?.length ? (
                                <div>{eiendomByggested.adresselinje3}</div>
                            ) : null}
                        </td>
                        <td>{eiendomByggested.seksjonsnummer?.length ? eiendomByggested.seksjonsnummer : "-"}</td>
                        <td>{eiendomByggested.festenummer?.length ? eiendomByggested.festenummer : "-"}</td>
                        <td>{eiendomByggested.bolignummer?.length ? eiendomByggested.bolignummer : "-"}</td>
                        <td>{eiendomByggested.bygningsnummer?.length ? eiendomByggested.bygningsnummer : "-"} </td>
                    </tr>
                );
            })
            : "";
    };

    const eiendomByggesteder = selectedForm?.formData?.eiendomByggesteder;
    return (
        <table>
            <thead>
                <tr>
                    <th>Adresse</th>
                    <th aria-label="Seksjonsnummer">Seksjons-<br/>nummer</th>
                    <th aria-label="Festenummer">Feste-<br/>nummer</th>
                    <th aria-label="Bruksenhetsnummer">Bruksenhets-<br/>nummer</th>
                    <th aria-label="Bygningsnummer">Bygnings-<br/>nummer</th>
                </tr>
            </thead>
            <tbody>{renderEiendomByggesteder(eiendomByggesteder)}</tbody>
        </table>
    );
};

export default EiendomByggesteder;
